.container {
  margin-top: 3rem;
  gap: 2.25rem;
  min-height: 100vh;
  background: #f7f6e6;
  display: flex;
  flex-direction: column;

  @media (min-width: 1001px) {
    .ratingWrapper {
      background: linear-gradient(180deg, #f7f6e6 0%, #f7f6e6 30%, rgba(204, 185, 167, 0.65) 70%, rgba(204, 185, 167, 0.45) 100%);
      padding: 4rem 0;
      width: 100%;
    }

    .ingredientsTable {
      background: #f7f6e6;
      width: 100%;
      min-height: 25vh;
      align-items: center;
      justify-content: center;
      display: flex;
    }

    .ingredientsTableContainer {
      max-width: 1200px;
      margin-top: 40px;
      margin-left: 10%;
      margin-right: 10%;
      margin-bottom: 30px
    }

    .scoreContainer {
      max-width: 1200px;
      margin: 0 auto;
    }
  }
}

.ratingWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.scoreContainer {
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  width: 21.5rem;
  height: 29.125rem;
  padding: 9rem 0rem 11.76594rem 0rem;
}

.scoreDisplay {
  display: flex;
  flex-direction: row;
  height: 6.48406rem;
  flex-shrink: 0;
}

.scoreDigit {
  height: 4rem;
}

.scoreTitle {
  color: #000;
  text-align: center;
  font-family: 'ABC Diatype';
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.25rem;
  /* 100% */
  letter-spacing: -0.0125rem;
  text-transform: uppercase;
}

.lineContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 0.24rem;
}

.ingredientsTableContainer {
  display: flex;
  flex-direction: column;
  max-width: 90vw;
  margin-inline: auto;
  margin-top: 1.5rem;
  gap: 1.12rem;
}

.longLine {
  width: 22.23831rem;
  height: 0.0625rem;
  background: #000;
}

.shortLine {
  width: 3.64081rem;
  height: 0.0625rem;
  background-color: #000;
}

.bestsellersCarouselContainer {
  margin-left: 10%;
  min-height: 75vh;
}

.ProductsGrid {
  width: 100%;
}

.similarProductsTitleContainer {
}

.similarProductsTitleText {
  color: var(--Charcoal, #000);
  font-family: 'ABC Diatype';
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 104%;
  text-transform: uppercase;
}

@media (min-width: 1000px) {
  .container {
    min-height: auto;
  }

  .scoreDisplay {
    display: flex;
    flex-direction: row;
    height: 9.93256rem;
    flex-shrink: 0;
  }

  .scoreDigit {
    height: 6rem;
  }
}

@media (max-width: 768px) {
  .similarProductsTitleContainer {
    margin-left: 10px;
  }
}

// NOTE: this is the styling of the commented results page
// .resultsContainer {
//   padding: 20px;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   gap: 20px;

//   h2 {
//     font-size: 24px;
//     margin-bottom: 20px;
//   }

//   h3 {
//     font-size: 18px;
//     margin-bottom: 10px;
//   }
// }

// .imageContainer {
//   width: 100%;
//   max-width: 500px;
//   border-radius: 8px;
//   overflow: hidden;
//   box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
// }

// .scannedImage {
//   width: 100%;
//   height: auto;
//   display: block;
// }

// .textContainer {
//   width: 100%;
//   max-width: 500px;
//   padding: 20px;
//   background-color: #f5f5f5;
//   border-radius: 8px;
//   margin-top: 20px;
// }

// .extractedText {
//   font-family: monospace;
//   white-space: pre-wrap;
//   word-break: break-word;
//   background-color: white;
//   padding: 15px;
//   border-radius: 4px;
//   border: 1px solid #ddd;
//   max-height: 300px;
//   overflow-y: auto;
// }

.loadMoreContainer {
  display: flex;
  justify-content: center;
  margin: 1rem 0;
}

.loadMoreButton {
  background-color: var(--primary-color);
  color: #000;
  border: none;
  text-align: center;
  cursor: pointer;
  font-family: 'NB Grotesk Pro Mono';
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 105%; /* 0.91875rem */
  letter-spacing: -0.0175rem;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-skip-ink: auto;
  text-decoration-thickness: auto;
  text-underline-offset: auto;
  text-underline-position: from-font;
}
