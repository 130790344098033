.accountInfoTitle {
  font-family: 'ABC Diatype', sans-serif;
  font-size: 26px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.userHeader {
  margin-bottom: 3%;
}

.userHeaderMobile {
  margin-bottom: 20px;
  width: 100%;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.titleButtonContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid rgb(223, 218, 218);
}

.editButton {
  font-family: 'SF Pro Display', sans-serif;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  background-color: transparent;
  color: black;
  text-decoration: none;
  transition: all 0.3s ease;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1);
  margin: 15px 0;
}

.userData {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 70%;
}

.userDetails {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.userNameEmail,
.userLastnamePhone {
  display: flex;
  align-items: center;
}

.userNameEmail p,
.userLastnamePhone p {
  flex: 1;
}

.detailsText {
  font-family: 'SF Pro Display', sans-serif;
  font-size: 14px;
  color: #5D5246;
  line-height: 1.5;
  margin: 0;
}