.orderTrackContainer {
    border-top: 1px solid rgba(0, 0, 0, 0.07);
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 20px 0;
    max-width: 800px;
}

.estimatedDelivery {
    color: #000;
    font-family: "ABC Diatype";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 109%;
    text-transform: uppercase;
    margin-bottom: 20px;
}

.orderTracker {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
}

.trackDataContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
    gap: 62px;
}

.trackDataSection {
    display: flex;
    flex-direction: column;
    width: 70px;
}

.trackDataContainerTitle {
    color: #000;
    font-family: "ABC Diatype";
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
    letter-spacing: -0.1px;
    text-transform: uppercase;
}

.trackDataContainerDate {
    color: var(--Ceramic, #5D5246);
    font-family: "ABC Diatype";
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
    letter-spacing: -0.1px;
    text-transform: uppercase;
}

/* Mobile Styling */
@media (max-width: 768px) {
    .orderTrackContainer {
        padding: 10px;
    }

    .trackDataContainer {
        flex-direction: column;
        align-items: center;
        gap: 60px;
        margin-top: 0;
        margin-left: 20px;
    }

    .timelineAndData {
        display: flex;
        flex-direction: row;
    }

    .trackDataSection {
        display: flex;
        flex-direction: column;
        width: 100px;
    }
}