@import '../../../index.scss';

.bestsellersContainer {
    padding: 100px 20px 20px 30px;
    background-color: #F7F6E7;
    padding-bottom: 50px;

    @media (max-width: 768px) {
        padding: 0px 20px 20px 20px;
    }

    .containerCarouselHomePage {
        position: relative;
        width: 100%;
        margin-top: -5%;
    }
}

.scrollToExplore {
    color: var(--unnamed, #5D5246);
    text-align: center;
    font-family: "NB Grotesk Pro Mono";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 110.457%;
    transform: translateY(-70px);

    @media (max-width: 768px) {
        padding-bottom: 30px;
        margin-left: 60vw;
        margin-top: 3%;
    }

    @media (max-height: 670px) {
        width: 100%;
        margin-left: 30vw;
    }
}