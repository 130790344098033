.start-diagnosis-container {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 200px;
    border-radius: 8px;
    margin-top: 40px;
}


.start-diagnosis-image {
    width: 100%;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.start-diagnosis-title {
    font-family: 'ABC Diatype';
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translateX(-50%);
    color: #F7F6E6;
    margin: 0;
    text-transform: uppercase;
}

.start-diagnosis-text {
    font-family: 'NB Grotesk Pro';
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #F7F6E6;
    margin: 0;
}

.start-diagnosis-button {
    background-color: #F7F6E6;
    color: black;
    padding: 14px 76px;
    border: none;
    border-radius: 62px;
    cursor: pointer;
    text-transform: uppercase;
    margin-top: 10px;
    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
    text-transform: uppercase;
    font-size: 10px
}

@media (max-width: 768px) {
    .start-diagnosis-container {
        margin-top: 0;
        margin-bottom: 10px;
    }

    .start-diagnosis-title {
        font-size: 12px;
    }

    .start-diagnosis-button {
        width: 85%;
        font-size: 10px;
        margin-top: 0px;
    }
}