.hero {
  display: flex;
  height: 100vh;
  position: relative;

  &__title {
    position: absolute;
    top: 80px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    max-width: 80%; // Adjust this value as needed
    height: auto;
  }

  &__article {
    flex: 1;
    position: relative;
    overflow: hidden;
    text-decoration: none;
    color: #ffffff;

    &:hover {
      .hero__article-background {
        transform: scale(1.05);
      }
    }
  }

  &__article-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    transition: transform 0.3s ease;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.7));
    }
  }

  &__article-content {
    position: absolute;
    bottom: 2rem;
    left: 2rem;
    right: 2rem;
    z-index: 1;
    display: flex;
    justify-content: space-between;
  }

  &__category {
    font-size: 0.9rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
    display: block;
    color: #f7f6e6;
    font-family: 'ABC Diatype-Regular', Helvetica;
    letter-spacing: 0;
    line-height: 13.2px;
  }

  &__article-title {
    font-size: 16px;
    font-weight: normal;
    margin: 0;
    font-family: 'NB Grotesk Pro', sans-serif;
  }
}
////mobile

@media (max-width: 768px) {
  .hero__article-background{
    z-index: 0;
  }
  .hero__title{
    z-index: 1;
  }
  .hero__category {
    height: 2rem;
    width: 50%;
    margin-left: -1.5rem;
    transform: translateY(4rem);
  }
  .dossierName {
    display: inline-block;
  }
  .hero__article-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 20%;
  }
  .hero__article-title-container {
    display: flex; 
    flex-direction: column; 
    justify-content: flex-start; 
    height: 100px; 
    box-sizing: border-box; 
    padding: 10px; 
    width: 100%;
  }
  
  .hero__article-title {
    font-size: 16px;
    font-weight: normal;
    font-family: 'NB Grotesk Pro', sans-serif;
    width: 100%; 
    text-align: left; 
    box-sizing: border-box; 
    transform: translateX(-1rem);
    margin-top: 3rem;
    margin-left: -1rem;
  }
  
}
