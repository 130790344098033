.comingSoonPage {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;
}

.topSection {
    background-image: url('../../assets/images/ComingSoonBg.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: 100svh;
    padding: 5% 10%;
}

.topSectionContentContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
}

.logoContainer img {
    height: auto;
    width: 150px;
}

.titleContainer {
    margin-top: 5vh;
}

.title {
    color: #FFF;
    text-align: center;
    font-family: "ABC Diatype";
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.textContainer {
    margin-top: 8vh;
    max-width: 730px;
}

.text {
    color: #FFF;
    text-align: center;
    font-family: "NB Grotesk Pro Mono";
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
}

.emailInputContainer {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 8vh auto;
}

.emailInput {
    border-radius: 40px;
    background: var(--Off-White, #F7F6E6);
    border: none;
    padding: 16px 42px;
    width: 100%;
    box-sizing: border-box;
    font-size: 18px;
    outline: none;
}

.emailInput::placeholder {
    color: rgba(93, 82, 70, 0.4);
    font-size: 18px;
    font-weight: 700;
}

.submitBtn {
    position: absolute;
    right: -10px;
    border-radius: 40px;
    background: #000;
    border: none;
    color: #FFF;
    text-align: center;
    font-family: "ABC Diatype";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    padding: 16px 24px;
    cursor: pointer;
    transform: translateX(50%);
}

.bottomSection {
    flex: 1;
    padding: 30px 20px;
    background-color: #F7F6E6;
    text-align: center;
}

.productSelectionContainer {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    gap: 500px;
    padding-bottom: 100px;
}

.errorMessage {
    color: white;
    font-family: "ABC Diatype";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    position: relative;
    
}

.subTitle {
    color: var(--Charcoal, #000);
    font-family: "ABC Diatype";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 104%;
    text-transform: uppercase;
    white-space: nowrap;
}

.productSelectionTextContainer {
    max-width: 500px;
    text-align: left;
}

.productSelectionText {
    color: #000;
    font-family: "NB Grotesk Pro Mono";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: left;
    max-width: 500px;
}


.brandsImagesContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 10px;
}

.brandImage {
    width: 100%;
    height: 0;
    padding-top: 100%;
    object-fit: cover;
    position: relative;
}

.brandImage img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.brandImage img.imgBrandLogo {
    width: 70%;
    height: auto;
    max-width: none;
    object-fit: initial;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.comingBrandsHeadContainer {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    padding-bottom: 10px;
}

.comingBrandsContainer {
    padding-bottom: 30px;
}

.latestTrendsContinaer {
    padding-bottom: 30px;
}

.trendsImage {
    width: 100%;
    height: 300px;
}

.trendsImage img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.latestTrendsContainer {
    padding-bottom: 50px;
    background-color: #F7F6E6;
}

.formContainer {
    width: 500px;
    height: 150px;
    margin-right: 10%;
    &.right {
        margin-right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.successContainer {
    position: relative;
    width: 500px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.successMessage {
    color: #FFF;
    text-align: center;
    font-family: "NB Grotesk Pro Mono";
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
    @media (max-width: 768px) {
        font-size: 12px;
    }
}

@media (max-width: 768px) {
    .logoContainer {
        margin-top: 5vh;
    }

    .brandsImagesContainer {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;
    }

    .title {
        font-size: 24px;
    }

    .text {
        font-size: 12px;
    }

    .emailInput {
        border: none;
        padding: 8px 21px;
        font-size: 12px;
        outline: none;
    }

    .emailInput::placeholder {
        color: rgba(93, 82, 70, 0.4);
        font-size: 12px;
        font-weight: 700;
    }

    .submitBtn {
        transform: translateX(30%);
        border-radius: 40px;
        padding: 8px 24px;
        font-size: 12px;
    }

    .textContainer {
        margin-top: 8vh;
    }

    .logoContainer img {
        width: 100px;
        height: auto;
    }

    .emailInputContainer {
        width: 90%;
        margin-top: 5vh;
        margin-bottom: 15vh;
    }

    .productSelectionContainer {
        gap: 100px;
        padding-bottom: 50px;
    }

    .subTitle {
        font-size: 10px;
    }

    .productSelectionText {
        font-size: 10px;
    }

    .comingBrandsHeadContainer {
        gap: 100px;
    }

    .bottomSection {
        padding: 10px 8px 20px 8px;
    }

    .formContainer {
        width: 300px;
        margin-right: 10%;
        height: 100px;
    }

}