.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 9998;
  opacity: 1;
  transition: opacity 0.3s ease-out;

  &.overlayClosing {
    opacity: 0;
  }

  @media (min-width: 1001px) {
    background: none;
  }
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 80%;
  max-width: 19.6rem;
  background: var(--Backgrounds-CERAMICS, #5d5246);
  color: var(--Off-White, #f7f6e6);
  z-index: 9999;
  padding: 1.5rem;
  box-shadow: -4px 0 12px rgba(0, 0, 0, 0.1);
  animation: slideIn 0.3s ease-out;

  &.closing {
    animation: slideOut 0.3s ease-out forwards;
  }

  @media (min-width: 1001px) {
    top: 56px;
    border-radius: 6px;
  }
}

.closeButton {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  cursor: pointer;
  width: 2.0625rem;
  height: 1.25rem;
  text-align: right;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem; /* 200% */
  text-transform: uppercase;
  color: var(--Off-White, #f7f6e6);
}

.content {
  margin-top: 3rem;

  .title {
    font-family: 'ABC Diatype';
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: 104%; /* 1.3rem */
    letter-spacing: -0.025rem;
    text-transform: uppercase;
  }

  .subtitle {
    font-family: 'NB Grotesk Pro Mono';
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 105%; /* 0.91875rem */
    letter-spacing: -0.0175rem;
    margin-top: 0.89rem;
    text-transform: capitalize;
  }
}

.section {
  margin-bottom: 2rem;
  max-width: 13rem;

  h3 {
    font-family: 'ABC Diatype';
    font-size: 0.625rem;
    font-style: normal;
    font-weight: 700;
    line-height: 110%; /* 0.6875rem */
    letter-spacing: -0.00625rem;
    text-transform: uppercase;
  }
}

.description {
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  width: 16.72538rem;
  line-height: normal;
  margin-top: 0.89rem;
  letter-spacing: -0.00875rem;
}
@keyframes slideIn {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideOut {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}
