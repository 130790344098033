html,
body {
  user-select: none;
  /* Standard syntax */
  -webkit-user-select: none;
  /* Chrome, Safari, iOS */
  -moz-user-select: none;
  /* Older Firefox versions */
  -ms-user-select: none;
  /* Internet Explorer/Edge Legacy */
}

.homepageContainer {
  overflow-x: hidden !important;
  overflow-y: hidden;
  max-width: 100vw;
  position: relative;
  background-color: #F7F6E7;
}

.headSection {
  max-height: 100svh;
}
