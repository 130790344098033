.desktopContainer {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-image: url('../../../../../assets/images/headSectionBackground.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.topSection {
    display: flex;
    height: 33vh;
    width: 100%;
}

.validationIcon {
    position: absolute;
    right: 10%;
    top: 25%;
    transform: translateY(-50%);
    width: 15px;
    height: 15px;
    pointer-events: none;
}

.menuTopSection {
    width: 33%;
    background-color: transparent;
}

.contentTopSection {
    flex-grow: 1;
    padding: 2% 15% 3% 2%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.formContainer {}

.inputField {
    width: 50%;
    position: relative;

    input {
        width: 90%;
        flex: 1;
        margin-bottom: 20px;
        padding: 10px 0;
        border: none;
        border-bottom: 1px solid #7E7063;
        outline: none;
        background: transparent;
        font-family: 'ABC Diatype', sans-serif;
        font-size: 10px;
        font-weight: 400;
        line-height: 11px;
        text-align: left;
    }
}

.bottomSection {
    display: flex;
    flex-grow: 1;
    width: 100%;
    background-color: #F7F6E6;
    overflow: hidden;
    border-radius: 14px 14px 0 0;
}

.menuSection {
    width: 33%;
    padding: 3% 5% 5% 10%;
}

.contentSection {
    flex-grow: 1;
    padding: 2% 15% 2% 2%;
}

.userName {
    color: #FFF;
    font-family: "ABC Diatype";
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    letter-spacing: -0.52px;
    text-transform: uppercase;
    margin: 0;
}

.btnBack {
    color: var(--Charcoal, #000);
    font-family: "ABC Diatype";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-transform: uppercase;
    background: none;
    border: none;
    cursor: pointer;
    margin-bottom: 30px;
}

.formHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--Charcoal, #000);
    font-family: "ABC Diatype";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 109%;
    text-transform: uppercase;
    margin-bottom: 18px;
}

.btnDelete {
    color: var(--Charcoal, #000);
    text-align: right;
    font-family: "ABC Diatype";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-transform: uppercase;
    background: none;
    border: none;
    cursor: pointer;
}

.submitBtn {
    border-radius: 41px;
    background: var(--Charcoal, #000);
    color: #F7F6E6;
    padding: 10px 30px;
    border: none;
    cursor: pointer;
    width: 30%;

    &:disabled {
        background-color: #858585;
        color: #F7F6E6;
    }
}

.error {
    font-size: 12px;
    color: #990202;
    margin-bottom: 10px;
}

.doubleFields {
    display: flex;
    justify-content: space-between;
}

.shortFields {
    width: 30%;
    margin-bottom: 20px;
    padding: 10px 0;
    border: none;
    border-bottom: 1px solid #7E7063;
    outline: none;
    background: transparent;
    font-family: 'ABC Diatype', sans-serif;
    font-size: 10px;
    font-weight: 400;
    line-height: 11px;
    text-align: left;
}

.checkboxWrapper48 {
    --checkbox-color: #7E7063;
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    margin-top: 10px;

    label {
        font-family: 'ABC Diatype', sans-serif;
        font-size: 10px;
        font-weight: 700;
        line-height: 11px;
        text-align: left;
        color: #7E7063;
        display: flex;
        align-items: center;
    }

    input {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        width: 20px;
        height: 20px;
        border: 1px solid var(--checkbox-color);
        margin: 0 5px 0 0;
        cursor: pointer;
    }

    input[type="checkbox"] {
        border-radius: 2px;
        box-sizing: border-box;
    }

    input:checked {
        border-color: transparent;
        background: var(--checkbox-color);
        box-shadow: 0 0 0 0.3em inset #F7F6E6;
        border-radius: 2px;
    }

    input:not(:checked):hover {
        border-color: var(--checkbox-color);
    }
}

.selectCountry {
    width: 30%;
    margin-bottom: 12px;
    padding: 10px 30px 10px 0px;
    border: none;
    border-bottom: 1px solid #7E7063;
    outline: none;
    font-family: 'ABC Diatype', sans-serif;
    font-size: 10px;
    font-weight: 400;
    line-height: 11px;
    text-align: left;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6"><path fill="%237E7063" d="M5 6L0 0h10z"/></svg>') no-repeat right 10px center;
    background-size: 10px 6px;
    cursor: pointer;
}

.mobileContainer {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: #F7F6E6;
    padding: 10% 14px;
}

.mobileHeaderSection {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
    margin-bottom: 13px;
}

.mobileTitle {
    color: var(--Charcoal, #000);
    font-family: "ABC Diatype";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 104%;
    text-transform: uppercase;
}

.mobileAddBtn {
    color: var(--Charcoal, #000);
    text-align: right;
    font-family: "ABC Diatype";
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    text-transform: uppercase;
    background: none;
    border: none;
}

.mobileForm {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
    padding-right: 33px;
}

.mobileSubTitle {
    color: var(--Charcoal, #000);
    font-family: "ABC Diatype";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 109%;
    text-transform: uppercase;
    margin-bottom: 20px;
}

.phoneField {
    display: flex;
    width: 50%;
    flex-direction: column;
    position: relative;

    .phoneInput {
        width: 90%;
        margin-bottom: 20px;
        padding: 10px 0;
        border: none;
        border-bottom: 1px solid #7E7063;
        outline: none;
        background: transparent;
        font-family: 'ABC Diatype', sans-serif;
        font-size: 10px;
        font-weight: 400;
        line-height: 11px;
        text-align: left;
    }
}

.phoneInput .react-phone-number-input__country {
    background: none;
    border: none;
    margin-right: 8px;
}

.phoneInput .react-phone-number-input__country svg {
    width: 16px;
    height: 16px;
}

.react-phone-number-input__icon {
    width: 16px !important;
    height: 16px !important;
}

.phoneInput input {
    flex: 1;
    border: none;
    outline: none;
    padding-left: 0;
    background: transparent;
}

.suggestionsContainer {
    display: flex;
    flex-direction: column;
    border-left: 2px solid #ccc;
    margin-bottom: 20px;
    width: 90%;
}

.suggestionItemContainer {
    padding: 2px 15px;
    overflow: hidden;
}


.suggestionItem {
    color: #a0a0a0;
    display: inline;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}

.suggestionItem:hover {
    color: black;
}

.suggestionItem:active {
    color: black;
}


@media (max-width: 768px) {
    .btnsContainer {
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 90%;
        margin: 0 auto;
    }

    .submitBtn {
        border-radius: 41px;
        background: var(--Charcoal, #000);
        color: #F7F6E6;
        padding: 10px 30px;
        border: none;
        cursor: pointer;
        text-transform: uppercase;
        width: 100%;

        &:disabled {
            background-color: #858585;
            color: #F7F6E6;
        }
    }

    .cancelBtn {
        background: transparent;
        color: var(--Charcoal, #000);
        font-family: "ABC Diatype";
        font-size: 10px;
        font-weight: 400;
        line-height: 20px;
        text-align: center;
        text-transform: uppercase;
        border: none;
        cursor: pointer;
        width: 100%;
    }

    .shortFields {
        width: 60%;
    }

    .selectCountry {
        width: 60%;
    }

    .inputField {
        width: 100%;
    }

    .phoneField {
        width: 100%;
    }
}