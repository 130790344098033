.combineSection {
  min-height: 100vh; // or larger if your content is very tall
  display: flex;
  flex-direction: column; // so components stack vertically
  background: var(--Articles-background, linear-gradient(180deg, #5D5246 0%, #928476 100%));
}

.space {
  padding-bottom: 50px;
}

.container {
  height: auto
}