.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  pointer-events: auto;
}

.loginPopup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  padding: 20px;
  background: var(--Off-White, #f7f6e6);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 99999;
  isolation: isolate;
  cursor: default;
  pointer-events: auto;
  user-select: none;
}

.loginPopupContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  pointer-events: auto;
}

.loginPopupTitle {
  font-size: 20px;
  color: #333;
  margin-bottom: 20px;
  font-family: 'NB Grotesk Pro Mono';
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
  letter-spacing: -0.14px;
}

.loginPopupSubtitle {
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.loginPopupSubtitleButton,
.continueButton {
  border-radius: 41px;
  background: var(--Charcoal, #000);
  display: inline-flex;
  padding: 10px 76px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 130px;
  cursor: pointer;
  font-family: 'ABC Diatype';
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-transform: uppercase;
  border: none;
  color: var(--CTA-White, #f7f6e6);
}

.loginPopupSubtitleButtonText,
.continueButtonText {
  all: unset;
  text-align: center;
  display: block;
}

.continueButton {
  border-radius: 41px;
  background: var(--Text-OFF-WHITE, #F7F6E6);
    display: inline-flex;
  padding: 10px 76px;
  align-items: center;
  width: 130px;
  gap: 10px;
  border: 1px solid var(--Charcoal, #000);
}

.continueButtonText {
  color: var(--CTA-White, #000);
  text-align: center;
  font-family: 'ABC Diatype';
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 200% */
  text-transform: uppercase;
}

@media (max-width: 768px) {
  .loginPopup {
    width: 90vw;
  }

  .loginPopupTitle {
    font-size: 12px;
  }

  .loginPopupSubtitleButton,
  .continueButton {
    padding: 3px 6px;
    gap: 5px;
    width: 80px;
  }
}