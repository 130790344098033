@media (max-width: 768px) {
    .mobileContainer {
        display: flex;
        flex-direction: column;
        height: 100vh;
        font-size: 14px;
        overflow-y: auto;
    }

    .headSection {
        background-image: url('../../../assets/images/headSectionBackground.jpg');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
        padding: 24px 14px;
        min-height: 50vh;
    }

    .sectionBottom {
        flex-grow: 1;
        background-color: #F7F6E6;
        padding: 0px 14px 20px 14px;
    }

    .userName {
        color: #FFF;
        font-family: "ABC Diatype";
        font-size: 26px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%;
        letter-spacing: -0.52px;
        text-transform: uppercase;
        margin: 0;
    }

    .mobileTitle {
        color: var(--Charcoal, #000);
        font-family: "ABC Diatype";
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%;
        letter-spacing: -0.32px;
        text-transform: uppercase;
        padding: 10px 0;
    }

    .mobileFunctionContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0;
        border-bottom: 1px solid #ccc;
    }

    .mobileFunctionContainerNoBorder {
        border-bottom: none;
    }

    .shopButtonContainer {
        padding: 0px 20px;
    }

    .seeAllBtn {
        color: var(--Charcoal, #000);
        text-align: right;
        font-family: "ABC Diatype";
        font-size: 10px;
        font-style: normal;
        font-weight: 700;
        line-height: 110%;
        letter-spacing: -0.1px;
        text-transform: uppercase;
        background: none;
        border: none;
        z-index: 100;
        cursor: pointer;
    }

    .mobileSubTitle {
        color: #000;
        font-family: "ABC Diatype";
        font-size: 10px;
        font-style: normal;
        font-weight: 700;
        line-height: 109%;
        text-transform: uppercase;
    }

    .mobileSubTitleContainer {
        padding: 10px 0;
    }

    .mobileCenterSubTitle {
        color: #000;
        text-align: center;
        font-family: "ABC Diatype";
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 109%;
        text-transform: uppercase;
        margin-bottom: 10px;
    }

    .mobileCenterText {
        color: var(--oli, #5D5246);
        text-align: center;
        font-family: "ABC Diatype";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: -0.14px;
    }

    .mobileShopButton {
        border-radius: 41px;
        background: var(--Charcoal, #000);
        color: #F7F6E6;
        padding: 10px 30px;
        border: none;
        cursor: pointer;
        text-transform: uppercase;
        width: 100%;
        margin: 20px 0;
        font-size: 10px;
    }

    .noOrdersContainer {
        padding: 16px 0;
    }

    .favoriteProductContainer {
        padding: 20px;
        position: relative;
        margin-bottom: 20px;
        height: 44svh;
        width: 100%;
    }

    .favoriteArticleContainer {
        padding: 20px;
        position: relative;
        height: 60svh;
        width: 100%;
    }
}