.step-indicator {
  display: inline-flex;
  align-items: flex-start;
  gap: 1px;
  margin-bottom: 20px;
  }

  .step-indicator span {
    margin-right: 5px
  }
  
  .step {
    font-size: 10px;
    color: #00000075;
    font-weight: normal;
    font-family: "ABC Diatype";
    font-weight: 700;
    line-height: 140%; /* 14px */
  }
  
  .step.active {
    color: var(--Charcoal, #000);
    font-family: "ABC Diatype";
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 14px */
  }
  