.desktopContainer {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-image: url('../../../assets/images/headSectionBackground.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.topSection {
    display: flex;
    height: 33vh;
    width: 100%;
}

.menuTopSection {
    width: 33%;
    background-color: transparent;
}

.contentTopSection {
    flex-grow: 1;
    padding: 2% 15% 3% 2%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.userName {
    color: #FFF;
    font-family: "ABC Diatype";
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    letter-spacing: -0.52px;
    text-transform: uppercase;
    margin: 0;
}

.bottomSection {
    display: flex;
    flex-grow: 1;
    width: 100%;
    background-color: #F7F6E6;
    overflow: hidden;
    border-radius: 14px 14px 0 0;
}
.menuSection {
    width: 33%;
    padding: 3% 5% 5% 10%;
}
.contentSection {
    flex-grow: 1;
    width: 60%;
    padding: 2% 15% 2% 2%;
}

.articlesContainer{
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    gap: 30px 10px;
    align-self: stretch;
    flex-wrap: wrap;
}
.articlesTitleContainer{
    position: relative;
    width: 100%;
    padding: 20px 0;
    text-align: start;
    top: 0%;
    z-index: 1;
}
.title{
    color: var(--Charcoal, #000);
    font-family: 'ABC Diatype';
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 26px */
    letter-spacing: -0.52px;
    text-transform: uppercase;
}
.articleCard{
    width: 253px;
height: 363.071px;
border-radius: 8px;
flex-shrink: 0;
background-size: cover;
background-position: center;
background-repeat: no-repeat;
@media (max-width: 768px) {
    width: 100%;
height: 250px;
}
}
.articleInfo {
    position: absolute;
    margin-top: 37%;
    padding-left: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}
@media (max-width: 768px) {
    .articleInfo {
        margin-top: 42%;
    }
}
.articleTitle{
    color: #FFF;
    font-family: "NB Grotesk Pro Mono";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%; /* 15.4px */
    letter-spacing: -0.14px; 
    width: 188px;
    @media (max-width: 768px) {
        font-size: 13px;
        width: 150px;
    }
}
.headerContainer{
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 98%;
    margin-left: 5px;
    margin-top: 10px;
}
.articleFavIcon{

}
.articleFavIconImg{
    width: 20px;
    height: 20px;
    cursor: pointer;
}
.dossierContainer{

    border-radius: 28px;
background: var(--WHITE, #F7F6E6);
display: inline-flex;
padding: 3px 5px;
justify-content: center;
align-items: center;
gap: 10px;
}
.dossierTitle{
    color: var(--Charcoal, #000);
    font-family: "ABC Diatype";
    font-size: 8px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
}
.noArticlesFound {
    position: absolute;
    text-align: center;
    left: 50%;
    top: 70svh;
    transform: translate(-50%, -50%);
    padding: 20px;
    @media (max-width: 768px) {
        position: relative;
        top: 0%;
        left: 0;
        transform: none;
        padding: 0;
    }
}

.eggShapeImage {
    width: 100%;
    height: 100%;
    stroke-width: 1px;
    stroke: rgba(0, 0, 0, 0.21);
    display: block;
    margin: 0 auto;

    
}

.noArticlesTextContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
}

.noArticlesButtonContainer {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
}
.noArticlesTitle{
    color: #000;
text-align: center;
font-family: "ABC Diatype";
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: 109%; /* 19.62px */
text-transform: uppercase;
margin-bottom: 10px;
}
.noArticlesSubtitle{
    color: var(--Charcoal, #000);
text-align: center;
opacity: 0.57;
font-family: "ABC Diatype";
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 17px; /* 121.429% */
letter-spacing: -0.14px;
padding-bottom: 10px;
}
.noArticlesButtonContainer{
    position: absolute;
    bottom: 90px;
    left: 50%;
    width: 80%;
    transform: translateX(-50%);
    display: inline-flex;
    padding: 10px 76px;
    align-items: center;
    gap: 10px;
    border-radius: 41px;
background: var(--Charcoal, #000);
display: flex;
justify-content: center;
align-items: center;
cursor: pointer;
}

.noArticlesButton {
background-color: transparent;
border: none;
color: var(--CTA-White, #F7F6E6);
text-align: center;
font-family: "ABC Diatype";
font-size: 10px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 200% */
text-transform: uppercase;
cursor: pointer;
}


.mobileContainer {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: #F7F6E6;
    padding: 10% 14px;
}

.mobileHeaderSection {
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 20px 0;
    margin-bottom: 13px;
}