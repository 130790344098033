.betaBadge {
    display: inline-flex;
    padding: 0.34019rem 0.68038rem;
    justify-content: center;
    align-items: center;
    gap: 0.34019rem;
    border-radius: 2.04113rem;
background: var(--Backgrounds-CLAY, #7F6F64);
background-blend-mode: multiply;
color: var(--Text-OFF-WHITE, #F7F6E6);
position: absolute;
bottom: 1.25rem;
right: 1.25rem;
text-transform: uppercase;
font-family: "ABC Diatype";
font-size: 0.51025rem;
font-style: normal;
font-weight: 700;
line-height: 110.457%; /* 0.56363rem */
}

