.success_checkoutWithHeaderContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: linear-gradient(to bottom right, #f7f6e6 50%, #c1b9aa 85%, #c8bda8 100%);
}

.success_completeOrderContainer {
  display: flex;
  width: 100%;
  justify-content: space-between; // Adjusts children to be on opposite sides
}

.success_imageContainer {
  flex: 1;
  display: block;
  height: 90vh;
  padding-bottom: 3%;
  border-radius: 10%;
  margin-left: 3%;
}

.success_valentineDaysBack {
  width: 45rem;
  height: 100%;
  object-fit: cover;
  border-radius: 10%;
}

.success_textLogoContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.success_checkoutSuccessIconContainer {
  margin-left: 8rem;
}

.success_checkoutSuccessIcon {
  width: 30%;
  height: auto;
  object-fit: cover;
}

.success_completeOrderTitle {
  color: var(--Charcoal, #000);
  text-align: center;

  font-family: 'ABC Diatype';
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  /* 30px */
  letter-spacing: -0.6px;
  text-transform: uppercase;
  width: 20rem;
}

.success_completeOrderSubtitle {
  color: #000;
  text-align: center;
  font-family: 'NB Grotesk Pro Mono';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 105%;
  /* 14.7px */
  letter-spacing: -0.28px;
  text-transform: capitalize;
  padding-top: 1rem;
}

.success_backToShopButtonContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}

.success_backToShopButton {
  background: black;
  border-radius: 41px;
  color: #f7f6e6;
  padding: 15px 20px;
  border: none;
  cursor: pointer;
  width: 30%;
  text-align: center;
  margin-top: 29px;
  font-weight: 700;
  font-size: 10px;
  font-family: "ABC Diatype";
}

//mobile
.mobile_success_checkoutWithHeaderContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: linear-gradient(to bottom right, #f7f6e6 50%, #c1b9aa 85%, #c8bda8 100%);
}

.mobile_success_checkoutSuccessIcon {
  width: 3rem;
  height: 3rem;
  object-fit: cover;
  justify-content: center;
  align-items: center;
  margin-left: 7rem;
}

.mobile_success_valentineDaysBack {
  display: none;
}

.mobile_success_completeOrderTitle {
  color: var(--Charcoal, #000);
  text-align: center;

  /* Title/Mobile/H2 */
  font-family: 'ABC Diatype';
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 104%;
  /* 20.8px */
  text-transform: uppercase;
}

.mobile_success_completeOrderSubtitle {
  color: #000;
  text-align: center;
  font-family: 'NB Grotesk Pro Mono';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 105%;
  /* 14.7px */
  letter-spacing: -0.28px;
  padding-top: 1rem;
}

.mobile_success_backToShopButton {
  border-radius: 41px;
  background: #000;
  color: #f7f6e6;
  display: flex;
  padding: 10px 76px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  margin-top: 10rem;
  margin-bottom: -9rem;
  margin-left: 1rem;
  font-family: "ABC Diatype";
  cursor: pointer;
  border: none;
}

.mobile_success_checkoutSuccessIconContainer {
  margin-bottom: 2rem;
}

.viewOrdersContainer {
  margin-top: 20px;
}

.viewOrdersText {
  color: #000;
  text-align: center;
  font-family: 'NB Grotesk Pro Mono';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 105%;
  letter-spacing: -0.28px;
  text-transform: uppercase;
  cursor: pointer;
  text-decoration: underline;
}

@media (max-width: 768px) {
  .viewOrdersContainer {
    display: flex;
    margin-top: 20px;
    width: 100%;
    justify-content: center;
  }
}