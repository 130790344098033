.loadingContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: var(--Off-White, #F7F6E6);

}

.collectionPageGrid {
    width: 100%;
    background: var(--Off-White, #F7F6E6);
    overflow: hidden;
}

.collectionPage {
    width: 100%;
    height: 100%;
    background: var(--Off-White, #F7F6E6);
    overflow-x: hidden;
}

.filter {
    position: sticky;
    top: 40px;
    height: fit-content;
    background: var(--Off-White, #F7F6E6);

}

.collectionPageContent {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    width: 100%;
    background: var(--Off-White, #F7F6E6);

}

.collectionCategories {
    flex: 30%;
    padding-right: 20px;
}

.collectionProducts {
    flex: 70%;
    padding-left: 20px;
}

.collectionPageGrid {
    width: 100%;
}

@media (max-width: 448px) {
    .collectionPageContent {
        flex-direction: column;
    }
}