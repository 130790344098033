/****************************************/
/*             DESKTOP STYLES           */
/****************************************/
.desktopContainer {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    height: 100%;
    background-image: url('../../../assets/images/headSectionBackground.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
  }
  
  .topSection {
    display: flex;
    height: 33vh;
    width: 100%;
  }
  
  .menuTopSection {
    width: 33%;
    background-color: transparent;
  }
  
  .contentTopSection {
    flex-grow: 1;
    padding: 2% 15% 3% 2%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  
  .userName {
    color: #FFF;
    font-family: 'ABC Diatype';
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    letter-spacing: -0.52px;
    text-transform: uppercase;
    margin: 0;
  }
  
  .bottomSection {
    display: flex;
    flex-grow: 1;
    width: 100%;
    background-color: #F7F6E6;
    overflow: hidden;
    border-radius: 14px 14px 0 0;
  }
  
  .menuSection {
    width: 33%;
    padding: 3% 5% 5% 10%;
  }
  
  .contentSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    width: 60%;
  }
  
  /* Shared grid styles—desktop first */
  .gridContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    padding: 20px;
    overflow-y: auto;
    margin-top: 0vh;
    scrollbar-width: none; /* for Firefox */
    scrollbar-color: transparent transparent;
  }
  
  .imageItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #EEEEDDed;
  }
  
  .favoriteImages {
    position: relative;
    width: 325px;
    height: 363px;
    display: flex;
    justify-content: center;
  }
  
  .faveImage {
    width: 225px;
    height: 363px; /* The second “height: auto;” was overridden, so decide which you need */
    border-radius: 8px;
    cursor: pointer;
  }
  
  .upperSection {
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 100%;
    top: 0;
  }
  
  .productInfo {
    text-align: center;
    padding-top: 10px;
    width: 100%;
    padding-left: 10px;
  }
  
  .brand {
    margin: 5px 0;
    color: var(--Charcoal, #000);
    text-align: left;
    font-family: 'ABC Diatype';
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%; /* 13.2px */
    letter-spacing: -0.12px;
    text-transform: uppercase;
  }
  
  .title {
    margin: 5px 0;
    color: var(--OLI-PRIMARY-BLACK, #000);
    text-align: left;
    font-family: 'NB Grotesk Pro Mono';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 16px */
    letter-spacing: -0.32px;
    text-transform: capitalize;
  }
  
  .price {
    color: var(--OLI-PRIMARY-BLACK, #000);
    font-family: 'NB Grotesk Pro Mono';
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 13px */
    letter-spacing: -0.26px;
    text-transform: capitalize;
    text-align: left;
    padding-bottom: 10px;
  }
  
  .favoriteIcon {
    padding-right: 10px;
    padding-top: 10px;
  }
  
  .faveIcon {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
  
  .justDroppedContainer {
    padding-left: 10px;
    padding-top: 10px;
  }
  
  .justDropped {
    color: var(--unnamed, #5D5246);
    font-family: 'ABC Diatype';
    font-size: 8px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
  }
  
  .loaderContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .allProductTitle {
    position: relative;
    width: 100%;
    padding: 20px 0;
    text-align: start;
    top: 0%;
    left: 3%;
    z-index: 1;
    height: 0%;
  }
  
  .allProductTitleText {
    color: #000;
    font-family: 'ABC Diatype';
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 109%; /* 15.26px */
    text-transform: uppercase;
  }
  
  .allProductTitleContainer {
    position: relative;
    width: 100%;
    padding: 20px 0;
    text-align: start;
    top: 0%;
    left: 3%;
    z-index: 1;
  }
  
  .allProductMainTitle {
    color: var(--Charcoal, #000);
    font-family: 'ABC Diatype';
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 26px */
    letter-spacing: -0.52px;
    text-transform: uppercase;
  }
  
  .noProductsFound {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 50%;
    top: 60%;
    transform: translate(-50%, -50%);
    z-index: 10;
  }
  
  .noFavoritesContainer {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .noFavoritesText {
    color: var(--unnamed, #5D5246);
    text-align: center;
    /* Title/H5 */
    font-family: 'ABC Diatype';
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 109%; /* 15.26px */
    text-transform: uppercase;
  }
  
  .noFavoritesSubText {
    color: var(--Charcoal, #000);
    text-align: center;
    opacity: 0.57;
    /* Text */
    font-family: 'ABC Diatype';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px; /* 121.429% */
    letter-spacing: -0.14px;
  }
  
  .noFavoritesButtonContainer {
    margin-top: 30px;
    display: inline-flex;
    padding: 10px 76px;
    align-items: center;
    gap: 10px;
    border-radius: 41px;
    background: var(--Charcoal, #000);
    cursor: pointer;
  }
  
  .noFavoritesButton {
    color: var(--CTA-White, #F7F6E6);
    text-align: center;
    font-family: 'ABC Diatype';
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 200% */
    text-transform: uppercase;
    background-color: transparent;
    border: none;
  }
.alignGrid{
  margin-left: 3%;
}
  
  /****************************************/
  /*             MOBILE STYLES            */
  /****************************************/
  @media (max-width: 768px) {
    .mobileContainer {
      display: flex;
      flex-direction: column;
      min-height: 100vh;
      background-color: #F7F6E6;
      padding: 10% 14px;
    }
  
    .mobileHeaderSection {
      display: flex;
      flex-direction: column;
      align-items: start;
      padding: 20px 0;
      margin-bottom: 13px;
    }
  
    .mobileBigTitle {
      color: var(--Charcoal, #000);
      font-family: 'ABC Diatype';
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 104%; /* 20.8px */
      text-transform: uppercase;
      margin-bottom: 20px;
    }
  
    .mobileTitle {
      align-self: stretch;
      color: #000;
      font-family: 'ABC Diatype';
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 109%; /* 15.26px */
      text-transform: uppercase;
      margin-top: 10px;
    }
  
    /* Overrides for .gridContainer on mobile */
    .gridContainer {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: 5px;
      margin-top: 0;
      padding: 0;
      min-height: 70svh;
      overflow-x: hidden;
    }
  
    /* Overrides for product images on mobile */
    .favoriteImages {
      width: 27svh;
      height: 300px;
    }
  
    .faveImage {
      width: 180px;
      height: 251px;
    }
  
    .productInfo {

    }
  
    .brand {
      text-align: left;
      font-size: 10px;
    }
  
    .title {
      text-align: left;
      font-size: 10px;
    }
  
    .price {
      text-align: left;
      font-size: 10px;
    }
  }
  
  @media (max-width: 380px) {
    .favoriteImages {
      width: 23svh;
      height: 241px;
    }
  }
  