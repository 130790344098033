.modalOverlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContent {
  background: #f8f6e9;
  padding: 20px;
  border-radius: 15px;
  text-align: center;
  max-width: 500px;
  height: 700px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1002;
}

@media (max-width: 768px) {
  .modalContent {
    width: 100%;
    height: 100%;
    margin: 0;
    border-radius: 0;
  }
}

.voucherHeaderContainer {
  display: flex;
  justify-content: flex-end;
}

.voucherHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  width: 100px;
}

.deleteButton,
.closeButton {
  background: none;
  border: none;
  font-size: 10px;
  cursor: pointer;
  color: black;
  font-family: 'ABC Diatype';
  font-weight: bold;
}

.voucherImageContainer {
  position: relative;
  width: 100%;
  height: 250px;
  background-size: cover;
  background-position: center;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 16px;
}

.discountText {
  position: absolute;
  bottom: 10px;
  right: 10px;

  color: white;
  padding: 5px 10px;

  font-size: 24px;
  font-family: 'NB Grotesk Pro';
}

.voucherContent {
  text-align: left;
}

.codeHeader {
  font-family: 'ABC Diatype';
  font-size: 20px;
  margin-bottom: 5px;
}

.expHeader {
  font-family: 'ABC Diatype';
  font-size: 10px;
  color: #5d5246;
  margin-bottom: 15px;
}

.styledHr {
  border: none;
  border-top: 1px solid rgba(0, 0, 0, 0.088);
  margin: 10px auto;
  margin-bottom: 15px;
}

.styledDescription {
  font-family: 'ABC Diatype';
  font-size: 13px;
}

.applyButton {
  background: black;
  font-family: 'ABC Diatype';
  font-weight: bold;
  font-size: 10px;
  color: #f7f6e6;
  padding: 10px 20px;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  margin-top: 20px;
  width: 253px;
  align-self: center;
}
