.inviteFriendsContainer {
  position: relative;
  width: 100%;
  height: 200px;
  border-radius: 8px;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  text-align: center;
}

.inviteImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
}

.inviteTitle,
.inviteText,
.inviteButton {
  z-index: 1;
  position: relative;
}

.inviteTitle {
  color: var(--Off-White, #f7f6e6);
  font-family: 'ABC Diatype';
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 109%;
  text-transform: uppercase;
  margin: 10px 0;
}

.inviteText {
  color: var(--Off-White, #f7f6e6);
  font-family: 'NB Grotesk Pro Mono';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 105%;
  letter-spacing: -0.28px;
  text-transform: capitalize;
  margin-bottom: 20px;
}

.inviteButton {
  border-radius: 41px;
  background: #f7f6e6;
  padding: 10px 76px;
  border: none;
  cursor: pointer;
  width: auto;
  text-transform: uppercase;
  color: var(--Charcoal, #000);
  text-align: center;
  font-family: 'ABC Diatype';
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-transform: uppercase;
}

@media (max-width: 768px) {
  .inviteFriendsContainer {
    margin-top: 0;
    margin-bottom: 20px;
  }

  .inviteTitle {
    font-size: 12px;
  }

  .inviteButton {
    width: 85%;
  }
}

.phoneInput {
  width: 80%;
  max-width: 300px;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #f7f6e6;
  border-radius: 25px;
  background: rgba(255, 255, 255, 0.1);
  color: #f7f6e6;
  text-align: center;
  z-index: 1;
  position: relative;

  &::placeholder {
    color: rgba(247, 246, 230, 0.7);
  }

  &:focus {
    outline: none;
    border-color: #fff;
  }
}

.inviteButton {
  background: #f7f6e6;
  color: #000;
  border: none;
  border-radius: 25px;
  padding: 10px 20px;
  cursor: pointer;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 12px;
  z-index: 1;
  position: relative;

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
}
