.LargePhotoSectionContainer {
  width: 100%;
  background-size: cover;

  height: 100dvh;
  // min-height: 101dvh;
  flex-shrink: 0;
}

.ContantContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  gap: 20px;
}

.LargePhotoSectionTitle {
  color: #FFF;

  text-align: center;
  font-family: "ABC Diatype";
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;

  @media (max-width: 768px) {
    width: 100%;
    font-size: 30px;
  }
}

.LargePhotoSectionSubTitle {
  color: #FFF;
  text-align: center;
  font-family: "NB Grotesk Pro Mono";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
  /* 22px */
  width: 700px;

  @media (max-width: 768px) {
    width: 100%;
    font-size: 14px;
    width: 323px;

  }
}

.LargePhotoSectionButton {
  display: inline-flex;
  padding: 8px 34px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 26px;
  background: var(--Off-White, #F7F6E6);
  color: var(--Charcoal, #000);
  font-family: "ABC Diatype";
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
}