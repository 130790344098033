@import '../../../index.scss';

@mixin ABCDiatype-Bold($size) {
  font-weight: bold;
  font-size: $size;
  font-family: 'ABCDiatype', sans-serif; // Assuming 'ABCDiatype' is the font-family name
}
.shopHeaderBlock {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100svh;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  position: relative;
}

.brandShopHeaderBlockContent {
  position: relative; /* allows child elements to be absolutely positioned */
  width: 100%;
  height: 100%; /* give it a fixed height (example) for proper centering */
}

/* Left container */
.BrandHeaderAdviceContainer {
  position: absolute;
  left: 5%;
  top: 50%;
  transform: translateY(-50%); /* vertically center within the header height */
  padding: 0 1rem;
}

/* Center container */
.ShopHeaderTitleContainer {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%); /* moves center to the exact midpoint horizontally & vertically */
  padding: 0 1rem;
}

/* Right container */
.brandHeaderRightTextContainer {
  position: absolute;
  right: 5%;
  top: 50%;
  transform: translateY(-50%); /* vertically center */
  padding: 0 1rem;
}

.BrandHeaderAdvice {
  color: var(--OLI-Off-White, #f7f6e6);
  font-family: 'NB Grotesk Pro Mono';
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 110.457%;
}

.brandHeaderRightText {
  color: var(--OLI-Off-White, #f7f6e6);
  font-family: 'NB Grotesk Pro Mono';
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 110.457%;
}

.ShopHeaderTitle {
  object-fit: contain;
  width: 345.885px;
  height: 75.512px;
}

.brandScrollDownSection {
  display: flex;
  width: 100%;
  justify-content: center;
  z-index: 1;
}
.scrollDownSection {
  position: absolute;
  bottom: -30px;
  z-index: 1;

  .imageWrapper {
    position: relative; // allows absolutely positioning the text
    display: inline-block;
    z-index: 1;
  }

  .scrollDownVector {
    // image styling, e.g. width, height, etc.
    display: block;
    width: 87.834px;
    height: 81.879px;
    // width: 100px;
    // height: auto;
  }

  .overlayText {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    color: #fff;
    text-align: center;
    font-family: 'NB Grotesk Pro Mono';
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 110.457%; /* 11.046px */
    // text-shadow: 1px 1px 2px rgba(0,0,0,0.7);
  }
}

/************************************** Mobile-specific styles */
/************************************** Mobile-specific styles */
/************************************** Mobile-specific styles */
/************************************** Mobile-specific styles */

@media (max-width: 1000px) {
  .shopHeaderBlock,
  .shopHeaderBlockPage {
    height: 100svh;
    padding: 20px;
    width: 100%;
  }

  .brandShopHeaderBlockContent {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 100%;
  }

  .BrandHeaderAdviceContainer {
    position: absolute;
    left: 0;
    right: 0;
    top: 15%;
    text-align: center;
  }
  .brandHeaderRightTextContainer {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    transform: translateY(55%);
  }

  .ShopHeaderTitleContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0;
    //border: 1px solid red;
  }
  .ShopHeaderTitle {
    width: 60vw;
    height: 200%;
    object-fit: contain;
  }
}
