/* Container for the order info */
.orderInfoContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-top: 1px solid rgba(0, 0, 0, 0.07);
    max-width: 800px;
    padding-bottom: 4.245px;

    margin-bottom: 20px;
    p {
        color: var(--Ceramic, #5D5246);
        font-family: "ABC Diatype";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 116%;
        /* 16.24px */
        text-transform: none;
    }
}

/* Title of the section */
.title {
    color: #000;
    font-family: "ABC Diatype";
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    letter-spacing: -0.52px;
    text-transform: uppercase;
    margin-top: 16px;
}

/* Horizontal layout for order info sections */
.orderInfo {
    display: flex;
    justify-content: space-between;
    /* Distribute the sections evenly */
    width: 100%;
    margin-top: 20px;
    color: #7E7063;
    font-family: "ABC Diatype", sans-serif;
    font-size: 12px;
    font-weight: 700;
    line-height: 110%;
    text-transform: uppercase;
}

.orderInfo {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
    color: #7E7063;
    font-family: "ABC Diatype", sans-serif;
    font-size: 12px;
    font-weight: 700;
    line-height: 110%;
    text-transform: uppercase;
}

.infoSection .left,
.infoSection .middle,
.infoSection .right {
    display: flex;
    align-items: center;
}

.infoSection .middle {
    justify-content: center;
    flex-grow: 1;
}

.infoSection .right {
    justify-content: flex-end;
}

.infoSection .left {
    justify-content: flex-start;
}

.label {
    color: var(--Charcoal, #000);
    font-family: "ABC Diatype";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 109%;
    text-transform: uppercase;
}



@media (max-width: 768px) {
    .title {
        color: #000;
        font-family: "ABC Diatype";
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%;
        letter-spacing: -0.32px;
        text-transform: uppercase;
    }

    .label {
        color: #000;
        font-family: "ABC Diatype";
        font-size: 10px;
        font-style: normal;
        font-weight: 700;
        line-height: 109%;
        text-transform: uppercase;
    }

    .orderInfo {
        color: var(--Ceramic, #5D5246);
        font-family: "ABC Diatype";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 116%;
    }

    .orderInfoContainer {
        padding-right: 15%;
    }

    .mobileDate {
        color: #000;
        font-family: "ABC Diatype";
        font-size: 10px;
        font-style: normal;
        font-weight: 700;
        line-height: 109%;
        text-transform: uppercase;
        margin-top: 30px;
    }

    .orderInfoContainer {
        margin-bottom: 0;
    }
}
