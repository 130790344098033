@import '../../../index.scss';

.articlePriviewContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 30px 0 30px 40px;
  width: 100%;
  background-color: #F7F6E6;
  position: relative;
  cursor: url('../../../assets/cursors/mouse_read.svg') 16 16, auto;
}

.articlePreviewImg {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 642px;
  width: 440px;
  border-radius: 8px;
  margin-right: 40px;
  cursor: url('../../../assets/cursors/mouse_read.svg'), auto;
}

.skinCareHeader {
  @include ABCDiatype-Bold(12);
  text-transform: uppercase;
  cursor: pointer;
}

.skinCareText {
  @include NBGroteskProMono-Regular(24);
  margin-top: 16px;
  max-width: 560px;
  cursor: url('../../../assets/cursors/mouse_read.svg'), auto;
}

.storyWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.storyIMG {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 50%;
  height: 63px;
  width: 63px;
}

.storyNumber {
  @include NBGroteskProMono-Regular(12);
  margin-top: 10px;
}

// ------------------------------------------------------------------
// Story container styles with loader border animations
.storyIMG-container {
  position: relative;
  height: 75px;
  width: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  svg {
    position: absolute;
    transform: rotate(-90deg);
    fill: none;
  }

  circle {
    stroke: #7e7063;
    stroke-width: 3;
    stroke-dasharray: 226;
    stroke-dashoffset: 226;
    transition: stroke-dashoffset 0.3s ease-in-out;
    animation: drawCircle 7s linear forwards;
  }

  .storyIMG {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 50%;
    height: 63px;
    width: 63px;
  }

  &:hover circle {
    stroke-dashoffset: 226;
    /* Reset */
  }
}

@keyframes drawCircle {
  to {
    stroke-dashoffset: 0;
  }
}

// Create pseudo-elements for left and right borders
.storyIMG-container::before,
.storyIMG-container::after,
.storyIMG-placeholder::before,
.storyIMG-placeholder::after {
  content: '';
  position: absolute;
  border-radius: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
}

.storyIMG-container::before,
.APcontentContainer::after {
  border: 3px solid transparent;
}


.textAndButtonContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
  margin-right: 45px;
  cursor: url('../../../assets/cursors/mouse_read.svg'), auto;
}

.textBlock {
  width: 50vw;
  margin-right: 6vw;
  cursor: url('../../../assets/cursors/mouse_read.svg'), auto;

  @media (min-width: 1024px) {
    width: 40vw;
  }
}

.APcontentContainer {
  justify-content: space-between;
  width: calc(100% - 500px);
  margin-top: 40px;
  min-width: 350px;
  display: flex;
  flex-direction: column;
  transition: opacity 1s ease-in-out, transform 1s ease-in-out;
  opacity: 1;
  transform: translateY(0);
}

.storiesContainer {
  display: flex;
  gap: 16px;
  justify-content: flex-end;
  margin: 0 40px 40px 0;
}

.storiesContainer-loggedOut {
  display: flex;
  gap: 16px;
  justify-content: flex-end;
  margin: 0 40px 40px 0;
}
.APHomeblackButton{
  border: none;
  cursor: pointer;
}


/* ------------------------------------
   Mobile Styles
   ------------------------------------ */
@media (max-width: 768px) {
  .articlePriviewContainer {
    padding: 20px;
    padding-bottom: 100px;
    flex-direction: column;
    align-items: center;
    position: relative; // Keep if you need a positioned container
  }

  .articlePreviewImg {
    width: 100%;
    height: 50vh;
    margin: 20px 0;
  }

  .APcontentContainer {
    width: 100%;
    margin-top: 20px;
    min-width: auto;
  }

  /* Let the stories container flow naturally */
  .storiesContainer-loggedOut {
    position: static;
    width: 100%;
    margin: 20px 0;
    gap: 16px;
    display: flex;
    justify-content: center;
  }

  .textAndButtonContainer {
    position: static;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .textBlock {
    width: 100%;
    margin-right: 0;
    text-align: left;
    margin-bottom: 16px;
  }

  .seeAllArticlesContainer {
    position: static;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    width: auto;
  }

  .APHomeblackButton {
    margin: 0 auto;
    width: 138px;
    text-align: center;
    margin-top: 50px;
    border: none;
  }

  .skinCareHeader {
    font-size: 10px;
    margin-bottom: 8px;
  }

  .skinCareText {
    font-size: 18px;
    max-width: 100%;
  }

  .storyWrapper {
    align-items: flex-start;
  }

  .storyIMG {
    width: 63px;
    height: 63px;
    border-radius: 63px;
    flex-shrink: 0;
  }

  .storyNumber {
    font-size: 12px;
    margin-right: 3vw;
  }

  .storyIMG-container,
  .storyIMG-placeholder {
    height: 70px;
    width: 70px;
    margin-right: 4vw;
  }
}

.APHomeblackButton {
  color: var(--Off-White, #F7F6E6);
  font-family: "ABC Diatype";
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  border-radius: 26px;
  background: #000;
  display: flex;
  max-width: 200px;
  min-width: 180px;
  padding: 8px 34px;
  justify-content: center;
  align-items: center;
  gap: 10px;
}