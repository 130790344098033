/* Container for the entire product collection */
html,
body {
  user-select: none;
  /* Standard syntax */
  -webkit-user-select: none;
  /* Chrome, Safari, iOS */
  -moz-user-select: none;
  /* Older Firefox versions */
  -ms-user-select: none;
  /* Internet Explorer/Edge Legacy */
}

.collectionProducts {
  display: flex;
  justify-content: flex-end;
  /* Align the grid to the right */
  align-items: flex-start;
  /* Align items to the top */
  width: 100%;
  margin: 0 auto;
  // commented
  // overflow-y: hidden;
  padding-top: 20px;
  // padding-right: 20px;
  padding-bottom: 20px;
  /* Add right padding */
  box-sizing: border-box;
  /* Ensure padding is included in width */
}

.productGrid {
  display: grid;
  grid-template-columns: repeat(var(--grid-columns, 4), 283px);
  /* Default to 4 columns */
  gap: 10px;
  width: auto;
  box-sizing: border-box;
  justify-content: start;
  direction: ltr;
  margin-bottom: 30px;
  padding-left: 0;
  overflow: hidden;

  @media (min-width: 1450px) {
    padding-right: calc((100vw - 1450px) * 0.1);
    /* Increase the multiplier to move more to the right */
  }

  @media (min-width: 1550px) {
    padding-right: calc((100vw - 1450px) * 0.5);
    /* Increase the multiplier to move more to the right */
  }

}

/* Individual product item with fixed dimensions */
.productGridItem {
  direction: ltr;
  width: 254.7px;
  height: 378px;
  border-radius: 8px;
  background: #F1EDDD;
  position: relative;
  /* Ensure positioned children are relative to this */
  /* No additional styles added */
  cursor: pointer;
  justify-self: center;
  align-items: center;
}

/* Product image with fixed dimensions */
.productGridItemImage {
  width: 226.8px;
  height: 294.3px;
  display: flex;
  justify-content: center;
  justify-self: center;
  flex-shrink: 0;
}

/* Upper section (e.g., labels and icons) */
.upperSectionSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* Ensures left and right alignment */
  align-items: center;
  /* Aligns items vertically in the center */
  width: 100%;
  padding-top: 5px;
  box-sizing: border-box;
  /* Ensure padding is included in width */
}

/* "Just Dropped" label container */
.justDroppedContainer {
  padding-left: 10px;
}

/* Favorite icon container */
.favoriteIconContainer {
  padding-right: 10px;
  cursor: pointer;
}

.favoriteIcon {
  width: 15px;
  height: 15px;
}

/* "Just Dropped" text styling */
.justDroppedText {
  color: var(--Backgrounds-CERAMICS, #5D5246);
  font-family: "ABC Diatype";
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}

/* Product information section */
.productInfo {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 13px;
}

/* Product brand styling */
.productBrand {
  color: var(--Charcoal, #000);
  font-family: "ABC Diatype";
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%;
  /* 13.2px */
  letter-spacing: -0.12px;
  text-transform: uppercase;
  padding-bottom: 6px;
}

/* Product title styling */
.productTitle {
  color: var(--OLI-PRIMARY-BLACK, #000);
  font-family: "NB Grotesk Pro Mono";
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  /* 13px */
  letter-spacing: -0.26px;
  text-transform: capitalize;
  padding-bottom: 3px;
}

/* Product price styling */
.productPrice {
  color: var(--OLI-PRIMARY-BLACK, #000);
  font-family: "NB Grotesk Pro Mono";
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  /* 13px */
  letter-spacing: -0.26px;
  text-transform: capitalize;
}

@media (max-width: 1390px) and (min-width: 1090px) {
  .collectionProducts {
    justify-content: flex-start;
  }

  .productGrid {
    grid-template-columns: repeat((var(--grid-columns, 3)), 283px);
    /* Use CSS variable */
    margin-left: auto;
    /* Push grid to right */
  }
}

/* Small screens (large phones) */
@media (max-width: 1090px) and (min-width: 786px) {
  .collectionProducts {
    justify-content: flex-start;
  }

  .productGrid {
    grid-template-columns: repeat(var(--grid-columns, 2), 283px);
    margin: 0;
    /* Reset margins for appropriate responsiveness */
    margin-right: auto;
    /* If any horizontal space exists, align grid right */
  }
}

/* Small screens (large phones) */
@media (max-width: 795px) and (min-width: 448px) {
  .collectionProducts {
    justify-content: flex-start;
  }

  .productGrid {
    grid-template-columns: repeat(var(--grid-columns, 2), 283px);
    margin: 0;
    /* Reset margins for appropriate responsiveness */
    margin-right: auto;
    /* If any horizontal space exists, align grid right */
  }
}

.scrollable {
  // commented
  // max-height: 850px; // Your desired height limit
  // overflow-y: auto;

  /* Firefox */
  scrollbar-width: none; // Hide the scrollbar in Firefox
  scrollbar-color: transparent transparent;

  /* WebKit browsers (Chrome, Safari, Opera, etc.) */
  &::-webkit-scrollbar {
    width: 0px; // Removes the scrollbar width
    background: transparent; // Transparent background
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: transparent; // Transparent thumb
    border: none;
  }
}



/* Extra small screens (small phones) */
@media (max-width: 486px) {
  .collectionProducts {
    display: flex;
    justify-content: center;
    /* Align the grid to the right */
    align-items: center;
    /* Align items to the top */
    margin: 0 auto;
    width: 100%;
    padding-top: 20px;
    flex-direction: column;
    box-sizing: border-box;
    /* Ensure padding is included in width */
    margin-left: 10px;
  }

  .recommendationsContainer {
    display: flex;
    align-items: start;
    align-self: stretch;
    padding-bottom: 20px;
    width: 100%;
  }

  .recommendationsTitle {
    margin-top: 5px;
    color: var(--Charcoal, #000);

    font-family: "ABC Diatype";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 104%;
    /* 14.56px */
    text-transform: uppercase;
  }

  /* Grid layout for products */

  .gridChangesContainer {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    width: 50%;
    gap: 10px;
  }

  .gridChanges {
    width: 24px;
    height: 24px;
  }

  .productGrid {
    display: grid;
    grid-template-columns: repeat(var(--grid-columns, 2), 180px);
    justify-content: center;
    /* Align the grid items to the right */
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    /* Ensure padding is included in width */
    margin-right: 0px;
    gap: 5px;
  }

  .productGridItem {
    max-width: 100%;
    min-width: 10vw;
    height: 310px;
    border-radius: 8px;
    background: #F1EDDD;
    position: relative;
    /* Ensure positioned children are relative to this */
    /* No additional styles added */
  }

  /* Product image with fixed dimensions */
  .productGridItemImage {
    width: 180px;
    height: 201px;
    flex-shrink: 0;
    flex-shrink: 0;
    object-fit: cover;
    /* Ensure the image covers the container without distortion */
    margin: 10px auto 0;
    /* Center the image horizontally within the grid item */
  }

  .productInfo {
    margin-top: 20px;
  }

  .productBrand {
    color: #000;

    font-family: "ABC Diatype";
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
    /* 11px */
    letter-spacing: -0.1px;
    text-transform: uppercase;
  }

  /* Product title styling */
  .productTitle {
    color: #000;
    font-family: "NB Grotesk Pro Mono";
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    /* 10px */
    letter-spacing: -0.2px;
    text-transform: capitalize;
  }

  /* Product price styling */
  .productPrice {
    color: #000;
    font-family: "NB Grotesk Pro Mono";
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    /* 10px */
    letter-spacing: -0.2px;
    text-transform: capitalize;
  }


  .singleColumn {
    // You can override the grid settings or even target nested elements
    grid-template-columns: 1fr;
    /* Example: change background color or padding on grid items */

    .productGridItem {
      max-width: 365px;
      min-width: 90vw;
      height: 600px;
      flex-shrink: 0;
      /* Any other style adjustments for a single column layout */
    }

    .productGridItemImage {
      width: 100%;
      height: auto;
      justify-content: center;
      align-items: center;

    }

    .productInfo {
      position: absolute;
      bottom: 10px;
      left: 10px;
    }
  }
}

.menuContainerSuggestionsTitle {
  width: 65%;
  display: flex;
  justify-content: center;
  padding-bottom: 1%;
  color: #000;
  text-transform: uppercase;
}