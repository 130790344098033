.categoriesSection {
    display: flex;
    width: 100%;
    padding: 10px;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    flex-shrink: 0;
    position: relative;
}
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: relative;
    padding: 10px 18px;
}

.titleContainer {
    position: absolute;
    left: 18px;
    font-size: 18px;
    font-weight: bold;
}

.countContainer {
    margin-left: auto;
    font-size: 16px;
    font-weight: bold;
}
.count{
    color: #000;

    text-align: right;
    font-family: "ABC Diatype";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%; /* 15.4px */
    letter-spacing: -0.14px;
    text-transform: uppercase; 
}


.title {
    color: var(--Charcoal, #000);
    font-family: "ABC Diatype";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 104%;
    text-transform: uppercase;
}

.categoriesContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin-top: 50px;
}

.categoriesGrid {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 80px;
    width: 100%;
}

.category {
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 150px;
}

.categoryImage {
    cursor: pointer;
    flex-shrink: 0;
}

.categoryText {
    color: var(--Charcoal, #000);
    font-family: "ABC Diatype";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 104%;
    text-transform: uppercase;
    padding-top: 10px;
    text-align: center;
}

.noCategoriesText {
    text-align: center;
    font-size: 14px;
    color: var(--Charcoal, #000);
}
@media (max-width: 440px) {
.categoriesContainer{
    margin-top: 0px;
}
.category{
    height: 150px;
}

}