.background {
  position: relative;
  min-height: 100vh;
  background: linear-gradient(180deg, #f7f6e6 0%, #f7f6e6 30%, rgba(204, 185, 167, 0.65) 70%, rgba(204, 185, 167, 0.45) 100%);
  overflow: hidden;
}

.backgroundResults {
  @media (min-width: 1001px) {
    background: #f7f6e6; // Plain background only for results page in desktop
  }
}

.content {
  position: relative;
  z-index: 2;
}
