.checkoutWithHeaderContainer {
  background: linear-gradient(to bottom right, #f7f6e6 50%, #c1b9aa 85%, #c8bda8 100%);
  min-height: 100vh;
  margin: 0 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
}

@media (max-width: 768px) {
  .checkoutWithHeaderContainer {
    align-items: center;
  }
}

@media (min-width: 1024px) {
  .checkoutWithHeaderContainer {
    align-items: center;
  }
}

.shippingList {
  list-style: none;
  padding: 0;
}

.shippingItem {
  margin-bottom: 10px;
}

.labelContainer {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}

.labelContainer span {
  margin-bottom: 5px;
}

input[type='checkbox'] {
  margin-bottom: 5px;
}

.checkoutContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding-top: 3%;
  flex-wrap: wrap;
  
}

.strikethroughPrice {
  text-decoration: line-through;
  color: grey;
}

.formHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.backText {
  margin-bottom: 19px;
}

.voucherInputSection {
  background: rgba(204, 185, 167, 0.32);
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.voucherHeader {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  margin-bottom: 10px;
  font-size: 10px;
  font-family: 'ABC Diatype';
}

.voucherTitleCode {
  font-size: 10px;
  font-family: 'ABC Diatype';
  color: #7e7063;
}

.voucherInputWrapper {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #7d7d7d;
  font-size: 10px;
  font-family: 'ABC Diatype';
}

.voucherInput {
  flex: 1;
  border: none;
  background: transparent;
  padding: 10px 0;
  font-size: 16px;
  color: black;
  font-size: 10px;
  font-family: 'ABC Diatype';
}

.applyButton {
  background: transparent;
  border: none;
  color: black;
  cursor: pointer;
  font-size: 10px;
  font-family: 'ABC Diatype';
}

.checkoutForm {
  padding-right: 3%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
  transform: translateY(0%);
  flex-wrap: wrap;
  padding-top: 50px;
  width: 60%;
}

.emptyCartContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
}

.emptyCartImg {
  height: auto;
  display: block;
}

.emptyCartText {
  font-family: 'ABC Diatype';
  color: #7e7063;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  line-height: 1;
  white-space: nowrap;
}

@media (max-width: 768px) {
  .checkoutForm {
    width: 100%;
    margin-left: 0;
    padding-top: 0px;
  }
}

.orderSummary {
  width: 460px;
  flex-shrink: 0;
  border-radius: 10px;
  background: var(--CTA-White, #f7f6e6);
  padding: 2%;
  display: flex;
  flex-direction: column;
  z-index: 1;
  flex-wrap: wrap;
  margin-top: 50px;
  flex-grow: 1;
  min-height: 100%;
}

.productListScroll {
  overflow-y: auto;
  max-height: 30vh;
  margin-top: 10px;
  margin-bottom: 10px;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #b3b3b3;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.headerContent {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.title {
  color: #000;
  font-family: 'ABC Diatype';
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 104%;
  letter-spacing: -0.4px;
  text-transform: uppercase;
}

@media (max-width: 768px) {
  .voucherInputSection {
    padding: 15px;
  }

  .voucherInput {
    font-size: 10px;
    font-family: 'ABC Diatype';
    color: black;
  }

  .applyButton {
    font-size: 10px;
    font-family: 'ABC Diatype';
    color: black;
  }
}

@media (min-width: 769px) {
  .voucherInputSection {
    padding: 20px;
  }

  .voucherInput {
    font-size: 10px;
    font-family: 'ABC Diatype';
    color: black;
  }

  .applyButton {
    font-size: 10px;
    font-family: 'ABC Diatype';
    color: black;
  }
}

.priceServiceContainer {
  display: flex;
  flex-wrap: wrap;
}

.priceServicePrice {
  display: flex;
  align-items: center;
}

.shippingSectionService {
  padding: 20px;
  color: var(--oli, #5d5246);
  font-family: 'ABC Diatype';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.12px;
}

@media (max-width: 768px) {
  .shippingSectionService {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
  }
}


.shippingSection {
  padding: 20px;
  color: var(--oli, #5d5246);
  font-family: 'ABC Diatype';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.12px;
}

@media (max-width: 768px) {
  .shippingSection {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
  }
}



.paymentSection {
  display: flex;
  flex-direction: column;
  padding: 20px;
  flex-wrap: wrap;
}

@media (max-width: 768px) {
  .paymentSection {
    padding-left: 0;
    padding-right: 0;
  }
}



.sectionShippingBox {
  border-bottom: 1px rgba(128, 128, 128, 0.273) solid;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-top: 20px;
  font-family: 'ABC Diatype-Bold', Arial, sans-serif;
  flex-wrap: wrap;
}

.sectionBillingShippingBox {
  border-bottom: 1px rgba(128, 128, 128, 0.273) solid;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-top: 20px;
  font-family: 'ABC Diatype-Bold', Arial, sans-serif;
  flex-wrap: wrap;
  width: 70%;

  @media (max-width: 768px) {
    width: 100%;
  }
}

.sectionTitle {
  color: #000;
  font-family: 'ABC Diatype';
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 15px;
  text-transform: uppercase;
}

.sectionBillingTitle {
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 15px;
  text-transform: uppercase;
}

.formTitle {
  font-size: 14px;
  margin-bottom: 18px;
  text-transform: uppercase;
  font-family: 'ABC Diatype-Bold', Arial, sans-serif;
}

.addressChange {
  color: #007bff;
  cursor: pointer;
  text-transform: uppercase;
}

.shippingOptions,
.paymentOptions {
  color: var(--Charcoal, #000);
  font-family: 'ABC Diatype';
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: -0.12px;
  text-transform: uppercase;
  display: flex;
  flex-wrap: wrap;
}

.productDescTitle {
  margin-top: 8px;
}

.productDescSize {
  margin-top: 16px;
}

.option {
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: bold;
  font-size: 14px;
  font-family: 'ABC Diatype-Bold', Arial, sans-serif;
}

.paymentForm {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 10px;
  column-gap: 30px;
  margin-top: 10px;
}

.formInputLabel {
  color: #7e7063;
  font-size: 10px;
  text-transform: uppercase;
  font-family: 'ABC Diatype-Bold', Arial, sans-serif;
}

.formInputBox {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.inputContainer {
  position: relative;
  width: 100%;
}

.formInput {
  padding: 10px;
  width: 100%;
  margin-bottom: 10px;
  background: none;
  border: none;
  font-size: 14px;
  font-family: 'ABC Diatype-Bold', Arial, sans-serif;
  border-bottom: 1px black solid;
  box-sizing: border-box;
  padding-right: 30px;
  /* Space for the icon */
}

.inputXIcon {
  position: absolute;
  right: 10px;
  top: 45%;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  pointer-events: none;
}

.inputVIcon {
  position: absolute;
  right: 5px;
  top: 40%;
  transform: translateY(-50%);
  width: 32px;
  height: 32px;
  pointer-events: none;
}

.formInputDate {
  padding: 10px;
  width: 40%;
  margin-bottom: 10px;
  background: none;
  border: none;
  font-size: 14px;
  font-family: 'ABC Diatype-Bold', Arial, sans-serif;
  border-bottom: 1px black solid;
}

.suggestionsContainer {
  display: flex;
  flex-direction: column;
  border-left: 2px solid #ccc;
}

.suggestionItem {
  color: #a0a0a0;
  padding: 2px 15px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.suggestionItem:hover {
  color: black;
}

.suggestionItem:active {
  color: black;
}

.saveInfo {
  grid-column: span 2;
  display: flex;
  align-items: center;
  gap: 10px;
}

.productBrand {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  font-family: 'ABC Diatype';
}

.product {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.priceAndActionsContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.priceContainer {
  text-align: right;
}

.plusMinusButtonsContainer {
  display: inline-flex;
  padding: 3px 5px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 23px;
  border: 0.5px solid var(--Charcoal, #000);
  margin-right: 20px;

  span {
    border: none;
    background: none;
    cursor: pointer;
    font-size: 10px;
    font-weight: 400;
    font-family: 'ABC Diatype';
    color: black;
  }
}

.quantityControlsContainer {
  display: flex;
  justify-content: space-between;

  button {
    border: none;
    background: none;
    cursor: pointer;
    font-size: 10px;
    font-weight: 400;
    font-family: 'ABC Diatype';
    color: black;
  }
}

.buttonsControlsContainer {
  display: flex;
  justify-content: space-between;

  button {
    border: none;
    background: none;
    cursor: pointer;
    font-size: 10px;
    font-weight: 400;
    font-family: 'ABC Diatype';
    color: black;
    margin-left: 3px;
  }
}

.billingAddressCheckboxContainer {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.billingAddressCheckboxText {
  color: var(--Charcoal, #000);
  font-family: 'ABC Diatype';
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.1px;
  text-transform: uppercase;
}

.billingAddressCheckboxText img {
  margin-right: 8px;
  vertical-align: middle;
}

@media (max-width: 768px) {

  .product h5,
  .product p,
  .productDescTitle,
  .productDescSize,
  .productPrice {
    font-size: 0.9em;
  }
}

.productImage {
  width: 80px;
  height: auto;
  object-fit: cover;
  background: #e6e4d4;
}

.formButton {
  background-color: black;
  color: #f7f6e6;
  text-transform: uppercase;
  border: none;
  border-radius: 41px;
  padding: 10px 74px;
  font-size: 14px;
}

.formButton:disabled {
  background-color: rgba(128, 128, 128, 0.487);
  color: #d1d1d1;
  cursor: not-allowed;
}

@media (max-width: 768px) {
  .productImage {
    width: 70px;
    height: 70px;
  }

  .checkboxLabel {
    display: flex;
    //align-items: center;
    //justify-content: center;
    //border: 1px solid #000;
  }

  .formButton {
    padding: 2% 2%;
    height: 100%;
    width: 100%;
    font-size: 0.7em;
  }
}

.productDetails {
  flex-grow: 1;
  width: 100px;
  padding-left: 10px;
}

.noItemsTitle {
  color: var(--Charcoal, #000);
  font-family: 'ABC Diatype';
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 141%;
  text-transform: uppercase;
}

.productDetails h5 {
  font-family: 'ABC Diatype-Bold', Arial, sans-serif;
  font-size: 18px;
}

.productDesc {
  color: var(--OLI-PRIMARY-BLACK, #000);
  font-family: "NB Grotesk Pro Mono";
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 105%;
  letter-spacing: 0.4px;
  position: relative;
}

.productPrice {
  font-family: 'NB Grotesk Pro Mono';
  font-size: 16px;
}

.deleteButton {
  color: #dc3545;
  cursor: pointer;
  text-transform: uppercase;
}

.totalSection {
  border-top: 1px solid #ccc;
  padding-top: 1px;
}

@media (max-width: 768px) {
  .totalSection {
    display: flex;
    flex-direction: column;
  }

  .totalPrice {
    color: black;
    margin-bottom: 15px;
  }
}

.totalRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.confirmButton {
  width: 100%;
  padding: 15px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 20px;
  text-transform: uppercase;
  font-weight: bold;
}

.totalPrice {
  color: black;
}

.sumupDetails {
  font-family: 'ABC Diatype-Bold', Arial, sans-serif;
  margin-top: 2%;
  margin-left: 60%;
  font-size: 14px;
  color: #5d5246;
  transform: translateY(15%);
}

@media (max-width: 768px) {
  .sumupDetails {
    margin-left: 35%;
  }
}

.subtotalDivPrice {
  font-family: 'NB Grotesk Pro Mono', Arial, sans-serif;
}

.backButton,
.shopButton {
  background: none;
  border: none;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 18px;
}

.backButton {
  margin-left: 30px;
}

.cartFooter {
  display: flex;
  flex-direction: column;
  margin-top: auto;
  flex-shrink: 0;
}

@media (max-width: 768px) {
  .cartFooter {
    align-items: inherit;
  }
}

.subtotal {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
}

.subtotalDiv {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.buttonContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}

.checkout-btn {
  background: var(--Charcoal, #000);
  color: #f7f6e6;
  font-size: 10px;
  font-weight: bold;
  border: none;
  cursor: pointer;
  width: 368px;
  height: 44px;
  margin-top: 29px;
  border-radius: 41px;
  font-family: 'ABC Diatype-Bold', Arial, sans-serif;
  text-align: center;
  font-style: normal;
  line-height: 20px;
  text-transform: uppercase;

  &:disabled {
    background: rgba(128, 128, 128, 0.55);
    color: #f7f6e6;
    cursor: not-allowed;
  }
}

@media (max-width: 768px) {
  .checkout-btn {
    width: 100%;
    margin-left: 0;
  }
}

.imageContainer {
  width: 45%;
  border-radius: 10%;
}

.valentineDaysBack {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10%;
}

.completeOrderTitle {
  font-family: 'ABC Diatype-Bold', Arial, sans-serif;
  font-weight: bold;
  width: 60%;
}

.completeOrderSubtitle {
  font-family: 'NB Grotesk Pro Mono', Arial, sans-serif;
  font-size: 18px;
  margin-top: 3%;
}

.textContainer {
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-right: 3%;
  font-size: 2.5em;
}

.backToShopButtonContainer {
  height: 4rem;
  width: 100%;
  border-radius: 10;
}

.checkoutSuccessIcon {
  width: 40%;
  object-fit: cover;
}

.checkoutPaymentContainer {
  padding: 20px;
  display: flex;
  flex-direction: column;
}

@media (max-width: 768px) {
  .checkoutPaymentContainer {
    padding-left: 0;
    padding-right: 0;
  }
}



.checkoutSuccessIconContainer {
  padding: 4%;
  width: 30%;
}

.servicePrice {
  color: var(--oli, #5d5246);
  font-family: 'ABC Diatype';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.12px;
}

.serviceName {
  font-family: 'ABC Diatype-Bold', Arial, sans-serif;
  margin-left: 3px;
  color: var(--oli, #5d5246);
}

.selectedServiceName {
  color: #000000;
  font-weight: bold;
}

.serviceDelivery {
  color: rgba(126, 112, 99, 1);
  color: var(--Text-Brown, #5d5246);
  font-family: 'NB Grotesk Pro Mono';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  /* 133.333% */
  letter-spacing: -0.12px;
}

.subOptions {
  margin-top: 10px;
  color: rgba(126, 112, 99, 1);
  color: var(--Text-Brown, #5d5246);
  font-family: 'NB Grotesk Pro Mono';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.12px;
}

.indentedRelayPoints {
  margin-left: 30px;
}

.addressCard {
  background-color: rgba(204, 185, 167, 0.21);
  padding: 20px 20px 10px 20px;
  border-radius: 5px;
  position: relative;
  width: 343px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.selectedAddressCard {
  background-color: #ccb9a7;
}

.cardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  color: #000;
  font-family: 'ABC Diatype';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 109%;
  text-transform: uppercase;
}

.selectedIcon img {
  display: block; // Ensures the image is not display:none
  width: 24px; // Adjust as necessary
  height: 24px; // Adjust as necessary
  margin-right: 10px;
}

.unselectedIcon {
  margin-right: 10px;
}

.addressDetails {
  color: #5d5246;
  font-family: Arial, sans-serif;
  flex-grow: 1;
}

.editButton {
  align-self: flex-end;
  background: none;
  border: none;
  color: black;
  cursor: pointer;
}

.formInput {
  padding: 10px;
  font-size: 16px;
  width: 100%;
  box-sizing: border-box;
  background: none;
  border-bottom: 1px solid #7e7063;
}

.customSelect {
  appearance: none;
  padding-right: 30px;
  background-image: url('data:image/svg+xml;charset=US-ASCII,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23999"><path d="M7 10l5 5 5-5z"/></svg>');
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 16px;
  border-radius: inherit;
  cursor: pointer;
  color: black;
}

.customSelect:focus {
  outline: none;
}

.addressForm {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 10px;
  column-gap: 30px;
  margin-top: 10px;
  border-radius: inherit;
  width: 70%;

  input {
    margin-bottom: 10px;
    padding: 10px;
    background: none;
    border: none;
    border-bottom: 1px #7e7063 solid;
  }
}

.addressCardWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.addAddressButton {
  display: flex;
  height: 14px;
  padding: 10px 50px;
  justify-content: center;
  background: none;
  border: none;
  align-items: center;
  font-size: 10px;
  gap: 10px;
  color: black;
  font-family: 'ABC Diatype-Bold', Arial, sans-serif;
}

@media (max-width: 768px) {
  .addAddressButton {
    padding: 10px 0;
  }
}

.addressbook_container {
  display: flex;
  flex-direction: column;

  .containerForm {
    display: flex;
    flex-direction: column;
  }
}

.addressHeader {
  display: flex;
}

.userEmail {
  margin-top: 20px;
  width: 50%;
}

.addressCards {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

@media (max-width: 768px) {
  .addressCard {
    width: 100%;
  }

  .checkoutWithHeaderContainer {
    background: linear-gradient(to bottom, #f7f6e6, #e0deca);
  }

  .orderSummary {
    width: 100%;
    display: block;
    margin: 0;
    position: static;
    z-index: 1;
    margin-top: 20px;
    height: auto;
    padding: 5%;
    margin-bottom: 30px;
  }

  .textContainer {
    font-size: 1.8em;
    margin: 40px 0;
  }

  .checkoutSuccessIconContainer {
    width: 60%;
    margin: 0 auto;
  }

  .addressForm {
    width: 70vw;
  }

  @media (max-width: 768px) {
    .addressForm {
      width: 100%;
    }
  }

  @media (min-width: 769px) and (max-width: 1023px) {
    .addressForm {
      width: 100%;
    }
  }

  .formButton {
    width: 30vw;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .imageContainer {
    display: none;
  }

  .checkoutContainer {
    width: 90vw;
    background: linear-gradient(to bottom, #f7f6e6, #e0deca);

  }

  .checkoutSuccessIcon {
    width: 10vw;
    height: 10vw;
  }

  .completeOrderTitle {
    font-size: 24px;
    width: 89vw;
  }

  .completeOrderSubtitle {
    font-size: 18px;
    width: 89vw;
  }

  .paymentSection {
    width: 90vw;
  }

  .customSelectOption {
    font-size: 10%;
  }

  @media (max-width: 768px) {
    .paymentSection {
      width: 100%;
    }

    .customSelectOption {
      font-size: 10%;
    }
  }
}

.errorMessage {
  color: #990202;
  font-size: 0.875rem;
  margin-top: 0.25rem;
}

@media (max-width: 768px) {

  .phoneInput input,
  .phoneInput {
    border-radius: 0;
    -webkit-border-radius: 0;
  }

  .formInput,
  .customSelect {
    border-radius: 0px;
    font-size: 14px;
    padding: 6px 10px;
    color: black;
  }

  .formInputLabel {
    font-size: 13px;
  }

  .formButton {
    font-size: 14px;
    padding: 8px 16px;
  }
}

@media (max-width: 480px) {

  .formInput,
  .customSelect {
    font-size: 12px;
    padding: 4px 8px;
    color: black;
  }

  .formInputLabel {
    font-size: 12px;
  }

  .formButton {
    font-size: 12px;
    padding: 6px 12px;
  }
}

.checkoutSuccessDiv {
  display: block;
}

@media screen and (min-width: 768px) {
  .checkoutSuccessDiv {
    display: flex;
    width: 100%;
    justify-content: space-around;
    padding: 50px 0;
  }
}

.backToHomeCheckoutComplete {
  background: var(--Charcoal, #000);
  color: #f7f6e6;
  font-size: 10px;
  font-weight: bold;
  border: none;
  cursor: pointer;
  border-radius: 41px;
  font-family: 'ABC Diatype-Bold', Arial, sans-serif;
  text-align: center;
  font-style: normal;
  line-height: 20px;
  padding: 12px 12px;
  text-transform: uppercase;
  width: 100%;
  margin-top: 200px;
}

@media (min-width: 1024px) {
  .backToHomeCheckoutComplete {
    margin-top: 30px;
    padding: 12px 12px;
    font-size: 14px;
    font-weight: 500;
    width: 50%;
  }
}