@font-face {
    font-family: 'ABC Diatype';
    src: url('../../../../../assets/fonts/ABCDiatype-Regular.woff2') format('woff2'),
        url('../../../../../assets/fonts/ABCDiatype-Bold.woff') format('woff');
    font-style: normal;
}

@font-face {
    font-family: 'NB Grotesk Pro';
    src: url('../../../../../assets/fonts/NBGroteskProMono-Regular.woff2') format('woff2'),
        url('../../../../../assets/fonts/NBGroteskProMono-Regular.woff') format('woff');
    font-style: normal;
}

.skinContainer {
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.skinHeaderContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 50px;
}

.skinHeader {
    color: var(--Charcoal, #000);
    font-family: "ABC Diatype";
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    letter-spacing: -0.52px;
    text-transform: uppercase;
}

.headerEditButton {
    color: var(--Charcoal, #000);
    text-align: right;
    font-family: "ABC Diatype";
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
    letter-spacing: -0.1px;
    text-transform: uppercase;
    text-decoration: none;
}

.skinDataContainer {
    width: 100%;
    max-height: 80%;
    overflow-y: hidden;
}

.skinInfoBox {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 0;
    border-bottom: 1px solid #ccc;
    min-height: 50px;
}

.skinInfoBoxNumber {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    width: 50px;
    height: 40px;
    flex-shrink: 0;
    padding-top: 5px;
}

.skinInfoBoxNumber img {
    width: 10px;
    height: auto;
}

.skinInfoBoxContent {
    display: flex;
    flex-direction: column;
}

.skinInfoBoxTitle {
    color: var(--Charcoal, #000);
    font-family: "ABC Diatype";
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
    letter-spacing: -0.1px;
    text-transform: uppercase;
    margin-bottom: 5px;
}

.skinUnderline {
    width: 100%;
    border: none;
    border-bottom: 1px solid #ccc;
}

.skinInfoBoxData {
    color: var(--OLI-PRIMARY-BLACK, #000);
    text-align: left;
    font-family: "NB Grotesk Pro Mono";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 105%;
    letter-spacing: 0.48px;
    text-transform: capitalize;
    
}

.riskItem {
    font-family: 'ABC Diatype';
    background-color: black;
    color: #F7F6E6;
    padding: 3px 9px;
    border-radius: 14px;
    border: 0.5px solid transparent;
    text-transform: uppercase;
    margin-right: 5px;
}

.buttonContainer {
    display: flex;
    justify-content: flex-end;
    margin-top: 25px;
}

.redoButton {
    border-radius: 41px;
    background: var(--Charcoal, #000);
    color: #F7F6E6;
    padding: 10px 30px;
    border: none;
    cursor: pointer;
    width: 30%;
}

@media (max-width: 768px) {
    .redoButton {
        display: none;
    }

    .skinHeader {
        color: var(--Charcoal, #000);
        font-family: "ABC Diatype";
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%;
        letter-spacing: -0.32px;
        text-transform: uppercase;
    }

    .headerEditButton {
        color: var(--Charcoal, #000);
        text-align: right;
        font-family: "ABC Diatype";
        font-size: 10px;
        font-style: normal;
        font-weight: 700;
        line-height: 110%;
        letter-spacing: -0.1px;
        text-transform: uppercase;
    }

    .skinInfoBoxTitle {
        color: var(--Charcoal, #000);
        font-family: "ABC Diatype";
        font-size: 10px;
        font-style: normal;
        font-weight: 700;
        line-height: 110%;
        letter-spacing: -0.1px;
        text-transform: uppercase;
    }

    .skinInfoBoxData {
        color: var(--OLI-PRIMARY-BLACK, #000);
        text-align: right;
        font-family: "NB Grotesk Pro";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 105%;
        letter-spacing: 0.48px;
        text-transform: capitalize;
    }

    .riskItem {
        font-family: 'ABC Diatype';
        background-color: black;
        color: #F7F6E6;
        padding: 3px 9px;
        border-radius: 14px;
        border: 0.5px solid transparent;
        text-transform: uppercase;
        margin-right: 5px;
        font-size: 8px;
    }
}