@import '../../../index.scss';

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.573);
  //background-color: blue;
  transition: transform 0.3s ease-in-out;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 22px;
  color: $color-white;

  &.hidden {
    transform: translateY(-100%);
  }

  &.visible {
    transform: translateY(0);
  }

  .navbar-left-logo {
    display: flex;
    align-items: center;
  }

  .navbar-logo {
    width: 35px;
    height: 20px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  .back-button-home {
    background-color: #f8f6f3;
    opacity: 0.2;
    border: none;
    color: black;
    padding: 10px 15px;
    border-radius: 25px;
    cursor: pointer;
    font-size: 12px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    width: 95px;

    &:hover {
      background-color: #d0cabcaf;
    }
  }

  .navbar-left {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .navbar-item {
    margin-left: 20px;
    font-family: 'ABC Diatype', sans-serif;
    font-weight: 700;
    font-size: 12px;

    color: #f7f6e6;
    cursor: pointer;
    text-decoration: none;
    z-index: 1000;

    &:hover {
      color: $color-gray-dark-1;
    }
  }
  .navbar-item-menu {
    position: absolute;
    left: 40%;
    color: var(--Off-White, #f7f6e6);
    font-family: 'ABC Diatype';
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    z-index: 100001;
    cursor: pointer;
    @media (max-width: 440px) {
      left: 15%;
    }
  }

  .navbar-item-black {
    margin-left: 20px;
    font-family: 'ABC Diatype', sans-serif;
    font-weight: 700;
    font-size: 12px;
    color: black !important;
    cursor: pointer;
    text-decoration: none;

    &:hover {
      color: $color-gray-dark-1;
    }
  }

  .navbar-right {
    display: flex;
    align-items: center;

    .cart-count {
      color: $color-black;
      padding: 2px 7.4px;
      margin-left: 5px;
      font-family: 'ABC Diatype', sans-serif;
      font-weight: 700;
      font-size: 12px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;

      &.product-page {
        color: #f7f6e6;
        background-size: contain;
      }
    }
  }

  .navbar-item.cart-container {
    margin-left: 40px;
  }
}

@media (max-width: 440px) {
  .navbar {
    display: flex;
    justify-content: space-between;
    padding: 10px 15px;
    align-items: center;
    height: 3vh;

    .navbar-left-logo {
      order: -1;
      position: fixed;
      left: 5px;
      height: 50px;
      z-index: 1000;
      display: flex;
      align-items: center;
      margin-right: 20px;
    }

    .navbar-logo {
      position: relative;
      margin-left: 2vw;
    }

    .navbar-item,
    .navbar-item-black {
      position: relative;
      margin: 0;
      font-family: 'ABC Diatype', sans-serif;
      font-weight: 700;
      font-size: 14px;
      white-space: nowrap;
      right: 0;
    }

    .back-button-home {
      margin-left: 35vw;
      padding: 4px 15px;
    }
    .navbar-item.cart-container {
      position: absolute; // Position it absolutely within its relative parent
      right: 2vw; // Anchor to the right side
      top: 50%; // Center vertically
      transform: translateY(-50%); // Adjust vertical positioning
    }
    .navbar-right {
      display: flex;
      align-items: center;
      .navbar-item{
        margin-left: 65vw;
      }

      .cart-count {
        margin-left: 2vw;

        @media (max-width: 768px) {
          width: 100px;
          height: 50px;
          background-size: contain;
        }
      }
    }
  }

  .navbar.checkout-page {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f7f6e6;

    .navbar-logo {
      position: fixed;
      left: 15px;
      top: 3%;
      transform: translateY(-50%);
    }

    .back-button-home {
      position: absolute;
      left: 60px;
      top: 4%;
      transform: translateY(-50%);
      display: flex;
      gap: 10px;
    }

    .navbar-item-black {
      margin-left: 40vw;
    }
  }
}

.dossiersContainer {
  display: flex;
  flex-wrap: wrap;
}

.navbar.feed-page {
  padding: 30px 55px;
}

.navbar.login-page {
  padding: 30px 55px;
}

.login-page-shop {
  display: none;
}

.login-page-signup {
  display: none;
}

.navbar.signup-page {
  padding: 0px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
