// General Reset for Consistency
@import '../../index.scss';

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

p {
  margin: 0;
}

ul {
  list-style: none;
  padding-left: 0;
  margin-left: 0;
}

.shopPageContainer {
  overflow-x: hidden;
  background-color: #f7f6e7;
}

.recommendedBlock {
  display: flex;
  padding: 20px;
  background-color: #f7f6e7;
  justify-content: flex-start;
  width: 100%;
  box-sizing: border-box;
  height: auto;
  // min-height: 100dvh;

  .recText,
  .recTextMain {
    text-transform: uppercase;
    font-family: 'ABC Diatype';
    font-weight: 900;
    font-size: 10px;
    color: rgb(128, 121, 109);
    padding: 15px;
    margin-bottom: 10px;
    position: relative;
  }

  .discover {
    font-family: 'NB Grotesk Pro Mono';
    font-size: 24px;
    max-width: 340px;
    margin-bottom: 40px;
    position: relative;
  }

  .rightSideContainer {
    flex: 1;
    justify-items: end;
  }

  // Cards Container
  .cardsContainer {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    padding-left: 0;

    // @media (max-width: 1570px) {
    //   grid-template-columns: repeat(3, 1fr);
    // }

    // @media (max-width: 1180px) {
    //   grid-template-columns: repeat(2, 1fr);
    // }
  }

  .leftSideContainer {
    flex: 0 0 204.769px;
    display: flex;
    flex-direction: column;
    padding: 0px 0px;
    align-items: flex-start;
  }

  // Product Container
  .productContainer {
    width: 283px;
    height: 420px;
    position: relative;
    background: var(--Text-OFF-WHITE, #EEEBD9);
    border-radius: 8px;

    // Product Image
    .productImage {
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      width: 200px;
      height: 300px;
      cursor: url('../../assets/cursors/mouse_shop.svg'), auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

    }

    // Product Shop Info
    .productShopInfo {
      text-align: left;
      margin-left: 2%;
      width: 100%;
      height: 100%;

      @media (max-width: 768px) {
        width: 0;
        height: 0;
      }
    }

    // Product Brand
    .productShopBrand {
      text-align: left;
      color: #000;
      font-family: "ABC Diatype";
      font-size: 12px;
      font-weight: 700;
      line-height: 110%;
      letter-spacing: -0.1px;
      text-transform: uppercase;
      margin-bottom: 30px;
      position: absolute;
      bottom: calc(30px + 0%);
      left: 10px;
    }

    // Product Title
    .productShopTitle {
      text-align: left;
      color: #000;
      font-family: "NB Grotesk Pro";
      font-size: 12px;
      font-weight: 400;
      line-height: 100%;
      letter-spacing: -0.2px;
      text-transform: capitalize;
      width: 80%;
      position: absolute;
      bottom: 30px;
      left: 10px;
    }

    // Product Price
    .productShopPrice {
      color: var(--OLI-PRIMARY-BLACK, #000);
      font-family: "NB Grotesk Pro Mono";
      font-size: 13px;
      font-weight: 400;
      line-height: 100%;
      letter-spacing: -0.26px;
      text-transform: capitalize;
      position: absolute;
      bottom: 10px;
      left: 10px;
    }

    // Discover Button Container
    .shopPageDiscoverButtonContainer {
      position: absolute;
      left: 0;
      bottom: 0;
    }
  }

  // Flex Container
  .flexContainer {
    display: flex;
    align-items: center;

    // Dot Icon
    .dotIcon {
      width: 9.02px;
      height: 8.34px;
      background-color: #000;
      margin-bottom: 10px;
    }
  }
}

// Space Section
.spaceSection {
  height: 5vh;
  background-color: #f7f6e7;
}

.spaceText {
  position: absolute;
  margin: 12px 0 10px 20px;
  text-transform: uppercase;
  font-family: 'ABC Diatype'; // Assuming ABCDiatype-Bold mixin sets font-family and weight
  font-weight: bold;
  font-size: 14px;
}

.APhomeblackButton {
  display: flex;
  width: 174px;
  padding: 10px 76px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 41px;
  background: var(--CTA-Black, #000);
  color: #fff;
  border: none;
}

.bestSellersContainer {
  height: 100%;
  width: 100%;
  margin-left: 40px;
}

// Recommended Block Styles - Mobile
@media (max-width: 768px) {
  .recommendedBlock {
    display: flex;
    flex-direction: column;
    .cardsContainer {
      margin-left: 0;
      padding-top: 10px;
      grid-template-columns: repeat(2, 1fr);
    }

    .productContainer {
      width: 180px;
      height: 310px;

      .productImage {
        width: 180px;
        height: 245px;
        top: 40%;

      }
    }

  }

}