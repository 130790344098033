// Mobile first - styles here will apply to all screens by default
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 158px;
  padding: 0 24px;
  text-align: center;
  font-family: 'ABC Diatype';
  position: relative;
}

.title {
  color: var(--Charcoal, #000);
  font-size: 54.576px;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: -1.092px;
  text-transform: uppercase;
  max-width: 332.626px; // Using max-width instead of width for better responsiveness
  margin: 0 auto; // Center the h1 if it's smaller than container

  // Remove the default margin that browsers add to h1
  margin-top: 0;
  margin-bottom: 0;
}
.subtitle {
  color: #000;
  text-align: center;
  font-family: 'NB Grotesk Pro Mono';
  font-size: 14px;
  margin-top: 5px;
  font-style: normal;
  font-weight: 400;
  line-height: 105%; /* 14.7px */
  letter-spacing: -0.28px;
  text-transform: capitalize;
}

.steps {
  display: flex;
  width: 362px;
  padding: 20px 0px;
  flex-direction: column;
  justify-content: center;
  margin-top: 52.55px;
  align-items: center;
  counter-reset: step-counter;
}

.step {
  color: var(--Charcoal, #000);
  font-family: 'ABC Diatype';
  font-size: 25.437px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: -0.509px;
  text-transform: uppercase;
  display: flex;
  align-items: flex-start;
  width: 100%;

  &::before {
    counter-increment: step-counter;
    content: counter(step-counter) '.';
    color: #000;
    font-family: 'ABC Diatype';
    font-size: 25.437px;
    font-style: normal;
    font-weight: 700;
    min-width: 35px;
    margin-right: 8px;
  }
}

.buttonsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
  justify-content: center;
  gap: 16px;
}

@media (min-width: 1000px) {
  .container {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 246px;
    margin-left: 9rem;
    gap: 2rem;
    margin-right: 11rem;
  }
  .title {
    width: 350px;
    font-size: 52.098px;
    line-height: 100%;
    text-align: start;
    letter-spacing: -1.002px;
    margin: 0;
  }
  .subtitle {
    width: 382px;
    text-align: start;
    font-size: 14px;
    line-height: 105%; /* 14.7px */
    letter-spacing: -0.28px;
    margin: 0 auto;
    margin-top: 2rem;
  }
  .steps {
    width: 350px;
    line-height: 80%; /* 36px */
    margin-top: 0;
    align-self: flex-start;
  }
  .step {
    font-size: 18px;
  }
  .scanButtonContainer {
    margin-top: 2rem;
    align-self: flex-start;
  }
  .buttonsContainer {
    margin-top: 10rem;
  }
}

// Desktop
@media (min-width: 1200px) {
  .container {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 246px;
    margin-left: 9rem;
    margin-right: 11rem;
  }
  .title {
    width: 457.707px;
    // font-size: 63.098px;
    font-size: 70.098px;
    line-height: 100%; /* 75.098px */
    text-align: start;
    letter-spacing: -1.502px;
    margin: 0;
  }
  .subtitle {
    width: 382px;
    text-align: start;
    font-size: 14px;
    line-height: 105%; /* 14.7px */
    letter-spacing: -0.28px;
    margin: 0 auto;
    margin-top: 2rem;
  }
  .steps {
    width: 495.447px;
    line-height: 120%; /* 36px */
    margin-top: 0;
    align-self: flex-start;
  }
  .step {
    font-size: 30px;
  }
  .scanButtonContainer {
    margin-top: 3rem;
    align-self: flex-start;
  }
  .buttonsContainer {
    margin-top: 12rem;
  }
}
