.mainContainer {
  width: 100%;
  white-space: nowrap;
  padding: 0vw 20vw;
  position: relative;
  z-index: 1;
  cursor: pointer;
}

.allImgsContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  overflow-x: scroll;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;
  height: 100px;
}

.allImgsContainer::-webkit-scrollbar {
  display: none;
}

.imgContainer {
  position: relative;
  display: inline-block;
  margin-right: 5px;
}

.selectedImg {
  position: absolute;
  top: 0;
  left: 0;
  width: 70px;
  height: 100px;
  object-fit: contain;
  z-index: 2;
}

.miniProductImg {
  position: relative;
  z-index: 1;
  width: 46px;
  height: 70px;
  object-fit: contain;
  margin-top: 20px;
  margin-left: 10px;
}

.dotImg {
  display: none;
}

@media (max-width: 1360px) {
  .mainContainer {
    padding: 0vw;
    height: 110px;
    width: 100%;
  }

  .allImgsContainer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    overflow-x: scroll;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    -ms-overflow-style: none;
    position: relative;
  }

  .selectedBar {
    width: 40px;
    height: 2px;
    flex-shrink: 0;
    border-radius: 26px;
    background: var(--Beige, #CCB9A7);
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
  }

  .imgContainer {
    position: relative;
    display: inline-block;
    margin-right: 5px;
    height: 80px;
  }

  .miniProductImg {
    width: 100%;
    height: 80%;
    position: relative;
    z-index: 3;
    object-fit: contain;
    margin-top: 0;
    margin-left: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .selectedImg {
    top: auto;
    bottom: 0.5vh;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 50%;
    z-index: 0;
    //  width: 40px; // Adjust egg shape size if needed
    //  height: 40px; // Adjust egg shape size if needed
  }

  .dotImg {
    display: block;
    position: absolute;
    top: auto;
    bottom: 0.8vh;
    left: 50%;
    transform: translateX(-50%);
    width: 4px;
    height: 4px;
    z-index: 0;
  }

  .horizontalLine {
    position: absolute;
    top: 83px;
    left: 0;
    width: 100%;
    border-top: 1px solid rgba(204, 185, 167, 0.2);
  }
}