@import '../../../index.scss';

.mobileHeader {
  color: #000;
  background-color: transparent;
  background: inherit;
  font-family: 'ABC Diatype Unlicensed Trial-Bold', Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%;
  letter-spacing: -0.14px;
  text-transform: uppercase;
  padding: 20px 20px 0px 20px;
}

.container {
  padding: 20px 20px 20px 30px;
  position: relative;
  z-index: 0;

  .header {
    @include ABCDiatype-Bold(20);
    text-transform: uppercase;
  }

  .description {
    @include NBGroteskProMono-Regular(15);
  }

  .headerBlock {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .indicatorContainer {
    display: flex;
    margin-top: 20px;
    justify-content: center;

    @media (max-width: 768px) {
      display: none;
    }
  }
}

@media (max-width: 768px) {
  .container {
    padding-top: 0px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 0px;
  }

  .headerBlock {
    margin-bottom: 0px;
  }
}