.dossier-list-container {
  display: flex;
  height: 100vh;
  font-family: "Arial", sans-serif;
  overflow: hidden;
}

.dossier-list__image {
  flex: 1;
  background-size: cover;
  background-position: center;
  position: relative;
  height: 100vh;
}

.dossier-list__image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 40px;
  background: rgba(0,0,0,0.2);
}

.dossier-list__title {
  font-family: "NB Grotesk Pro Mono";
  font-weight: 400;
  font-size: 32px;
  color: white;
  max-width: 50%;
  text-transform: uppercase;
}

.dossier-list__article-count {
  font-size: 18px;
  color: white;
}

.dossier-list__articles-content {
  flex: 1;
  background-color: #EBE6D5;
  overflow-y: auto;
  height: 100vh;
  
}

.dossier-list__back-button {
  position: absolute;
  top: 40px;
  left: 20px;
  z-index: 3;
  background-color: rgba(255, 255, 255, 0.2);
  border: none;
  border-radius: 50px;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: rgba(255, 255, 255, 0.5);
  }
}

.dossier-list__articles-section {
  padding: 0 40px 40px;
}

.dossier-list__articles-heading {
  font-size: 24px;
  margin: 40px 0 20px;
}

.dossier-list__articles-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.dossier-list__article-item {
  text-decoration: none;
  color: inherit;
  border-radius: 10px;
  overflow: hidden;
  transition: transform 0.3s ease;
  position: relative;
  left: 0%;
  width: 100%;


  &:hover {
    transform: translateY(-5px);
  }

  &.dossier-list__top-viewer {
    grid-column: 1 / -1;
    overflow: visible;
    
    .dossier-list__article-image {
      border-radius: 10px;
      overflow: hidden;
      width: 100%;
    }
  }
}

.dossier-list__article-image {
  width: 100%;
  height: 300px;
  background-size: cover;
  background-position: center;
  position: relative;
  .favArticle{
    position: absolute;
    top: 1rem;
    right: 1rem;
    z-index: 1000;
  }

  .dossier-list__top-viewer & {
    height: 650px;
  }
}

.dossier-list__article-info {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  background: linear-gradient(to top, rgba(0,0,0,0.7), rgba(0,0,0,0));
  color: white;
 
}

.dossier-list__article-title {
  font-size: 18px;
  margin: 0 0 10px 0;
}

.dossier-list__article-category {
  font-size: 12px;
  text-transform: uppercase;
  background-color: rgba(204, 185, 167, 0.7);
  color: white;
  padding: 3px 8px;
  border-radius: 20px;
  display: inline-block;
}
@media (max-width: 768px) {
  .dossier-list-container {
    display: flex;
    flex-direction: column;
    overflow: visible;
  }
  .dossier-list__image{
    flex: 0.6 ;
    height: 50vh;
    background-size: cover;
    background-position: center; 
  }
  .dossier-list__image-overlay {
    height:auto;
    display: flex;
    flex-direction: column; /* Stack items vertically */
    justify-content: center;
    align-items: center;
  }
  .dossier-list__articles-content {
    margin-bottom: -100vh;
  }
  .dossier-list__title {
    max-width: 100%;
    color: var(--WHITE, #F7F6E6);
    font-family: "ABC Diatype";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 104%; /* 24.96px */
    text-transform: uppercase;
    margin: 0; /* Remove extra margin */
    text-align: center; /* Center align the text */
  }

  .dossier-list__article-count {
    color: #FFF;
    font-family: "ABC Diatype";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    margin-top: 1rem; /* Add spacing between title and count */
    text-align: center; /* Center align the text */
  }
  .dossier-list__articles-section{
    padding: 0 1rem 0rem;
  }

  .dossier-list__article-item{
    width: 100%;
    background-color: #e7b719;
  }

  .dossier-list__article-image{
    width: 100%;
    height: 238px;
    position: relative;
  }
  .dossier-list__articles-grid{
    display: flex;
    flex-direction: column;
    width: 100%;
    
  }

  .dossier-list__article-info{
    height: 100%;
    position: relative;
    width: 100%;
    border-radius: 15px;
  }
  .dossier-list__article-title{
    position: absolute;
    bottom: 0;
    left: 0.5rem;
    color: #FFF;
font-family: "NB Grotesk Pro";
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 110%; /* 17.6px */
letter-spacing: -0.16px;
  }
  .dossier-list__article-category {
    position: absolute; /* Position the element relative to its container */
    top: 1.5rem; /* Adjust as needed to align properly */
    left:1rem; /* Adjust as needed to align properly */
    transform: none; /* Remove unnecessary transformation */
    border-radius: 28px; /* Keep the rounded corners */
    background: var(--WHITE, #F7F6E6);
    color: var(--Charcoal, #000);
    font-family: "ABC Diatype";
    font-size: 8px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    padding: 5px 10px; /* Add padding for better appearance */
    z-index: 10; /* Ensure it appears above other elements */
}

}