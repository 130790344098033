.timelineContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.section {
    display: flex;
    justify-content: center;
    align-items: center;
}

.image {
    max-width: 50px;
    height: auto;
}

.line {
    background-color: #CCB9A7;
    width: 2px;
    height: 70px;
}

.fullBlackLine {
    background-color: black;
}

.firstThirdBlackLine {
    background: linear-gradient(to right, black 33%, #CCB9A7 33% 100%);
}

.fullBeigeLine {
    background-color: #CCB9A7;
}

@media (min-width: 767px) {
    .timelineContainer {
        flex-direction: row;
        align-items: center;
    }

    .line {
        width: 120px;
        height: 2px;
    }
}

@media (max-width: 768px) {
    .firstThirdBlackLine {
        background: linear-gradient(to bottom, black 33%, #CCB9A7 33% 100%);
    }
}