.carouselContainer {
  overflow: visible !important; // Force overflow visibility
  position: relative;
  z-index: 1;
  // border: 10px solid red;
  cursor: url('../../../assets/cursors/dragCursor.svg') 40 30, auto;

  //padding-bottom: 2%;
  transform-origin: 50% 50%; // Center the transform origin
  transition: all 0.3s ease;

  &:hover {
    //      transform: scale(1.05);
    //  transform: translate(-2.5%, -2.5%) scale(1.05); // Adjust position while scaling

    z-index: 2;
  }
}

// Add this new style to ensure parent containers don't clip
:global {
  .swiper-container {
    overflow: visible !important;
  }

  .swiper-wrapper {
    overflow: visible !important;
  }
}

.indicatorContainer {
  display: flex;
  justify-content: flex-end;
  //position: absolute;
  //bottom: 0;
  //margin-bottom: 20px;
  //border: 5px solid red;

  z-index: 2;

  &:hover {
    cursor: auto;
  }
}

.indicatorContainerShopPageUp {
  display: flex;
  justify-content: center;
  position: absolute;
  right: 0;
  top: -7%;
  // border: 10px solid blue;
}

.indicatorContainerShopPageDown {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: -2%;
  left: 50%;
  height: fit-content;
  width: fit-content;
  //padding-top: 50px;
}

.indicatorContainerShopPageNone {
  display: none;
}

@media (max-width: 768px) {
  .carouselContainer {
    //border: 5px solid red;
  }

  .indicatorContainer {
    // border: 5px solid blue;
    right: 0;
    top: -10vw;
  }

  .indicatorContainerShopPageDown {
    left: 0%;
  }
}