@import '../../../index.scss';

.SaferIngredientsContainer {
    background: transparent;
    // min-height: 100dvh;
    /* Use auto height to fit content */
    display: flex;
    justify-content: space-between;
    padding: 20px;
    // height: 800px;
    // cursor: url('../../../assets/cursors/mouse_read.svg'), auto;


    @media (max-width: 1024px) {
        flex-direction: column;
        padding: 40px 20px;
    }

    @media (max-width: 768px) {
        padding: 20px;
        height: 100svh;


    }

    .containerBottom {
        width: 600px;

        //banner top
        margin-top: 10px;

        /* Replace large margin */
        @media (max-width: 768px) {
            width: 100%;
            margin-top: 45px;
            /* Adjust for smaller screens */
        }
    }
}

.SaferIngredientsDot {
    @media (max-width: 768px) {
        transform: translateX(70%);
    }
}

.SaferIngredientsheaderDescription {
    color: #FFF;

    font-family: "ABC Diatype";
    font-size: 9.022px;
    font-style: normal;
    font-weight: 700;
    line-height: 122%;
    /* 11.007px */
    text-transform: uppercase;

    @media (max-width: 768px) {
        font-size: 14px;
        /* Larger font size for mobile */
        width: 95vw;
        color: var(--Off-White, #F7F6E6);
        font-family: "ABC Diatype";
        font-size: 8px;
        font-style: normal;
        font-weight: 700;
        line-height: 122%;
        /* 9.76px */
        text-transform: uppercase;
        align-self: stretch;
    }
}

.SaferIngredientsheaderHeader {
    color: #FFF;
    font-family: "ABC Diatype";
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 95%;
    /* 38px */
    letter-spacing: -0.8px;
    text-transform: uppercase;
    width: 500px;

    @media (max-width: 1024px) {
        @media (max-width: 1024px) {
            font-size: 35px;
            /* Increased font size for tablets */
            max-width: 100%;
            width: 100%;

        }
    }

    @media (max-width: 768px) {
        font-size: 28px;
        /* Increased font size for mobile */
    }
}

.SaferIngredientsHeaderSVG {
    height: 33px;

    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    @media (max-width: 1024px) {
        width: 350px;
        height: 100svh;
    }

    @media (max-width: 768px) {
        width: 280px;
        height: auto;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    }
}

.SaferIngredientsDescription {
    color: #FFF;
    font-family: "NB Grotesk Pro Mono";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
    /* 28.6px */
    letter-spacing: -0.26px;
    text-transform: uppercase;

    @media (max-width: 1024px) {
        padding-bottom: 30px;
        
    }


    @media (max-width: 768px) {
        width: 269px;
        text-align: start;
        transform: translateX(2%);
        font-size: 12px;
        padding-bottom: 50px;


    }
}

.SaferIngredientsButton {
    background-color: #000;
    color: #fff;
    border-radius: 200px;
    height: 52px;
    width: 210px;
    font-size: 12px;
    text-transform: uppercase;
    font-family: 'ABC Diatype';
    border: none;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;

    @media (max-width: 768px) {
        font-size: 12px;
        width: 150px;
        height: 40px;
        margin-bottom: 10px;
        display: initial;
    }
}

.dotReadMoreWrapper {
    display: flex;
    align-items: center;
    padding-bottom: 16px;
}

.readMore {
    color: #FFF;
    font-family: "ABC Diatype";
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 122%;
    /* 14.64px */
    text-transform: uppercase;
    padding-left: 8.99px;
}


.SaferIngredientsContainerIMG {
    height: 96dvh;
    width: 695px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 20px;
    margin-right: 20px;
    overflow: hidden;
    display: block;
    object-fit: cover;

    @media (max-width: 1024px) {
        height: 100%;
        border-radius: 20px;
        border: none;
    }

    @media (max-width: 768px) {
        width: 100%;
        height: 680px;
        border-radius: 20px;
        border: none;
        margin-right: 0px;

    }
}