@font-face {
  font-family: 'NB Grotesk Pro';
  src: url('../../../../assets/fonts/NBGroteskProMono-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

.guide-section {
  padding: 0.5rem 1.5rem;
  background-color: #f7f6e6;
  height: 660px;


  .guide-item{
    width: 100%;
  }

  .h2 {
    margin-bottom: 1rem;
    color: black;
    font-family: 'SF Pro Display', sans-serif;
    font-size: 20px;
    font-weight: 600;
    line-height: 22.09px;
    text-align: center;
  }

  .guide-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;
  }

  .guide-menu {
    display: flex;
    flex-direction: column;
    align-items: end;
    margin-top: 20rem;
    margin-bottom: 5rem;
    width: 100%;
    transform: translateX(100px);
    z-index: 1000px;


    ol {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        width: 100%;

        &:hover a,
        &:hover .menu-description {
          color: black;
        }

        a {
          font-family: 'ABC Diatype', sans-serif;
          font-size: 48.27px;
          font-weight: 700;
          line-height: 53.1px;
          letter-spacing: -0.01em;
          text-align: center;
          text-decoration: none;
          color: rgba(0, 0, 0, 0.18);
          padding: 10px 20px;
          border-radius: 5px;
          // transition: background-color 0.3s ease, color 0.3s ease;
          color: black;

          .menu-number {
            color: inherit;
            margin-right: 0.5rem;
            font-size: 12px;
            position: relative;
            bottom: 25px;
            font-weight: bold;
            color: #000;
          }
        }

        .menu-description {
          font-family: 'NB Grotesk Pro', sans-serif;
          font-weight: 400;
          font-size: 15.95px;
          line-height: 15.95px;
          letter-spacing: -0.02em;
          text-align: center;
          color: rgba(0, 0, 0, 0.18);
          margin-left: 0.5rem;
          color: black;
          // transition: color 0.3s ease;
        }
      }
    }
  }

  .guide-image {
    margin-left: 1.5rem;
    margin-bottom: -7rem;
    max-width: 100%;
    height: auto;
    border-radius: 7px;
  }
}

@media screen and (max-width: 768px) {
  .combination-container {
    display: flex;
    .menu-number-combination {
      font-family: 'NB Grotesk Pro', sans-serif;
      color: inherit;
      margin-right: 0.5rem;
      font-size: 12px;
      position: relative;
      font-weight: bold;
      bottom: 15px;
    }
    .combination-text {
      display: flex;
      flex-direction: column;
      line-height: 30px;
    }
  }

  .guide-section .guide-container {
    flex-direction: column;
  }
  .guide-section .guide-menu {
    margin-left: 0;
    margin-bottom: 1rem;
    width: 100%;
    display: block;
    transform: translateX(0);
  }

  .guide-section .guide-menu ol li a {
    font-size: 30px;
    color: #000000;
    padding: 0;
    .menu-number {
      bottom: 12px;
    }
  }

  .guide-section .guide-menu ol li .menu-description {
    color: #000000;
    font-size: 12px;
  }

  .guide-section .guide-image {
    display: none;
  }
}
