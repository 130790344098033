.emptyOrdersContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    text-align: center;
    position: relative;
    max-width: 800px;
}

.contentContainer {
    position: relative;
}

.imageContainer {
    position: relative;
    width: 288.704px;
    height: 269.507px;
    overflow: hidden;
}

.eggShapeImage {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.textContainer {
    position: absolute;
    top: 62%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    text-align: center;
    width: 100%;
    padding: 0 20px;
}

.title {
    color: #000;
    text-align: center;
    font-family: "ABC Diatype";
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 109%;
    text-transform: uppercase;
}

.subTitle {
    color: var(--Charcoal, #000);
    text-align: center;
    font-family: "ABC Diatype";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: -0.14px;
    opacity: 0.57;
    margin-bottom: 42px;
}

.favoritesBtn {
    color: var(--Charcoal, #000);
    text-align: center;
    font-family: "ABC Diatype";
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-transform: uppercase;
}

.btn {
    display: inline-flex;
    padding: 10px 76px;
    align-items: center;
    gap: 10px;
    border: none;
    border-radius: 41px;
    background: var(--Charcoal, #000);
    text-transform: uppercase;
    font-size: 10px;
    color: var(--CTA-White, #F7F6E6);
    text-align: center;
    font-family: "ABC Diatype";
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-transform: uppercase;
    margin-bottom: 20px;
    cursor: pointer;
}