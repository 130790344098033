.desktopContainer {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-image: url('../../../../../assets/images/headSectionBackground.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.topSection {
    display: flex;
    height: 33vh;
    width: 100%;
}

.menuTopSection {
    width: 33%;
    background-color: transparent;
}

.contentTopSection {
    flex-grow: 1;
    padding: 2% 15% 3% 2%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.userName {
    color: #FFF;
    font-family: "ABC Diatype";
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    letter-spacing: -0.52px;
    text-transform: uppercase;
    margin: 0;
}

.bottomSection {
    display: flex;
    flex-grow: 1;
    width: 100%;
    background-color: #F7F6E6;
    overflow: hidden;
    border-radius: 14px 14px 0 0;
}

.menuSection {
    width: 33%;
    padding: 3% 5% 5% 10%;
}

.contentSection {
    flex-grow: 1;
    padding: 2% 15% 2% 2%;
}

.OrderPageContent {
    margin-left: 490px;
    margin-top: 500px;
    position: relative;
}

.OrderPageHeadSection {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
    max-width: 800px;
    position: relative;
}

.rebuyBtn {
    display: inline-flex;
    padding: 10px 74px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 41px;
    background: var(--Charcoal, #000);
    border: none;
    color: #F7F6E6;
    text-transform: uppercase;
    margin-left: auto;
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
}

.backBtn {
    color: var(--Charcoal, #000);
    font-family: "ABC Diatype";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-transform: uppercase;
    background: none;
    border: none;
    margin-bottom: 50px;
    cursor: pointer;
}

.OrderPageTitle {
    color: #000;
    font-family: "ABC Diatype";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
    letter-spacing: -0.14px;
    text-transform: uppercase;
}

.mobileContainer {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: #F7F6E6;
    padding: 10% 14px;
}

.mobileHeaderSection {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
    margin-bottom: 13px;
}

.mobileTitle {
    color: var(--Charcoal, #000);
    font-family: "ABC Diatype";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 104%;
    text-transform: uppercase;
}

.mobileSubTitle {
    color: var(--Charcoal, #000);
    font-family: "ABC Diatype";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 109%;
    text-transform: uppercase;
}

.mobileSubTitleContainer {
    padding: 10px 0;
}

.rebuyAllMobileContainer {
    position: relative;
    padding: 0 32px;
}

.mobileRebuyAllBtn {
    margin: 20px 0;
    border-radius: 41px;
    background: var(--Charcoal, #000);
    color: #F7F6E6;
    padding: 10px 76px;
    border: none;
    cursor: pointer;
    text-transform: uppercase;
    width: 100%;
    position: sticky;
    bottom: 20px;
    z-index: 100;
}

@media (max-width: 768px) {}