.headerBlockHome {
  height: 100dvh;
  width: 100%;
  overflow-y: hidden;

  flex-shrink: 0;
}

.slideContent {
  background-Size: cover;
  background-Position: center;
  background-Repeat: no-repeat;
  width: 100%;
  height: 100svh;
  overflow-y: hidden;

}

.navigationButtonsContainer {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  position: relative;
  gap: 0.5rem;
  z-index: 101;
  transform: translateY(-2rem);
  margin-right: 80px;
}
.dynamicButton{
  color: var(--WHITE, #F7F6E6);
  font-family: "NB Grotesk Pro Mono";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  background-color: transparent;
  border: none;
  cursor: pointer;
  height: 0;
}

.blurred {
    filter: blur(1px);
  }

  @media (max-width: 440px) {
    .navigationButtonsContainer{
      margin-right: 20px;
      transform: translateY(-1.2rem);

    }
  }
