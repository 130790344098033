/* ProfileMenu.module.scss */
.menu {
    z-index: 1;

    ul {
        list-style-type: none;
        padding: 0;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    li {
        display: flex;
        align-items: center;
        padding-left: 20px;

        a,
        span {
            text-decoration: none;
            color: rgba(0, 0, 0, 0.6);
            font-family: 'ABC Diatype', sans-serif;
            font-size: 12px;
            font-weight: 700;
            line-height: 13.2px;
            letter-spacing: -0.01em;
            text-align: left;
            transition: color 0.3s, opacity 0.3s;
        }

        &.selected {
            background-image: url('../../../assets/images/blackCounter.png');
            background-repeat: no-repeat;
            background-position: left center;
            background-size: 7.63px 7.05px;

            a {
                color: black;
                opacity: 1;
            }
        }
    }
}