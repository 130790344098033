.brandDetailsSection {
  margin-top: 50px;
  position: relative;
  display: flex;
  z-index: 20;
  align-items: center;
  width: 100%; // Use 100% to fill the parent's width
  gap: 20px;
}

.aboutSection {
  flex: 0 0 75%; // fixed width when in a row
  display: flex;
  flex-direction: column;
  justify-content: center; // vertically centers the content
}

.scoreSection {
  flex: 0 0 25%; // fixed width when in a row
  display: flex;
  align-items: center;  // vertically centers the content within this block
  justify-content: flex-end; // aligns the score to the right
}

.aboutTitle {
  color: var(--Charcoal, #000);
  align-self: stretch;
  font-family: "ABC Diatype";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 104%; /* 20.8px */
  text-transform: uppercase;
  padding-left: 20px;
  padding-bottom: 20px;
}

.aboutText {
  color: #000;
  font-family: "NB Grotesk Pro Mono";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 28.6px; /* 119.167% */
  align-self: stretch;
  padding-left: 20px;
  max-width: 1100px;
  min-width: 900px;
}

.scoreAlignment {
  display: flex;
  width: 300px;
  flex-direction: column;
  align-items: center;
  padding-right: 50px;
}

.brandAvg {
  color: #000;
  text-align: center;
  font-family: "NB Grotesk Pro Mono";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 28.6px; /* 119.167% */
}

.digitImage {
  width: 20px;
  height: 31.86px;
  flex-shrink: 0;
}

.titleAndDot {
  display: flex; /* Enable flexbox for alignment */
  align-items: center; /* Align items vertically in the center */
  gap: 8px; /* Add space between the dot and the title */
}

@media (max-width: 440px) {
  .brandDetailsSection {
    margin-top: 50px;
    display: flex;
    align-items: center;
    width: 100%; // fills the parent's width
    gap: 20px;
    // Stack vertically on mobile:
    flex-direction: column;
  }
  
  .aboutSection {
    flex: 0 0 auto; // remove the fixed width when stacked vertically
    display: flex;
    flex-direction: column;
    justify-content: center; // vertically centers the content
    width: 100%; // ensure it fills the parent's width
    order: 1; // about section comes first
  }
  
  .scoreSection {
    padding-top:30px ;
    padding-left: 0px;
    flex: 0 0 auto; // adjust for vertical stacking
    display: flex;
    align-items: center; // vertically centers the content
    justify-content: center; // center horizontally or adjust as needed
    width: 100vw; // fill available space if needed
    order: 2; // score section comes after the about section
  }
  
  /* Adjust the aboutText for mobile to prevent overflow */
  .aboutText {
    padding-right: 10px; // Add right padding (if desired) to prevent content touching the edges
    max-width: 100%;     // Let it fill the container
    min-width: auto;     // Remove the fixed minimum width so it can shrink as necessary
    font-size: 20px;     // Optionally reduce the font size for better fit
    line-height: normal; // Adjust line-height if needed
    line-height: 30px;
  }
  .scoreAlignment{
    align-self: center;
    padding-right: 0px;
    width: 100%;
  }
}
