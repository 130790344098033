.orderContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-top: 1px solid rgba(0, 0, 0, 0.07);
    max-width: 800px;
    padding-bottom: 4.245px;
}

.orderInfo {
    margin-top: 16px;
    margin-bottom: 20px;
    display: flex;
    width: 100%;
    color: #7E7063;
    font-family: "ABC Diatype";
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
    letter-spacing: -0.12px;
    text-transform: uppercase;
    align-items: flex-start;
    justify-content: flex-start;
}

.infoItem {
    display: flex;
    align-items: center;
    width: 100%;
}

.label {
    font-weight: bold;
    color: #7E7063;
}

.infoItem>.label+span {
    margin-left: auto;
}

.viewBtn {
    color: var(--Charcoal, #000);
    text-align: right;
    font-family: "ABC Diatype";
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
    letter-spacing: -0.12px;
    text-transform: uppercase;
    background: none;
    border: none;
    margin-left: auto;
    cursor: pointer;
}

.productImages {
    display: flex;
    justify-content: flex-start;
    gap: 5.41px;
    flex-wrap: wrap;
    width: 100%;
}

.productImage {
    width: 76.329px;
    height: 92.122px;
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 3.608px;
    background: #EAE7D8;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

@media (max-width: 768px) {
    .orderContainer {
        padding-bottom: 0;
        padding-top: 12px;
    }

    .orderInfo {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: nowrap;
    }

    .infoLeft {
        display: flex;
        gap: 5px;
        justify-content: flex-start;
        align-items: center;
        flex-shrink: 0;
    }

    .infoRight {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        text-align: right;
        flex-grow: 1;
    }

    .infoRight>span {
        margin-bottom: 8px;
    }

    .priceAndButton {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    .viewBtn {
        margin-top: 8px;
        background: none;
        border: none;
        color: #000;
        font-family: "ABC Diatype";
        font-size: 12px;
        font-weight: 700;
        text-transform: uppercase;
        cursor: pointer;
    }
}