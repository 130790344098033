@import '../../../index.scss';

.AdviceSectionContainer {
    padding: 45px 15px;
    background-color: $color-default-background;
    height: 800px;

    > div {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    div:last-child {
        flex-direction: row;
        align-items: flex-end;
    }
}

.adviceHeader {
    @include NBGroteskProMono-Regular(26);
    max-width: 690px;
}

.createPerfRoutineText {
    @include ABCDiatype-Bold(12);
    text-transform: uppercase;
    padding-right: 10px;
}

.AdviceSectionContainerDescription {
    @include ABCDiatype-Bold(12);
    text-transform: uppercase;
    max-width: 460px;
}

.AdviceSectionContainerImage {
    height: 143px;
    background-size: contain;
    width: 134px;
    margin-right: 326px;
    background-repeat: no-repeat;
    margin-bottom: 177px;
}

.lowerContainer {
    padding-top: 550px;
}
@media (max-width: 968px) {
    .AdviceSectionContainer {
        display: flex;
        width: 390px;
        padding-bottom: 50px;
        flex-direction: column;
        align-items: flex-start;
        gap: 85px;
        height: 100svh;

        > div {
            flex-direction: column;
            align-items: center;
            flex-wrap: wrap-reverse;
        }

        div:last-child {
            flex-direction: column;
            align-items: center;
        }
    }

    .adviceHeader {
        font-size: 20px;
        max-width: 100%;
        text-align: left;
        margin-top: 40px;
        padding-right: 20px;
    }

    .createPerfRoutineText {
        font-size: 10px;
    }

    .AdviceSectionContainerDescription {
        font-size: 10px;
        max-width: 100%;
        margin-bottom: 20px;
        text-align: left;
    }

    .AdviceSectionContainerImage {
        display: none;
    }

    .lowerContainer {
        padding-top: 240px;
    }
}
