/* HTML: <div class="loader"></div> */
.loader {
  display: inline-flex;
  gap: 5px;
  animation: l3-0 1s infinite;
  transform-origin: 50% calc(100% + 2.5px);
}
.loader:before,
.loader:after {
  content: "";
  width: 25px;
  aspect-ratio: 1;
  box-shadow: 0 0 0 3px inset #090909;
}
.loader:after {
  transform-origin: -2.5px calc(100% + 2.5px);
  animation: l3-1 1s infinite;
}
@keyframes l3-1 {
  50%,
  100% {transform:rotate(180deg)}
}
@keyframes l3-0 {
  0%,
  50%  {transform:rotate(0deg)}
  100% {transform:rotate(90deg)}
}