.mostSearchedActivesPage{
    width: 100%;
    height: 778px;
    position: relative;
    flex-shrink: 0;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    @media (max-width: 768px) {
        width: 100%;
        height: 100svh;  
      }

}
.mostSearchedTitleContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: 100%;
    display: flex;
    width: 366px;
    height: 18.561px;
    align-items: flex-end;
    gap: 7.54px;  
    padding-top: 18px;
    margin-left: 1%;
    @media (max-width: 768px) {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 5px;
        margin-left: 4%; 
        position: absolute;
        bottom: 0;
        left: 0;
        transform: translateY(80%);
    }
}
.mostSearchedActive{
    display: flex;
    width: 313.217px;
    height: 18.561px;
    align-items: flex-end;
    gap: 7.54px;
}
.mostSearchedActiveName{
    color: var(--WHITE, #F7F6E6);
text-align: center;

font-family: "ABC Diatype";
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: 110%; /* 15.4px */
letter-spacing: -0.14px;
text-transform: uppercase;
opacity: 0.5;
cursor: pointer;
}
.isSelected{
    color: var(--WHITE, #F7F6E6);
    text-align: center;
    font-family: "ABC Diatype";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%; /* 15.4px */
    letter-spacing: -0.14px;
    text-transform: uppercase;
    cursor: pointer;
    opacity: 1;
}

.activeImageContainer {
 position: absolute;
 left: 50%;
 top: 50%;
 transform: translate(-50%, -50%);
}

.mostSearchedActiveContainer {
    width: 400px;
    height: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border-radius: 4px;
    background: var(--WHITE, #F7F6E6);
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional for a subtle shadow */
    padding: 20px; /* Adjust padding as needed */
    cursor: url('../../../assets/cursors/mouse_read.svg'), pointer;
    @media (max-width: 768px) {
        width: 295px;
        height: 380px;
        border-radius: 4px;
background: var(--WHITE, #F7F6E6);
flex-shrink: 0;
    }
}

.titleContainer {
    width: 100%;
    @media (max-width: 768px) {
        position: relative; /* Make it position relative to the parent container */
        transform: translateY(-10px);
        margin-right: 12px;
    }
}
.title{
color: #000;
font-family: "ABC Diatype";
font-size: 8px;
font-style: normal;
font-weight: 400;
line-height: 122%; /* 9.76px */
text-transform: uppercase;
}

.imageContainer {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
}
.activeImage{
    width: 360px;
    height: 396px;
    flex-shrink: 0;
    margin-bottom: 25px;
    @media (max-width: 768px) {
        width: 275px;
    height: 303px;
    flex-shrink: 0;
    }
}
.activeTextContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    width: 100%;
    height: 10%;
    @media (max-width: 768px) {
        height: 5%;
}
}
.activeName{
    color: #000;
    text-align: start;
    font-family: "ABC Diatype";
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%; /* 33px */
    letter-spacing: -0.3px;
    text-transform: uppercase;
    padding-top: 0;
    @media (max-width: 768px) {
        font-size: 16px;
    }
}
.numberContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.572px;
    flex-shrink: 0;
}
.numberImage{
    width: 4.571px;
    height: 7px;
    margin-bottom: 7px;
}
.indexContainer{
position: absolute;
top: 20px;
left: 50%;
transform: translateX(-50%);
@media (max-width: 768px) {
    left: 95%;
    top: 10px;
}

}
.index{
    color: #000;
font-family: "ABC Diatype";
font-size: 8px;
font-style: normal;
font-weight: 400;
line-height: 122%; /* 9.76px */
text-transform: uppercase;
}