@import '../../../index.scss';

.PopularCategoriesContainer {
  background-color: $color-default-background;
  padding: 0;
  margin: 0;

  .PopularCategoriesHeader {
    display: flex;
    justify-content: space-between;
    padding: 10px 10px;
  }

  .PopularCategoriesHeaderText {
    @include ABCDiatype-Bold(14);
    text-transform: uppercase;
    padding-left: 10px;
  }

  .PopularCategoriesCardsContainer {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    .PopularCategoriesCard {
      height: 700px;
      width: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      flex-shrink: 0;
      cursor: pointer;
      position: relative;
    }

    /* Mobile-specific styles */
    @media (max-width: 768px) {
      .PopularCategoriesCard {
        flex: 0 0 auto;
        scroll-snap-align: center;
        width: 100%;
        height: 450px;
      }

      &::-webkit-scrollbar {
        display: none;
      }

      scrollbar-width: none;
    }
  }

  .PopularCategoriesCardContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    max-width: 400px;
    min-height: 400px;
    border-radius: 10px;
    //height: 100%;
  }
  .exporOur{
    color: var(--Off-White, #F7F6E6);
text-align: center;

font-family: "NB Grotesk Pro Mono";
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 100%;
padding-bottom: 10px;
  }
  .PopularCategoriesCardText {
    color: var(--Off-White, #f7f6e6);
    text-align: center;
    font-family: 'ABC Diatype';
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    letter-spacing: -0.32px;
    text-transform: uppercase;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
    margin-bottom: 20px;
  }

  .PopularCategoriesCardDescription {
    padding-top: 20px;
    color: #fff;
    text-align: center;
    width: 100%;
    max-width: 280px;
    margin: 0 auto;
    padding: 0 15px;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
    font-family: 'NB Grotesk Pro Mono';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
  }

  .PopularCategoriesCardButton {
    margin-top: 30px;
    display: flex;
    padding: 8px 34px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 26px;
    background: var(--Off-White, #f7f6e6);
    color: var(--Charcoal, #000);
    font-family: 'ABC Diatype';
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    border: none;
    cursor: pointer;
  }

  .PopularCategoriesCardTitle {
    background-size: auto;
    background-position: center;
    width: 100%;
    height: 40px;
    background-repeat: no-repeat;
    margin-top: 10px;

    @media (max-width: 768px) {
      width: 60%;
      background-size: contain; // Apply 'contain' only on mobile devices
    }
  }

  @media (max-width: 768px) {
    .PopularCategoriesCardContent {
      width: 90%;
      min-height: 180px;
      padding: 20px;
    }

    .PopularCategoriesCardText {
      font-size: 28px;
    }
  }
}
