.popupLayout {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    transition: visibility 0s, opacity 0.5s ease-in-out;
}

.popupContainer {
    width: 429px;
}

.xButtonContainer {
    display: flex;
    justify-content: flex-end;

    img {
        cursor: pointer;
    }
}

.contentContainer {
    display: flex;
    width: 412px;
    padding: 10px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    border-radius: 10px;
    background: var(--Backgrounds-CLAY, #7F6F64);
}

.infoContainer {
    display: flex;
    padding: 16px 10px;
    flex-direction: column;
    align-items: flex-start;
    gap: 26px;
    align-self: stretch;
    border-radius: 10px;
    background: var(--Text-OFF-WHITE, #F7F6E6);
}

.infoTitleContainer {}

.infoTitleText {
    color: #000;
    font-family: "ABC Diatype";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
    letter-spacing: -0.2px;
    text-transform: uppercase;
}

.infoDataContainer {}

.infoSubTitleText {
    color: #000;
    font-family: "ABC Diatype";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
    letter-spacing: -0.14px;
    text-transform: uppercase;
}

.infoText {
    color: #000;
    font-family: "NB Grotesk Pro Mono";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
    letter-spacing: -0.12px;
}

.bottomLabelContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.bottomLabelText {
    color: var(--Text-OFF-WHITE, #F7F6E6);
    font-family: "NB Grotesk Pro Mono";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
    letter-spacing: -0.12px;
}

@media (max-width: 1360px) {
    .popupContainer {
        width: 345px;
    }

    .contentContainer {
        width: 328px;
    }

    .infoTitleText {
        font-size: 16px;
        letter-spacing: -0.16px;
    }

    .infoSubTitleText {
        font-size: 12px;
        letter-spacing: -0.12px;
    }

    .infoText {
        font-size: 10px;
        letter-spacing: -0.1px;
    }

    .bottomLabelText {
        font-size: 10px;
        letter-spacing: -0.1px;
    }

    .infoContainer {
        gap: 18px;
    }
}