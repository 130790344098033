.tableWrapper {
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
}

.tableHeader {
  display: flex;
  height: 1.75rem;
  padding: 0rem 0.625rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  flex-shrink: 0;
  border-radius: 0.5rem 0.5rem 0rem 0rem;
  background: var(--Backgrounds-BEIGE, #ccb9a7);
  align-self: stretch;
}

.headerRow {
  display: flex;
  align-items: center;
  gap: 2.5rem;
  flex: 1 0 0;
}

.headerCell {
  color: var(--Text-CHARCOAL, #000);

  font-family: 'ABC Diatype';
  font-size: 0.89388rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 1.07263rem */
  letter-spacing: -0.01788rem;
  text-transform: uppercase;
}

.tableBody {
  width: 100%;
  color: #000;
  font-family: 'NB Grotesk Pro Mono';
  font-size: 0.89388rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.75rem; /* 195.78% */
  letter-spacing: -0.00894rem;
}

.tableRow {
  display: flex;
  min-height: 1.75rem;
  padding: 0 0.625rem;
  color: var(--Text-CHARCOAL, #000);
  align-items: center;
  gap: 0.62rem;
  align-self: stretch;
}

.oddRow {
  background: var(--Backgrounds-BEIGE, rgb(204, 185, 167, 0.3));
}

.evenRow {
  background: var(--Backgrounds-BEIGE, rgb(204, 185, 167, 0.2));
}

.ingredientName {
  color: #000;
  font-family: 'NB Grotesk Pro Mono';
  font-size: 0.89388rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.75rem; /* 195.78% */
  letter-spacing: -0.00894rem;
  text-decoration-line: underline;
  text-decoration-style: solid;
  width: 162.5px;
  text-decoration-skip-ink: auto;
  text-decoration-thickness: auto;
  text-underline-offset: auto;
  text-underline-position: from-font;
  cursor: pointer;
  text-transform: capitalize;
}

.ingredientNameNotInInciFormat {
  cursor: default;
  text-decoration: none;
}

.functions {
  color: #000;
  letter-spacing: -0.00894rem;
  width: 162.5px;

  text-transform: capitalize;
}

@media (min-width: 1001px) {
  .tableHeader {
    justify-content: flex-start;
  }
  .headerRow {
    display: flex;
    max-width: 42.14025rem;
    align-items: center;
    gap: 20rem;
  }
  .tableRow {
    display: flex;
    align-items: center;
    gap: 9.6875rem;
    align-self: stretch;
  }
  .ingredientName {
    width: 18.42369rem;
  }
  .functions {
    width: 42.24756rem;
  }
}
