@import '../../../../index.scss';

@keyframes slideIn {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fadeInMobile {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.menuRightSection {
  width: 43%;
  height: 98svh;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  background: $color-default-background;
  padding: 15px 20px;
  transition: visibility 0s, opacity 0.5s ease-in-out;
  min-height: 730px;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-width: 600px;
  justify-content: space-between;
  align-items: left;
  animation: slideIn 0.2s ease-in-out;
  margin-left: 1px;

  @media (max-width: 768px) {
    height: 100svh;
  }
}

.expendContainer {
  display: flex;
  flex-direction: column;
  align-items: left;
  overflow-y: hidden;
  max-height: 100%;
  overflow-x: hidden;
  border-radius: 12px;
  min-height: 700px;
}

.menuHeaderContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // margin-top: 8px;
  padding: 8px;
  
}

.menuHeaderResultsContainer {
  width: 100%;
}

.menuHeaderCloseContainer {
  @include ABCDiatype-Bold(12);
  text-transform: uppercase;
  cursor: pointer;
  margin-right: 1%;
  
}

.menuContainerSuggestions {
  margin-top: 5%;
  line-height: 1.4;
}

.menuContainerSuggestionsTitle {
  padding-bottom: 1%;
  @include ABCDiatype-Bold(14);
  color: #000;
  text-transform: uppercase;
}

.menuHeaderResultsText {
  color: #000;

  font-family: 'ABC Diatype';
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}

.menuCloseButton {
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #000;
  text-align: right;
  font-family: 'ABC Diatype';
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  gap : 10px
}

.menuCloseButtonDot{
  width: 15px;
}

.menuHeaderResultsText {
  //position: absolute;
  //top: 2%;
  margin-left: 2%;

  //left: 9%;
  @media (max-width: 768px) {
    left: -14%;
  }
}

.optionsContainer {
  margin-top: 18%;
  display: inline-flex;
  gap: 10px;
  flex-shrink: 0;
}

.activeOption {
  display: flex;
  padding: 8px 10px;
  justify-content: center;
  align-items: center;
  gap: 20px;
  border-radius: 4px;
  background: var(--OLI-Beige, #ccb9a7);
  backdrop-filter: blur(8.949999809265137px);
  border: none;
  //width: 150px;
}

.optionIcon {
  width: 6.411px;
  height: 5.985px;
  margin-right: 1px;
}

.nonActiveOption {
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin-left: 15px;
  color: var(--Charcoal, #000);
  font-family: 'ABC Diatype';
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%;
  text-transform: uppercase;
  opacity: 0.5;

  // disabled style
  &:disabled {
    cursor: not-allowed;
    color: gray;
  }
}

.optionIcon {
  width: 10px;
  height: 10px;
}

.activeGrid {
  height: 220px;
  width: 170px;
  background-size: cover;
  background-position: center;
  border-radius: 5px;
}

.activeImage {
  width: 100%;
  height: 100%;
  background-size: cover;
  /* Ensure the image covers the area */
  background-position: center;
  /* Center the image */
  border-radius: 5px;
  /* Optional: add rounded corners */
  position: relative;
}

.activeName {
  //border: 10px solid red;
  position: absolute;
  bottom: 2%;
  left: 2%;
  //right: 0;
  text-align: left;
  font-size: 14px;
  font-weight: 700;
  line-height: 110%;
  letter-spacing: -0.14px;
  text-transform: uppercase;
  color: rgb(247, 246, 230, 0.7);
  //padding: 20px;
}

.articlesList {
  //border: 10px solid red;
  //display: grid;
  //grid-template-columns: repeat(1, 1fr);
  //grid-gap: 10px
  width: 550px;
  // max-width: 95%;
  height: 100%;
  //height: 100%;
  // display: flex;
  //  flex-direction: column;
}

.articleItem {
  //border: 1px solid red;
  //position: relative;
  //display: flex;
  //flex-direction: column;
  //align-items: center;
  //justify-content: center;
  //height: auto; /* Allow height to adjust naturally */
  //border: 10px solid red;
  height: 100%;
  margin-bottom: 20px;
  /* Add spacing between articles */
  //box-sizing: border-box;
}

.articleImage {
  margin-top: 10px;
  height: 260px;
  background-size: cover;
  background-position: center;
  border-radius: 5px;
  position: relative;
}

.articleTitleWrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.7), transparent);
}

.articleTitleContainer {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}

.articleTitle {
  color: var(--OLI-Off-White, #f7f6e6);
  font-family: 'ABC Diatype';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%;
  letter-spacing: -0.14px;
  text-transform: uppercase;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
}

.articleDossierContainer {
  display: inline-flex;
  padding: 2.338px 6px;
  justify-content: center;
  align-items: center;
  gap: 7.795px;
  border-radius: 21.046px;
}

.articleDossier {
  color: var(--Charcoal, #000);
  font-family: 'ABC Diatype';
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  border: 0.39px solid var(--Off-White, #f7f6e6);
  background: var(--Off-White, #f7f6e6);
  padding: 2px 5px;
  border-radius: 21.046px;
}

.seeMoreContainerDiv {
  //border: 10px solid red;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.SeeMoreContainer {
  //position: absolute;
  //bottom: 14px;
  //left: 50%;
  //transform: translateX(-50%);
  // border: 1px solid red;
  width: 80%;
  padding: 10px;
  border-radius: 41px;
  background: var(--Charcoal, #000);
  display: flex;
}

.SeeMoreButton {
  color: var(--CTA-White, #f7f6e6);
  text-align: center;
  font-family: 'ABC Diatype';
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 200% */
  text-transform: uppercase;
  width: 100%;
}

.menuProductContainer,
.menuActivesContainer,
.menuArticlesContainer {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 4%;
  box-sizing: border-box;
  height: calc(100% - 150px);
  //height: 920px;
  overflow-y: auto;
  padding: 0;
  width: 100%;
  padding: 2%;
  scrollbar-width: thin;
  scrollbar-color: #ccb9a7 transparent;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ccb9a7;
    border-radius: 20px;
  }
}

.menuArticlesContainer {
  //border: 10px solid red;
  //width: 100%;

  align-items: center;
  justify-content: center;
  //max-height: 80%;
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  grid-gap: 10px;
  overflow: hidden;
}

.scroll {
  //border: 10px solid red;
  overflow-y: auto;
}

.productList,
.activesList {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  width: 100%;
}

.activeItem {
  text-align: center;
  border-radius: 8px;
  background-color: #f0f1e1;
  height: 220px;
  overflow: hidden;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  //border: 10px solid red;
}

.activeItem {
  height: fit-content;
  //width: fit-content;
}

.productItem {
  text-align: center;
  border-radius: 8px;
  background-color: #f0f1e1;
  height: 240px;
  overflow: hidden;
  width: 100%;

  @media (max-width: 768px) {
    min-width: 168px;
    min-height: 197px;
  }
}

.productImageContainer {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.productImage {
  height: 180px;
  width: 140px;
  object-fit: contain;

  @media (max-width: 768px) {
    overflow-y: auto;
    max-width: 390px;
  }
}

.productInfo {
  position: relative;
  width: 100%;
  padding: 10px;
  padding-left: 14px;
  padding-right: 14px;
}

.productPrice {
  text-align: left;
  margin-bottom: 2px;

  color: var(--Black, #000);
  font-family: NB Grotesk Pro Mono;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.26px;
  line-height: 100%;
  padding-bottom: 5px;
  padding-top: 2px;
}

.productBrand {
  text-align: left;
  margin-bottom: 2px;
  color: var(--Charcoal, #000);
  font-family: 'ABC Diatype';
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%;
  /* 8.8px */
  letter-spacing: -0.12px;
  text-transform: uppercase;
  @include ABCDiatype-Bold(12);
  text-decoration: none;
}

.productTitle {
  text-align: left;
  color: var(--OLI-PRIMARY-BLACK, #000);
  font-family: 'NB Grotesk Pro Mono';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  /* 9px */
  letter-spacing: -0.26px;
  text-transform: capitalize;
}

.productLink {
  text-decoration: none;
  color: inherit;
}

.menuLoaderContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: var(--Off-White, #f7f6e6);
  border: none;

  @media (max-width: 768px) {
    top: 40%;
    left: 70%;
    transform: translate(-50%, -50%);
  }
}

.seeAllProducts {
  position: absolute;
  bottom: 3%;
  right: 50%;
  transform: translateX(50%);
  display: flex;
  width: 434px;
  padding: 10px 76px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 41px;
  background: var(--Charcoal, #000);
}

.seeAllProductsButton {
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: var(--CTA-White, #f7f6e6);
  text-align: center;
  font-family: 'ABC Diatype';
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 200% */
  text-transform: uppercase;
}

@media (max-width: 1260px) {

  .productList {
    height: 550px;
  }

  .seeMoreContainerDiv {
    position: absolute;
    bottom: 0px;
  }
}

@media (max-width: 768px) {
  .menuRightSection {
    width: 100%;
    height: 100%;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    background: $color-default-background;
    padding: 0px;
    transition: visibility 0s, opacity 0.5s ease-in-out;
    border-left: none;
    min-height: 0;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    min-width: 100%;
    justify-content: space-between;
    align-items: left;
    animation: fadeInMobile 0.3s ease-in-out;
  }

  .expendContainer {
    display: flex;
    flex-direction: column;
    //justify-content: center;
    align-items: left;
    overflow-y: hidden;
    // min-height: 720px;
    max-height: 100%;
    overflow-x: hidden;
    border-radius: 12px;
    min-height: 0;

    width: 100%;
    height: 100%;
    //border: 1px solid red;
    //position:relative
  }

  .optionsContainer {
    margin-top: 2%;
    display: inline-flex;
    gap: 10px;
    flex-shrink: 0;
    margin-bottom: 16px;
  }

  .menuHeaderResultsContainer {
    width: 100%;
    padding-top: 2%;
    //border: 1px solid red;
  }

  .menuHeaderResultsText {
    margin-left: 0;
  }

  .menuContainerCloseText {
    visibility: hidden;
  }

  .menuArticlesContainer,
  .menuProductContainer,
  .menuActivesContainer {
    //border: 1px solid red;
    //justify-content: center;
    flex-direction: column;
    margin-top: 0;
    box-sizing: border-box;
    height: 310px;
    overflow-y: auto;
    width: 100%;
    padding: 0;
    scrollbar-width: thin;
    scrollbar-color: #ccb9a7 transparent;

    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 0px;
    overflow: hidden;
    overflow-y: visible;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #ccb9a7;
      border-radius: 20px;
    }
  }

  .articlesList,
  .productList,
  .activesList {
    //border: 2px solid red;
    //display: grid;
    //grid-template-columns: repeat(1, 1fr);
    //grid-gap: 10px
    width: 100%;
    // max-width: 95%;
    height: 100%;
    //height: 100%;
    // display: flex;
    //  flex-direction: column;
  }

  .articleItem,
  .activeItem {
    //border: 1px solid red;
    //position: relative;
    display: flex;

    //flex-direction: column;
    align-items: center;
    justify-content: center;
    //height: auto; /* Allow height to adjust naturally */
    //border: 10px solid red;
    height: 100%;
    margin-bottom: 5px;
    /* Add spacing between articles */
    //box-sizing: border-box;
  }

  .activeItem {
    height: fit-content;
    //width: fit-content;
  }

  .articleImage,
  .productImage,
  .activeImage {
    margin-top: 2px;
    height: 178px;
    width: 320px;
    background-size: cover;
    background-position: center;
    border-radius: 5px;
    position: relative;
  }

  .productImage {
    height: 120px;
    width: 100px;
    // border: 1px solid navy;
  }

  .seeMoreContainerDiv {
    padding-top: 4px;
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .SeeMoreContainer {
    //    border: 1px solid red;
    padding: 0px;
    border-radius: 41px;
    background: var(--Charcoal, #000);
    display: flex;
    width: 100%;
  }

  .SeeMoreButton {
    color: var(--CTA-White, #f7f6e6);
    text-align: center;
    font-family: 'ABC Diatype';
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 200% */
    text-transform: uppercase;
    width: 100%;
    padding: 2%;
  }

  .activeOption {
    gap: 5px;
    color: black;
  }

  .articleDossierContainer {
    display: inline-flex;
    padding: 2% 6%;
    border-radius: 21.046px;
    display: flex;
    justify-content: flex-start;
    width: 100%;
  }

  .menuActivesContainer {
    overflow-y: auto;
    //    position: absolute;
    //top: 10%;
    //width: 220%;
    //left: 90%;
    //transform: translateX(-50%);
    //max-height: 20svh;
  }

  .activeGrid {
    // width: 10svw;
    //width: 100%;
    // height: 40svh;
    height: 100%;

    //height: calc(100% - 45px);
    width: 50%;
    background-size: cover;
    /* Ensure the image covers the area */
    background-position: center;
    /* Center the image */
    border-radius: 5px;
    /* Optional: add rounded corners */
    //position: relative;
    //border: 10px solid red;
    //width: 100%;
    //height: 100%;
    //border: 10px solid pink;
    //margin-bottom: 10px;
  }

  .activeImage {
    width: 100%;
    height: 100%;
    background-size: cover;
    /* Ensure the image covers the area */
    background-position: center;
    /* Center the image */
    border-radius: 5px;
    /* Optional: add rounded corners */
    position: relative;
    margin-top: 2px;
    height: 170px;
    width: 100px;
    background-size: cover;
    background-position: center;
    border-radius: 5px;
  }

  .productList {
    //border: 1px solid red;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2px;
  }

  .productInfo {
    width: 140px;
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: 320px) {
  .productList {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }

  .SeeMoreContainer {
    margin-bottom: 30px;
  }
}