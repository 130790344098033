.orderTotalContainer {
    position: relative;
    border-top: 1px solid rgba(0, 0, 0, 0.07);
    max-width: 800px;
}

.orderTotal {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    text-align: right;
    gap: 8px;
    padding: 16px 0;
    max-width: 300px;
    margin-left: auto;
}

.row {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.totalRow {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .label {
        color: #000;
        font-family: "ABC Diatype";
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: -0.12px;
        text-transform: uppercase;
    }

    .price {
        color: #000;
        text-align: right;
        font-family: "NB Grotesk Pro Mono";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: -0.14px;
    }
}

.label {
    color: #5D5246;
    font-family: "ABC Diatype";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.12px;
    text-transform: uppercase;
}

.price {
    color: #5D5246;
    font-family: "ABC Diatype";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.12px;
    text-transform: uppercase;
}