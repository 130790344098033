/* Carousel Container */
@media (max-width: 768px) {
  .swiper-slide {
    min-width: 100px;
    margin-left: 0px;
  }
}

.product-carousel-swiper {
  display: flex;
  width: 100%;
  height: 13rem;
  overflow: hidden;
  /* Ensures only visible slides are shown */
  position: relative;
  /* Create a stacking context */

  .product-slide {
    flex: 1 0 30%;
    /* Adjusts the size of each slide */
    max-width: 100%;
    /* Ensures 3 slides fit with spacing */
    box-sizing: border-box;

    @media (max-width: 768px) {
      flex: none
    }
  }
}

.productDataContainer {
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

/* Individual Product Container */
.product-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 0.5rem;
  position: relative;
  overflow: visible;
  z-index: 1;
  height: 100%;
  background-color: #FFFEED;
  border-radius: 2px;
  justify-content: space-between;

  &.selected::before {
    content: '';
    display: block;
    width: 50%;
    height: 3px;
    background-color: #ccb9a7;
    position: absolute;
    top: 65%;
  }

}

/* Image Container */
.carousel-image-container {
  width: 100px;
  /* Adjust width as needed */
  height: 130px;
  /* Adjust height as needed */
  overflow: hidden;
  position: relative;
  margin-bottom: 0.5rem;
  z-index: 1;

  .carousel-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    transition: opacity 0.3s ease;
  }
}

/* Brand Name */
/* Product Brand */
.mobile-productBrand {
  color: var(--Charcoal, #000);
  font-family: "ABC Diatype";
  font-size: 0.7rem;
  font-style: normal;
  font-weight: 700;
  line-height: 110%;
  text-transform: uppercase;
  margin: 0.2rem 0;
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-top: -0rem;
  bottom: 0;
  z-index: 2;
}

/* Product Title */
.mobile-productTitle {
  color: var(--OLI-PRIMARY-BLACK, #000);
  font-family: "NB Grotesk Pro";
  font-size: 0.7rem;
  font-style: normal;
  font-weight: 400;
  line-height: 105%;
  /* 10.5px */
  letter-spacing: 0.4px;
  text-transform: capitalize;
  margin: 0.2rem 0;
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  text-align: left;
  top: 0;
  cursor: pointer;
  text-overflow: ellipsis;
  width: 120%;

  white-space: wrap;
  text-overflow: ellipsis;
}

.mobile-productPrice {
  color: var(--OLI-PRIMARY-BLACK, #000);
  font-family: "NB Grotesk Pro Mono";
  font-size: 0.69rem;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  /* 13px */
  letter-spacing: -0.26px;
  text-transform: capitalize;
  text-align: left;
}


/* Overlay for Product Info */
.product-info-overlay {
  display: contents;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 3rem;

  >*:first-child {
    margin-bottom: 10px;
  }
}

/* Recommanded Product Container */
.recommanded-productContainer {
  padding: 0 0.5rem;
  position: relative;

  &.selected::before {
    content: '';
    display: block;
    width: 100%;
    height: 3px;
    background-color: #ccb9a7;
    position: absolute;
    bottom: -3px;
    left: 0;
  }
}

/* Responsive Styles */
@media (max-width: 768px) {
  .carousel-image-container {
    width: 80px;
    height: 110px;
  }

  .product-container {
    padding: 0.4rem;
    // padding-top: 1rem;
    max-width: 105px;
  }

  .egg-shape {
    width: 45px;
    height: 45px;
    transform: translateY(-140%);
    margin-bottom: 5px;
  }
}