@import '../../../index.scss';

.bestsellersContainer {
  padding: 20px 20px 20px 0px;
  background-color: #f7f6e7;
  padding-bottom: 50px;
  position: relative;
  overflow: visible !important;

  /*
  @media (max-width: 768px) {
    padding: 100px 20px 20px 20px;
    height: 100svh;
  }
  @media (max-width: 768px) and (max-height: 750px) {
    height: 110svh;
  }
  @media (min-width: 768px) and (max-height: 750px) {
    padding-bottom: 10svh;
  }
  */

  .BSheaderBlock {
    display: flex;
    justify-content: space-between;
    margin-bottom: 120px;

    @media (max-width: 440px) {
      flex-direction: column;
      margin-bottom: 70px;
    }
  }

  .BrandCarouselContainer {
    margin-bottom: 5%;
  }

  .header {
    @include ABCDiatype-Bold(20);
    text-transform: uppercase;
    margin-top: -80px;

    @media (max-width: 440px) {
      margin-bottom: 40px;
    }
  }

  // First .scrollToExplore
  .scrollToExplore {
    color: var(--unnamed, #5d5246);
    // If you want distinct styling, keep it here, otherwise merge with the second .scrollToExplore.
  }

  .containerCarouselHomePage {
    position: relative;
    width: 100%;
    margin-top: -5%;
    height: 750px;

    @media (max-width: 440px) {
      margin-top: -50%;
      height: 80%;
    }
  }

  // Second .scrollToExplore — you might combine both if they are meant to be the same
  .scrollToExplore {
    color: var(--unnamed, #5d5246);
    text-align: center;
    font-family: 'NB Grotesk Pro Mono';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 110.457%;
    transform: translateY(-70px);

    @media (max-width: 440px) {
      padding-bottom: 30px;
      margin-left: 60vw;
      margin-top: 3%;
    }

    @media (max-height: 670px) {
      width: 100%;
      margin-left: 30vw;
    }
  }

  .headerBranding {
    color: var(--Charcoal, #000);
    font-family: 'ABC Diatype';
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 104%; // 20.8px
  }

  // repeated .headerBranding block:
  // If it's exactly the same, merge them; otherwise you can keep one.
  .headerBranding {
    color: var(--Charcoal, #000);
    font-family: 'ABC Diatype';
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 104%;
    text-transform: uppercase;
  }

  .brandhomepageheader {
    @include ABCDiatype-Bold(20);
    text-transform: uppercase;
    position: absolute;
    left: 2%;
  }

  .header-shopPage {
    @include ABCDiatype-Bold(20);
    // You listed a second font-size: 16px; use whichever is correct.
    font-weight: bold;
    font-size: 16px;
    font-style: normal;
    text-transform: uppercase;
    color: var(--Charcoal, #000);
    font-family: 'ABC Diatype';
    font-weight: 700;
    line-height: 104%; // 16.64px
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .scrollToExploreContainer {
    position: absolute;
    top: 85%;
    right: 5%;

    @media (max-width: 440px) {
      top: 86%;
    }
  }

  .scrollToExploreText {
    color: var(--unnamed, #5d5246);
    text-align: center;
    font-family: 'NB Grotesk Pro';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 110.457%; // 11.046px
  }

  .description {
    @include NBGroteskProMono-Regular(15);
    height: 100%;
    width: 30%;
    transform: translateY(-70px);

    @media (max-width: 440px) {
      top: 13%;
      left: 5%;
      width: 80%;
      margin-top: 10%;
    }

    @media (max-height: 670px) {
      top: 11%;
      left: 5%;
      width: 80%;
      margin-top: 10%;
    }
  }

  .brandDescription {
    @include NBGroteskProMono-Regular(15);
    width: 50%;
  }

  .indicatorContainer {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
  }

  .indicatorContainer-shopPage {
    display: flex;
    justify-content: center;
  }
}

// end .bestsellersContainer

.bestsellersCarouselContainer {
  padding-top: 50px;
  // border: 1px solid red;
}

.BrandShopButton {
  display: flex;
  padding: 8px 34px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 26px;
  background: var(--Charcoal, #000);
  color: var(--Off-White, #f7f6e6);
  font-family: 'ABC Diatype';
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
}

// Cleaned up shopbestsellersContainer + nested .BrandShopButton
.shopbestsellersContainer {
  background-color: #f7f6e7;
  padding-bottom: 5%;
  margin-top: -10px;

  @media (max-width: 440px) {
    height: 55svh;
  }

  @media (max-height: 710px) {
    height: 65svh;
  }

  .BrandShopButton {
    position: absolute;
    left: 47%;
    width: 100%;
  }
}

.buttonContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}

// Fix .shopButtonContainer block
.shopButtonContainer {
  margin-top: 2%;
  display: flex;
  width: 100%;
}

.BrandShopButtonContainer {
  display: flex;
  width: 100%;
  justify-content: center;
}

.BrandButton {
  display: inline-flex;
  padding: 8px 34px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  //border: 1px solid red;
}

.shopButton {
  color: var(--Off-White, #f7f6e6);
  font-family: 'ABC Diatype';
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  width: 153px;
  height: 30px;
  line-height: normal;
  text-transform: uppercase;
  border-radius: 26px;
  background: var(--Charcoal, #000);
  //border: 1px solid red;
}

.UpperSectionBlock {
  display: flex;
  justify-content: space-between; // Align children to the ends
  align-items: flex-start; // Align items vertically at the start
  padding: 20px; // Optional: Add some padding
  flex-wrap: wrap;
  width: 100%;

  @media (max-width: 440px) {
    flex-direction: column;
    margin-right: 10px;
    padding: 0;
    padding-top: 40px;
  }
}

// Adjusting the .headerBrandingContainer for flex usage:
.headerBrandingContainer {
  /* Removed position: absolute; etc. */
  flex: 1 1 40%; // Grow, shrink, basis
  max-width: 309px;
}

.bestsellersDescriptionContainer {
  flex: 1 1 50%;
  max-width: 460px;
  height: auto;

  @media (max-width: 440px) {
    padding: 0;
    margin-top: 40px;
  }
}

.bestsellersDescription {
  color: #000;
  font-family: 'NB Grotesk Pro Mono';
  font-size: 15px;
  font-weight: 400;
  /* maybe try a simpler line-height to avoid fractional px issues */
  line-height: 1.2;
}

.containerCarouselHomePage {
  position: relative;
  width: 100%;
  margin-top: -5%;
  height: 75vh;
  //border: 10px solid green;
  overflow: visible !important;
  transform-style: preserve-3d;
  perspective: 1000px;
}

/* -----------------------------------------
   Media Queries
   ----------------------------------------- */

@media (max-width: 768px) {
  .containerCarouselHomePage {
    // margin-top: -50%;
    height: 80%;
  }

  .bestsellersContainer {
    padding: 0px 20px 20px 20px;

    border-radius: 26px;
    background: var(--Charcoal, #000);
    color: var(--Off-White, #f7f6e6);
    font-family: 'ABC Diatype';
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    //text-transform: uppercase;
    height: 780px;
  }
}

@media (max-width: 768px) {
  .shopMobileButtonContainer {
    margin-top: 20px;
    display: flex;
    width: 100%;
    padding: 8px 34px;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  .shopMobileButton {
    color: var(--Off-White, #f7f6e6);
    font-family: 'ABC Diatype';
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    width: 50%;
    height: 30px;
    line-height: normal;
    text-transform: uppercase;
    border-radius: 26px;
    background: var(--Charcoal, #000);
  }
}

@media (max-width: 440px) {
  .description {
    width: 100%;
    margin-bottom: 10%;
  }

  .BrandShopButtonContainer {
    display: flex;
    width: 100%;
    justify-content: center;
  }

  .buttonContainer {
    margin-top: 50px;
  }

  .BrandButton {
    display: inline-flex;
    padding: 8px 34px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 26px;
    background: var(--Charcoal, #000);
    color: var(--Off-White, #f7f6e6);
    font-family: 'ABC Diatype';
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
  }

  .shopButtonContainer {
    position: absolute;
    left: -20%;
    margin-top: 30%;
    width: 100%;
  }

  .shopMobileButtonContainer {
    // Adjust as needed for mobile
  }

  .bestsellersCarouselContainer {
    //padding-top: 0vh;
  }

  .shopMobileButton {
    width: 100%;
    height: 100%;
    padding: 8px 34px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: var(--Off-White, #f7f6e6);
    font-family: 'ABC Diatype';
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    border-radius: 26px;
    background: var(--Charcoal, #000);
  }
}