.navbar {
  position: relative;
  z-index: 10000;
  background-color: transparent;
}

.loadingPageContainer {
  position: fixed;
  width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #5b5146;
  z-index: 9999;
  overflow: hidden;
}

.imageContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
}
