.relatedProducts {
  width: 100%;
  cursor: none;

  * {
    cursor: none;
  }

  .mobileIndicator {
    display: none;
  }

  .swiperSlideContainer {
    padding-top: 2%;
    padding-bottom: 2%;
  }

  .relatedProductsSwiper {
    width: 100%;
    height: 100%;
    overflow: visible !important;
    position: relative;
    cursor: none;
  }

  .relatedProductSlide {
    height: 62vh;
    width: 25.5vw;
    border-radius: 8px;
    flex-shrink: 0;
    display: flex !important;
    flex-direction: column;
    align-items: center;
    background-color: #f0edde;
    position: relative;
    text-decoration: none;
    color: inherit;
    padding: 10px;
    transform-origin: 50% 50%;
    transition: all 0.3s ease;
    will-change: transform;
    cursor: none;

    &:hover {
      transform: scale(1.05);
      z-index: 2;
    }

    &.moving {
      transform: scale(1);
    }
  }

  .relatedProductCard {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    cursor: none;

    .productLink {
      width: 100%;
      text-decoration: none;
      color: inherit;
      cursor: none;
    }

    .imageContainer {
      width: 100%;
      height: 90%;
      max-height: 50vh;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      padding-bottom: 4vh;
    }

    .productImage {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    .productInfo {
      display: flex;
      flex-direction: column;
      width: 100%;
      position: absolute;
      bottom: 0;
    }

    .productBrand {
      color: var(--Charcoal, #000);
      font-family: 'ABC Diatype';
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 110%;
      letter-spacing: -0.12px;
      text-transform: uppercase;
      padding-bottom: 6px;
    }

    .productTitle {
      color: var(--OLI-PRIMARY-BLACK, #000);
      font-family: 'NB Grotesk Pro Mono';
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%;
      letter-spacing: -0.26px;
      text-transform: capitalize;
    }
  }

  .productPrice {
    display: flex;
    flex-direction: row;
    padding-top: 5px;
    color: var(--Black, #000);
    font-family: 'NB Grotesk Pro Mono';
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    letter-spacing: -0.28px;
    text-transform: capitalize;
    padding-bottom: 5px;

    @media (max-width: 768px) {
      text-align: left;
      width: 100%;
    }
  }

  .justDroppedContainer {
    position: absolute;
    top: 0px;
    left: 0px;
  }

  .justDroppedText {
    color: var(--Backgrounds-CERAMICS, #5d5246);
    font-family: 'ABC Diatype';
    font-size: 8px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
  }

  .favoriteIconContainer {
    position: absolute;
    top: 0px;
    right: 0px;
    padding: 0% 4% 6% 4%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    z-index: 3;
    opacity: 1;
    transition: all 0.3s ease;
    cursor: none;

    &:hover {
      .favoriteIcon {
        opacity: 0.7;
        transform: scale(1.1);
      }
    }
  }

  .favoriteIcon {
    height: 100%;
    width: 100%;
    opacity: 1;
    transition: all 0.3s ease;
  }
}

.cursorContainer {
  position: fixed;
  pointer-events: none;
  z-index: 99999;
  transform: translate(-50%, -50%);
  display: none;
  width: 20px;
  height: 20px;
}

.cursor {
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 0.3s ease;
  transform: translate(-50%, -50%);
}

body {
  cursor: auto;
}