.tilesSection {
  display: flex;
  padding-top: 50px;
  justify-content: center;
  align-items: center;
  gap: 30px;
  padding-bottom: 50px;
  cursor: url('../../assets/cursors/dragCursor.svg') 40 30, auto;
}

.tileBackground {
  background-size: cover;
  width: 47vw;
  max-width: 750px;
  height: 450px;
  flex-shrink: 0;
  border-radius: 20px;
  position: relative;
}

.tileImage {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.tileContent {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  position: absolute;
  bottom: 0%;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 80px;
  padding: 0px;
  border-radius: 10px;
}

.preTitle {
  color: var(--Off-White, #f7f6e6);
  font-family: 'ABC Diatype';
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  text-transform: uppercase;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
  /* Darker text shadow */
}

.title {
  color: var(--Off-White, #f7f6e6);
  text-align: center;
  font-family: 'ABC Diatype';
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%;
  letter-spacing: -0.32px;
  text-transform: uppercase;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
  /* Darker text shadow */
  margin-bottom: 20px;
}

.subTitle {
  color: #fff;
  text-align: center;
  font-family: 'NB Grotesk Pro Mono';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
  width: 327px;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
  /* Darker text shadow */
  padding-bottom: 28px;
}

.buttonContainer {
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
}

.button {
  display: flex;
  padding: 8px 34px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 26px;
  background: var(--Off-White, #f7f6e6);
  border: none;
  color: var(--Charcoal, #000);
  font-family: 'ABC Diatype';
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;
  text-transform: uppercase;
}

@media (max-width: 768px) {
  .tileBackground {
    width: 90vw;
    height: 450px;
  }

  .tileContent {
    padding-bottom: 16%;
    //border: 1px solid red;
    min-height: 260px;
  }

  .buttonContainer {
    bottom: 100px;
  }

  .subTitle {
    width: 260px;
  }
}