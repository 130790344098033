.hotbrandscontainer {
  padding: 20px;
  background-color: #f7f6e6;
  position: relative;
}

.hotbrandstitle {
  margin-bottom: 20px;
  margin-left: 0.5%;
  margin: 1%;
  color: var(--Charcoal, #000);
  font-family: 'ABC Diatype';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 104%; /* 14.56px */
  text-transform: uppercase;
}
.swiperpaginationcontainer {
  position: absolute;
  right: 1%;
  top: 1%;
}

.hotbrandslistcontainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  overflow-y: auto;
  position: relative;
  margin-top: 0%;
  width: 100%;
  gap: 10px;
  min-height: 60vh;
}
.hotbrandsletter {
  font-size: 20px;
  font-weight: bold;
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 37%;
  opacity: 0.5;
  width: 10vw;
  position: absolute;
  left: -10%;
}

.hotbrandsletter.active {
  font-weight: bold;
  opacity: 1;
}

.hotbrandslist {
  display: flex;
  flex-direction: column;
  width: 10vw;
  justify-content: start;
  text-align: start;
  gap: 10px;
  transform: translateX(0%);
  color: var(--Charcoal, #000);
  text-align: start;

  /* Title/Mobile/H2 */
  font-family: 'ABC Diatype';
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 104%; /* 20.8px */
  text-transform: uppercase;
}

.hotbranditem {
  text-transform: uppercase;
  font-size: 14px;
  color: #555;
  line-height: 1.5;
  transform: translateX(-30%);
  width: 30vw;
  .hotbrandproductcount {
    display: inline-block;
    color: var(--OLI-PRIMARY-BLACK, #000);
    text-align: center;
    font-family: 'NB Grotesk Pro Mono';
    font-size: 8.709px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 8.709px */
    letter-spacing: -0.174px;
    text-transform: capitalize;
  }
  .numberinlist {
    font-family: 'NB Grotesk Pro Mono';
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 8.709px */
    letter-spacing: -0.174px;
    text-transform: capitalize;
    margin-right: 4px;
  }
}

.hotbrandsimagecontainer {
  width: 100%;
  height: 18vh;
  border-radius: 10px;
  overflow: hidden; // Ensures nothing spills outside the container
  @media (max-width: 768px) {
    transform: translateX(-3%);
  }
}

.hotbrandsimage {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: block;
  margin: 10px;
  //object-fit: contain;
}
.hotbrandsseparator {
  width: 70%;
  position: absolute;
  right: 0%;
  height: 1px;
  opacity: 0.2;
  background: #000;
  margin-top: -1%;
}
.seeAllContainer {
  position: absolute;
  top: 0%;
  right: 0%;
}
.seeAllText {
  color: var(--CTA-Black, #000);
  text-align: center;
  font-family: 'ABC Diatype';
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 200% */
  text-transform: uppercase;
}
.hotbrandsnameoverlay {
  position: absolute;
  bottom: 20px; // Position from the bottom of the image
  left: 20px; // Position from the left of the image
  padding: 5px 10px; // Padding around the text
  border-radius: 5px; // Rounded corners for the background

  color: var(--CTA-White, #f7f6e6);
  text-align: center;
  font-family: 'ABC Diatype';
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 109%; /* 19.62px */
  text-transform: uppercase;
}

.hotbrandlink {
  text-decoration: none;
  color: inherit;
  transition: color 0.3s ease;

  &:hover {
    color: #000; // Darker color on hover
  }
}

// Add styles for the popular overlay
.hotbrandspopularoverlay {
  position: absolute;
  top: 20px; // Position from the top of the image
  left: 20px; // Position from the left of the image
  display: inline-flex;
  padding: 3px 5px;
  font-family: 'ABC Diatype';
  font-size: 14px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 28px;
  color: #000;
  background: var(--WHITE, #f7f6e6);
}
.categoriesAndBrandsContainer {
  display: inline;
  flex-direction: column;
  gap: 20px;
}

.hotbrandslistcategorycontainer {
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 10px;
  left: 5%;
  position: absolute;
}
.hotbrandslistcategoryitem {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 10px;
}
.hotbrandscategoriescontainer {
  margin-left: 1%;
  position: relative;
  flex-direction: column;
}
.hotbrandscategory {
  display: flex;
  margin-bottom: 4px;
}

.checkboxcustom {
  width: 10px;
  height: 10px;
  margin-right: 10px;
}

/* Hide the default checkbox */
.hotbrandscategory input[type='checkbox'] {
  display: none;
}
.categoriesName {
  cursor: pointer;
  text-transform: uppercase;
  font-family: 'NB Grotesk Pro Mono';
  font-size: 12px;
  font-weight: 700;
  line-height: 110%;
  color: #000;
  display: flex;
  justify-content: flex-start;
}
/* Apply the line-through effect to the text when the checkbox is checked */

.hotbrandspopularbrandstitle {
  margin-left: 0.5%;
  color: var(--Charcoal, #000);
  font-family: 'ABC Diatype';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 104%; /* 14.56px */
  text-transform: uppercase;
}
/* Apply the line-through effect to the text when the checkbox is checked */

.hotbrandspopularbrandstitle {
  margin-left: 0.5%;
  color: var(--Charcoal, #000);
  font-family: 'ABC Diatype';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 104%; /* 14.56px */
  text-transform: uppercase;
}
@media (max-width: 768px) {
  .hotbrandscategory {
    display: none;
  }
  .hotbranditem {
    width: 50vw;
    transform: translateX(-60%);
  }
  .hotbrandslistcontainer {
    margin-top: 10%;
  }
  .hotbrandsletter {
    left: -40%;
  }
  .hotbrandsseparator {
    margin-top: 2%;
    width: 95%;
  }
}
