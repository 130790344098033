@import '../../index.scss';

html,
body {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.sideMenuContainer {
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  padding-right: 12%;
}

.sideMenuItemContainer {
  width: 100%;
  margin-bottom: 20px;
}

.sideMenuItemHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.sideMenuText {
  color: #000;
  font-family: 'ABC Diatype';
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%;
  letter-spacing: -0.12px;
  text-transform: uppercase;
}

.plusMinusIcon {
  height: 15px;
  width: 15px;
}

.sideMenuSubText {
  margin-top: 10px;
  color: #000;
  font-family: 'NB Grotesk Pro Mono';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
  letter-spacing: -0.12px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  overflow: hidden;
  white-space: normal;
  max-width: 90%;
}

.productDetailsContainer {
  background-color: #f7f6e6;
  min-height: 100vh;
  overflow-x: hidden;
}

.alert-icon {
  position: relative;
  width: 18px;
  height: 18px;
  z-index: 1;
}

.info-icon {
  position: absolute;
  width: 12px;
  height: 12px;
  z-index: 2;
  margin-top: 0.2rem;
  margin-right: 0.2rem;
}

.scoreDataContainer {
  margin-bottom: 50px;
}

.advice-container {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-wrap: nowrap;
  width: 100%;
}

.explainScoreContainer {
  text-align: right;
}

.explainScoreText {
  color: #000;
  font-family: "NB Grotesk Pro Mono";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.12px;
  text-decoration: underline;
  text-transform: lowercase;
  cursor: pointer;
}

.advice-logo {
  margin-right: 12px;
  width: 42px;
  height: 27px;
}

.toggle-icon-IngredientsOpen {
  border: none;
  background-color: transparent;
}

.ingredients-ul {
  margin: 0px;
}

.advice-image-container {
  width: 50%;
  display: flex;
}

.advice-image {
  width: 65px;
}

.product-score {
  font-family: 'ABC Diatype-Bold', Arial, sans-serif;
  font-size: 18px;
  font-weight: 800;
  align-self: end;
}

.score-display {
  display: flex;
  gap: 2px;
  align-items: end;
}

.score-display span {
  margin: 0 4px;
  font-family: 'ABC Diatype-Bold', Arial, sans-serif;
}

.score-digit {
  height: 24px;
  width: auto;
}

.brand-name {
  cursor: pointer;
}

.risk-status-bg {
  padding: 10px;
  border-radius: 8px;
  font-size: 0.85rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 28px;
  pointer-events: none;

  p {
    margin: 0;
    display: flex;
    align-items: center;
  }

  .risk-icon {
    width: 16px;
    height: 16px;
    margin-right: 5px;
  }
}

.product-detail-container {
  padding: 80px 40px 0 40px;
  background-color: #f7f6e6;
  color: #333;
  align-items: center;
  justify-content: center;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  overflow: hidden;
  width: 100%;
  min-width: 1400px;
}

.side-menu {
  height: 100%;
  width: 100%;
  align-items: center;
  display: flex;
}

.product-image-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .product-image {
    background-color: rgba(204, 185, 167, 0.3);
    border-radius: 13px;
    width: 661px;
    height: 669px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;

    img {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
    }
  }

  .mini-image {
    display: flex;
    justify-content: center;
    gap: 5px;
    margin-top: 10px;

    img {
      width: 50px;
      height: 50px;
      object-fit: cover;
      border: 1px solid #ccc;
      cursor: pointer;
    }
  }

  .image-loader {
    width: 100%;
    height: 400px;
    background: linear-gradient(to right, #f0f0f0 0%, #e0e0e0 20%, #f0f0f0 40%, #f0f0f0 100%);
    background-size: 200% 100%;
    animation: lightShimmer 1.5s linear infinite;
    border-radius: 8px;
  }

  @keyframes lightShimmer {
    0% {
      background-position: -100% 0;
    }

    100% {
      background-position: 100% 0;
    }
  }
}

.product-info {
  height: 100%;
  position: relative;
  padding-left: 12%;
  max-width: 700px;
  overflow-x: hidden;

  .brand-name {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    //position: relative;
    //border: 10px solid yellow;
    //top: 20%;
    margin-top: 20%;
    //top: 200px;
  }

  .brand-name h1 {
    color: #000;

    font-family: 'ABC Diatype';
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    /* 128.571% */
    letter-spacing: -0.14px;
    text-transform: uppercase;
    max-width: calc(100% - 100px);
  }

  .product-brand {
    color: #000;
    font-family: 'NB Grotesk Pro Mono';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    padding-top: 10px;
  }

  .productpagePrice {
    color: var(--Ceramic, #7e7063);
    text-align: right;
    font-family: 'ABC Diatype';
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 115.5%;
    /* 9.24px */
    text-transform: uppercase;
  }

  .certificates {
    // margin-top: 15px;
    // margin-bottom: 50px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .certificate-badge {
      background-color: none;
      padding: 5px 10px;
      margin-right: 10px;
      border-radius: 23px;
      border: 0.5px solid #000;
      font-family: 'ABC Diatype', Helvetica, Arial, sans-serif;
      font-size: 11px;
      text-transform: uppercase;
      line-height: normal;
      margin-bottom: 8px;
    }
  }

  .product-sizes {
    margin-top: 50px;

    h3 {
      color: #000;
      text-align: center;
      font-family: 'ABC Diatype';
      font-size: 10px;
      font-style: normal;
      font-weight: 700;
      line-height: 110%;
      /* 11px */
      letter-spacing: -0.1px;
      text-transform: uppercase;
      text-align: left;
      margin-top: 20px;
      padding-bottom: 10px;
    }

    .size-options {
      display: flex;
      gap: 10px;
      flex-wrap: wrap;

      .size-button {
        background-color: #ebe6d5;
        padding: 20px 10px 7px 10px;
        border: none;
        cursor: pointer;
        font-family: 'NB Grotesk Pro Mono', Helvetica, Arial, sans-serif;
        font-size: 0.9rem;
        padding: 15px;

        &:disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }

        span {
          margin-left: 40px;
          font-family: 'NB Grotesk Pro Mono', Helvetica, Arial, sans-serif;
          font-size: 0.6rem;
        }
      }

      .size-button.active {
        background-color: #5d5246;
        color: white;
      }
    }
  }

  .add-to-cart-container {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .add-to-cart {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000;
    color: #f7f6e6;
    padding: 15px 78px;
    border: none;
    border-radius: 62px;
    cursor: pointer;
    text-align: center;
    font-family: 'ABC Diatype';
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    /* 200% */
    text-transform: uppercase;
    width: 250px;
    height: 44px;

    @media (max-width: 1360px) {
      display: flex;
      width: 90%;
      padding: 16px 32px;
      flex-direction: column;
      align-items: center;
      gap: 10px;
    }

    &:disabled {
      background: rgba(128, 128, 128, 0.55);
      color: #f7f6e6;
      cursor: not-allowed;
    }
  }

  .add-to-cart-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    width: 250px;
  }
}

.description-style {
  margin-top: 20px;
  //position: absolute;
  //top: 50%;
  max-height: 200px;
  overflow-y: auto;
  color: var(--Ceramic, #5d5246);
  font-family: 'NB Grotesk Pro Mono';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: 0.28px;
  width: 284px;
  width: 100%;
  padding-right: 10px;

  @media (max-width: 1360px) {
    position: relative;
    top: 0;
    text-align: left;
  }
}

/* Optional: Customize scrollbar appearance for better aesthetics */
.description-style::-webkit-scrollbar {
  background: transparent;
  width: 0px;
}

.description-style::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

.description-style::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.4);
}

.toggle-header-Ingredients {
  display: flex;
  justify-content: space-between;
  margin-top: 4px;
  //border: 1px solid red;
}

.toggle-header-Ingredients h3 {
  color: #000;
  background-color: none;
  background: inherit;
  font-family: 'ABC Diatype Unlicensed Trial-Bold', Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%;
  letter-spacing: -0.14px;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.advice-text {
  color: #000;
  font-family: 'ABC Diatype';
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  /* 128.571% */
  letter-spacing: -0.14px;
  text-transform: uppercase;
}

.mobile-divider {
  margin: 20px 0;
  border: none;
  height: 1px;
  background-color: inherit;
  opacity: 0.2;
}

.all-brands-products-container {
  position: relative;
  overflow-y: hidden;

  .product-highlight {
    position: absolute;
    bottom: 16px;
    height: 2px;
    background-color: #5d5246;
    transition: all 0.3s ease;
  }
}

.product-image {
  position: relative;
  display: flex;
  align-items: center;
}

.scrollable-image-gallery {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 500px;
  align-items: center;
  scroll-snap-type: y mandatory;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: gray;
  /* Default dot color */
  opacity: 1;
  /* Ensures visibility */
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.swiper-pagination-bullet-active {
  background-color: black;
  /* Active dot color */
  transform: scale(1.2);
  /* Enlarges active dot */
}

.custom-bullet {
  margin: 4px;
  /* Adjust spacing between dots */
}

.scrollable-image-gallery::-webkit-scrollbar {
  display: none;
}

.dots-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  justify-content: center;
}

.current-dot {
  width: 15px;
  height: 15px;
}

.light-dot {
  width: 15px;
  height: 15px;
}

.scrollable-image {
  width: 80%;
  height: 90%;
  padding-left: 5%;
  object-fit: cover;
  scroll-snap-align: start;
  //border: 10px solid red;
}

.show-more-btn {
  border: none;
  background: none;
  cursor: pointer;
  font-size: 10px;
  font-weight: 400;
  font-family: 'ABC Diatype';
}

.scroll-dots {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.dot.active {
  opacity: 1;
}

.dot {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.product-box {
  display: flex;
  flex-direction: column;

  .miniature-carousel {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    overflow-x: auto;
    white-space: nowrap;
    margin-top: -15px;
  }

  .miniature-carousel .recommandedContainer {
    width: 100%;
    max-width: 500px;
    overflow: hidden;
  }

  .miniature-carousel .indicatorContainer {
    display: none;
  }

  .miniature-carousel .BSheaderBlock {
    display: none;
  }

  .miniature-carousel .productsCarouselSliderBlock {
    width: 100%;
    height: auto;
  }

  .miniature-carousel .mobile-productContainer {
    width: 70px;
    height: 70px;
    margin-right: 10px;
    object-fit: cover;
    border-radius: 8px;
    cursor: pointer;
  }

  .miniature-carousel .productsCarouselSliderBlock {
    width: auto;
    max-width: 100%;
    overflow: visible;
  }

  .miniature-carousel .clickableImage {
    width: 100%;
    height: 100%;
  }

  .miniature-carousel .productBrand,
  .miniature-carousel .productTitle,
  .miniature-carousel .indicatorContainer,
  .miniature-carousel .BSheaderBlock {
    display: none;
  }

  .miniature-carousel .productContainer {
    width: 70px;
    height: 150px;
    margin-right: 10px;
    object-fit: cover;
    border-radius: 8px;
    cursor: none;
    background-color: transparent;
  }
}

.miniature-carousel .clickableImage {
  width: 250px;
  height: 457px;
  cursor: auto;
}

.miniature-carousel .productsCarouselSliderBlock {
  cursor: auto;
}

.miniature-carousel .productContainer:hover .clickableImage img {
  opacity: 1;
}

.no-scroll {
  overflow: hidden;
}

.save-product-container {
  display: inline-flex;
  padding: 0px 12px 0px 13px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  justify-content: right;
  align-items: right;
  width: 100%;
  margin-left: 12px;
  gap: 10px;
}

.save-product-container-mobile {
  display: inline-flex;
  padding: 0px 12px 0px 13px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  justify-content: right;
  align-items: right;
  width: 108%;
}

.save-share-product-button {
  color: var(--Charcoal, #000);
  font-family: 'ABC Diatype';
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 250% */
  text-transform: uppercase;
  border: none;
  background: none;
  cursor: pointer;
  background: #ebe6d5;
  border-radius: 14px;
  min-width: 69px;
}

.save-share-product-button-mobile {
  color: var(--Charcoal, #000);
  margin-right: 20px;
  font-family: 'ABC Diatype';
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 250% */
  text-transform: uppercase;
  border: none;
  background: none;
  cursor: pointer;
  background: rgba(93, 82, 70, 0.12);
  border-radius: 14px;
  width: 69px;
}

.add-to-cart-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;

  .add-text {
    color: var(--Off-white, #f7f6e6);
    text-align: center;
    font-family: 'ABC Diatype';
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    /* 200% */
    text-transform: uppercase;
  }

  .from-text {
    color: var(--Off-white, #f7f6e6);
    font-family: 'ABC Diatype';
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    text-transform: lowercase;
  }
}

.description-toggle-icon {
  //border: 1px solid red;
  justify-content: center;
  align-items: center;
  display: flex;
}

@media (min-width: 769px) and (max-width: 1024px) {
  .add-to-cart {
    margin-bottom: 25px;
  }

  .product-image {
    margin-bottom: 25px;
  }
}

@media (max-width: 1360px) {
  .dots-container {
    position: absolute;
    //top: 45%;
    left: 5%;
    z-index: 2;
    //display: flex;
    //flex-direction: column;
    //gap: 10px;
    //height: 100%;
    //justify-content: center;
    //border: 10px solid red;
  }

  .product-detail-container {
    flex-direction: column;
    padding: 0px;
    //border: 10px solid red;
    min-width: 100%;
    display: flex;
    flex-direction: column;
    // grid-template-rows: repeat(1, 1fr);
    grid-gap: 0px;
    // border: 10px solid red;
    overflow: hidden;
  }

  .sideMenuContainer {
    padding-right: 0px;
  }

  .side-menu {
    width: 100%;
    padding-right: 0;
    margin-bottom: 20px;
  }

  .ingredients-title {
    font-size: 12.2rem;
  }

  .description-style.mobile-hidden {
    margin-top: 0px;
    height: 100%; // Adjust as needed for mobile
    overflow-y: hidden;
    display: none;
  }

  .description-style.mobile-shown {
    margin-top: 0px;
    max-height: none;
    overflow-y: auto;
    width: 100%;
  }

  .productDetailsContainer {
    overflow-x: hidden;
    background-color: #f7f6e6;
    min-height: 100vh;
    padding-bottom: 70px;
  }

  .show-more-btn {
    border: none;
    background: none;
    color: black;
  }

  .mini-image {
    display: none;
  }

  .advice-logo {
    display: none;
  }

  .side-menu {
    margin-top: 1rem;
    order: 2;
  }

  .toggle-icon-IngredientsOpen {
    color: black;
    border: none;
    background: none;
    padding: 0;
    font-size: 18px;
  }

  .risk-status-bg {
    font-size: 0.75rem;
    pointer-events: none; // Disables clicking
    width: 100%;
    margin-bottom: 0;

    p {
      font-size: 0.75rem;
    }
  }

  .sideMenuItemContainer {
    width: 100%;
    margin-bottom: 0;
    padding: 20px;
  }

  .sideMenuSubText {
    margin-top: 10px;
    color: #000;
    font-family: 'NB Grotesk Pro Mono';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
    letter-spacing: -0.12px;
    word-wrap: break-word;
    overflow-wrap: break-word;
    overflow: hidden;
    white-space: normal;
    max-width: 100%;
  }

  .product-image-section {
    width: 100%;
    height: 516px;

    .product-image {
      width: 100%;
      height: 100%;
      background-color: rgba(204, 185, 167, 0.2);
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      border-radius: 0px;
      padding-top: 10vh;
    }

    .productpage-brand {
      align-self: flex-start;
      color: #000;
      font-family: 'ABC Diatype Unlicensed Trial-Bold', Helvetica;
      font-size: 16px;
      font-weight: 700;
      line-height: 14px;
      text-transform: uppercase;
      text-align: left;
      word-wrap: break-word;
      white-space: normal;
      overflow-wrap: break-word;
      max-width: 100%;
      margin-bottom: 10px;
      cursor: pointer;
    }

    .title-price-container {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      gap: 10px;
      width: 100%;
    }

    .productpage-title {
      font-family: 'NB Grotesk Pro Mono', Helvetica;
      font-size: 14px;
      line-height: 18px;
      color: #000;
      word-wrap: break-word;
      white-space: normal;
      margin: 0;
      text-align: left;
      max-width: calc(100% - 80px);
    }

    .product-price-container {
      position: absolute;
      bottom: 0;
      right: 0;
      height: 7rem;
      width: 100%;
      z-index: 1000;
    }

    .product-price {
      font-family: 'ABC Diatype Unlicensed Trial-Regular', Helvetica;
      font-size: 12px;
      font-weight: 200;
      line-height: 115.5%;
      color: var(--Charcoal, #000);
      text-align: right;
      text-transform: uppercase;
      margin-left: auto;
      white-space: nowrap;
    }
  }

  .description-container {
    border-top: 2px solid rgba(204, 185, 167, 0.2);
    width: 100%;
    margin-bottom: 0px;
    padding: 0px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .product-description-toggle {
    border: none;
    padding: 0;
    color: #000;
    background-color: none;
    background: inherit;
    font-family: 'ABC Diatype Unlicensed Trial-Bold', Helvetica;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
    letter-spacing: -0.14px;
    text-transform: uppercase;
    margin-bottom: 20px;
  }

  .product-info {
    width: 100%;
    height: 100%;
    padding-left: 0;
  }

  .certificates {
    flex-direction: column;
    align-items: flex-start;
  }

  .size-button {
    width: calc(50% - 10px);
  }

  .add-to-cart {
    color: #f7f6e6;
    padding: 10px 50px;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 1000;
    margin: 0;
    width: 100%;
    transform: translateX(5%);
    margin-bottom: 20px;
    z-index: 999;
  }

  .size-popup-overlay {
    position: fixed;
    top: 0;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 998;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .size-popup {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    background: #f7f6e6;
    width: 100%;
    margin: 0 auto;
    padding: 20px 20px 90px 20px;
    border-radius: 10px 10px 0 0;
    margin-bottom: 0;

    .header-popup {
      display: flex;
      justify-content: space-between;
      margin-bottom: 25px;

      h3 {
        font-family: 'ABC Diatype-Bold', Helvetica;
        font-size: 12px;
        line-height: 10.9px;
        font-weight: 700;
      }

      .close-button {
        background: none;
        background-color: inherit;
        border: none;
        font-family: 'ABC Diatype-Bold', Helvetica;
        font-size: 12px;
        line-height: 10.9px;
        font-weight: 700;
      }
    }

    .size-button {
      background-color: #ebe6d5;
      padding: 20px 10px 7px 10px;
      border: none;
      cursor: pointer;
      font-family: 'NB Grotesk Pro Mono', Helvetica, Arial, sans-serif;
      font-size: 0.9rem;
      padding: 15px;

      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }

      &.active {
        background-color: #5d5246;
        color: white;
      }

      span {
        margin-left: 40px;
        font-family: 'NB Grotesk Pro Mono', Helvetica, Arial, sans-serif;
        font-size: 0.6rem;
      }
    }
  }

  .productDetailsContainer {
    padding-bottom: 90px;
  }

  .explainScoreContainer {
    padding-right: 20px;
  }

  .advice-container {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
    margin-bottom: 0px;
    padding: 20px 20px 0px 20px;

    .advice-image {
      height: 20px;
      width: auto;
    }

    .score-display {
      display: flex;
      align-items: center;
      gap: 2px;
    }
  }

  .description-title {
    margin-top: 1rem;
  }

  .toggle-header-Ingredients h3 {
    color: #000;
    font-family: 'ABC Diatype';
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
    /* 13.2px */
    letter-spacing: -0.12px;
    margin-bottom: 10px;
  }

  .toggle-header-Ingredients button {
    color: black;
    border: none;
    background: none;
  }

  .product-description-toggle button {
    color: black;
    border: none;
    background: none;
  }

  .close-button {
    color: black;
    border: none;
    background: none;
  }

  .size-options button {
    color: black;
    border: none;
    background: none;
  }

  .product-desc {
    padding: 8px 3px;
  }
}

@media (max-width: 480px) {
  .size-button {
    width: 100%;
  }

  .toggle-header-Ingredients button {
    color: black;
    border: none;
    background: none;
  }

  .product-description-toggle button {
    color: black;
    border: none;
    background: none;
  }

  .toggle-icon-IngredientsOpen {
    color: black;
    border: none;
    background: none;
  }

  .close-button {
    color: black;
    border: none;
    background: none;
  }

  .size-options button {
    color: black;
    border: none;
    background: none;
  }
}

@media (max-width: 440px) {
  .product-image-section {
    .product-image {
      padding-top: 5vh;
    }
  }
}

@media (max-width: 360px) {
  .productpage-title {
    margin-left: 30px;
  }
}