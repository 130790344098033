.article-detail-container {
  display: flex;
  height: 100vh;
  font-family: "Arial", sans-serif;
  position: relative;

  @media (max-width: 768px) {
    width: 25rem;
  }
}

.back-button {
  position: absolute;
  top: 40px;
  left: 20px;
  z-index: 3;
  background-color: rgba(255, 255, 255, 0.2);
  border: none;
  border-radius: 50px;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: rgba(255, 255, 255, 0.5);
  }
}

.article-image-section {
  width: 50%;
  position: relative;
  overflow: hidden;
  height: 100vh;
}

.article-image {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.article-image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1); // Adjust opacity as needed
  z-index: 1;
}

.article-meta {
  padding: 0px 56px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  z-index: 2; // Increase z-index to appear above the overlay
  position: relative;
  color: white;
}

.article-categor {
  font-size: 9px;
  text-transform: uppercase;
  background-color: #ccb9a7;
  color: black;
  padding: 5px 10px;
  border-radius: 20px;
  display: inline-block;
  margin-bottom: 10px;
}

.article-title-detail {
  font-family: 'NB Grotesk Pro Mono';
  font-weight: 400;
  font-size: 26px;
  margin: 0;
  white-space: normal;
  width: 100%;
}

.article-loader {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to bottom, #F7F6E6, #C8BDA8);
}

.article-content-section {
  position: relative;
  width: 50%;
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  background-color: #EBE6D5;
  color: #333;

  // Styling the scrollbar to be transparent
  &::-webkit-scrollbar {
    width: 0px; // Width of the scrollbar, making it non-visible
    background: transparent; // Transparent background
  }
}

.article-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-bottom: 20px;
}

.article-favorite-button-container {
  display: flex;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 14px;
  // background: #EBE6D5;
  position: absolute;
  top: 70px;
  right: 20px;
  cursor: pointer;
}

.article-favorite-share-button {
  color: var(--Charcoal, #000);
  font-family: "ABC Diatype";
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  background: #EBE6D5;
  padding: 4px 12px;
  border-radius: 14px;
  border: none;
  cursor: pointer;
}

.share-button,
.save-button {
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-size: 14px;
  text-transform: uppercase;
}

.share-button {
  background-color: #f0f0f0;
  color: #333;
}

.save-button {
  background-color: #333;
  color: white;
}

.article-content {
  flex: 1;
  height: 100%;
  padding-top: 4rem;

}

.article-paragraph {
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 20px;
  padding-bottom: 0rem;
  color: #333; // Ensure paragraph text is lighter than black
  &[data-type="bulleted_list_item"] {
    list-style-type: disc;
    display: list-item;
    margin-left: 20px;
  }

  &[data-type="numbered_list_item"] {
    list-style-type: decimal;
    display: list-item;
    margin-left: 20px;
  }
}

.mobile-article-content-section {
  width: 90%;
  margin-left: 1rem;
}

.article-image-container {
  margin-bottom: 20px;
}

.article-content-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 17px; // Added border radius
}

.article-image-caption {
  font-size: 14px;
  color: #666;
  text-align: center;
  margin-top: 10px;
}

.article-description {
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 20px;
}

.article-section-title {
  font-size: 24px;
  margin-bottom: 15px;
}

.article-link {
  color: #000;
  text-decoration: none;
  font-style: italic;
  transition: color 0.3s ease;
  font-weight: 600;

  &:hover {
    color: #666;
  }
}

@media (max-width: 768px) {

  .body,
  html {
    background-color: #F7F6E6;

    &::-webkit-scrollbar {
      width: 0px; // Width of the scrollbar, making it non-visible
      background: transparent; // Transparent background
    }
  }

  .mobile-article-detail-container,
  .mobile-article-image-section,
  .mobile-article-meta,
  .mobile-article-content-section,
  .mobile-article-image {
    width: 100svw;
    height: 80vh;
  }

  .mobile-article-detail-container {
    overflow-x: hidden;
    height: 100svh;
  }


  .mobile-article-title-detail {
    font-family: 'NB Grotesk Pro Mono';
    color: white;
    font-weight: 400;
    font-size: 26px;
    margin: 0;
    white-space: normal;
    width: 60%;
    margin-top: -10rem;
    order: 2;
    margin-left: 1rem;
  }

  .mobile-article-category {
    font-size: 9px;
    text-transform: uppercase;
    background-color: #ccb9a7;
    color: black;
    padding: 5px 10px;
    border-radius: 20px;
    display: inline-block;
    transform: translateY(-11rem);
    margin-left: 1rem;
  }

  .mobile-article-contentP {
    background-color: #F7F6E6;
  }

  .mobile-article-image {
    background-color: #F7F6E6;
    object-fit: cover;
  }

  .mobile-article-content {
    margin-top: 3rem;
    padding-bottom: 10rem;
    margin-right: 2rem;
    background-color: #F7F6E6; // Set background color for all mobile article components
    overflow-y: auto; // Ensure scrolling is enabled

    // Styling the scrollbar to be transparent
    &::-webkit-scrollbar {
      width: 0px; // Width of the scrollbar, making it non-visible
      background: transparent; // Transparent background
    }
  }
}