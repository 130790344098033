.favoriteArticleSection {
    
    width: 100%;
    height: auto; /* Allow the section to expand based on content */
    padding: 20px; /* Add padding for better spacing */
}

.favoriteArticleContainer {
    width: 100%;
    position: absolute;
    margin-top: 25px;
    top: 18%;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px; /* Add spacing below the header */
}

.favoriteArticleTitle {
    color: var(--Charcoal, #000);
    font-family: "ABC Diatype";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 16px */
    letter-spacing: -0.32px;
    text-transform: uppercase;
}

.favoriteArticleSeeAll {
    color: var(--Charcoal, #000);
    text-align: right;
    font-family: "ABC Diatype";
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%; /* 11px */
    letter-spacing: -0.1px;
    text-transform: uppercase;
    cursor: pointer; /* Make it look clickable */
}

.articleContainer {
    display: flex;
    gap: 20px; /* Space between articles */
    flex-wrap: wrap;
    justify-content: center; /* Center align articles */
    padding: 20px;
}
.articleContainer{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 30%;
    left: 0;
}

.articleCard {
    width: 176px;
    height: 250px;
    border-radius: 8px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.33) 100%), url(https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?q=80&w=1932&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D) lightgray 50% / cover no-repeat;
    background-size: cover; /* Ensure the image covers the card */
    background-position: center; /* Center the image */
    border-radius: 8px; /* Optional: Add rounded corners */
    overflow: hidden; /* Hide overflow to maintain the shape */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: Add a shadow for better appearance */
    display: flex;
    align-items: flex-end; /* Align title to the bottom */
    padding: 10px;
    color: white; /* Ensure text is visible on images */
    background-color: #000; /* Fallback background color */
}
.articleTitleContainer{
    position: relative;
    width: 80%;
    padding: 10px;
}
.articleTitle{

    color: #FFF;

font-family: "NB Grotesk Pro";
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: 110%; /* 14.3px */
letter-spacing: -0.13px;
align-self: stretch;
}
.articleDossiers{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 10px;
}
.articleDossier{
    display: inline-flex;
padding: 3px 5px;
justify-content: center;
align-items: center;
gap: 10px;
border-radius: 28px;
background: var(--WHITE, #F7F6E6);
}
.articleDossierText{
    color: var(--Charcoal, #000);
font-family: "ABC Diatype";
font-size: 8px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-transform: uppercase;
}

.noLikedArticles{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  
}
.noLikedArticlesTitle{
    color: #000;
font-family: "ABC Diatype";
font-size: 12px;
font-style: normal;
font-weight: 700;
line-height: 109%; /* 13.08px */
text-transform: uppercase;
}
.noLikedArticlesButtonContainer{
    display: flex;
padding: 10px 76px;
justify-content: center;
align-items: center;
gap: 10px;
align-self: stretch;
border-radius: 41px;
background: var(--Charcoal, #000);
width: 100%;
height: 35px;
}
.noLikedArticlesButton{
    color: var(--CTA-White, #F7F6E6);
text-align: center;
font-family: "ABC Diatype";
font-size: 10px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 200% */
text-transform: uppercase;
background-color: transparent;
border: none;
}