.generalButton {
  display: flex;
  height: 42px;
  padding: 0.625rem 1.25rem;
  justify-content: center;
  gap: 10px;
  border-radius: 41px;
  border: none;
  background: var(--CTA-Black, #000);
  flex: 1 0 0;
  color: var(--CTA-White, #f7f6e6);
  text-align: center;
  font-family: 'ABC Diatype';
  font-size: 0.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem; /* 200% */
  text-transform: uppercase;
  cursor: pointer;
  img {
    width: 1.34806rem;
    height: 1.34806rem;
  }
  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
    border: none;
    background: #cecece; // or any color you want for disabled state

    &:hover {
      opacity: 0.5; // Prevent hover effect when disabled
    }
  }
}

.switchMethodButton {
  display: flex;
  width: 2.625rem;
  height: 2.625rem;
  padding: 0.625rem 1.25rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border: none;
  border-radius: 2.5625rem;
  background: var(--Text-OFF-WHITE, #f7f6e6);
}

.howItWorksButton {
  color: #000;
  text-align: center;
  font-family: 'NB Grotesk Pro Mono';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 105%; /* 14.7px */
  letter-spacing: -0.28px;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-skip-ink: auto;
  text-decoration-thickness: auto;
  text-underline-offset: auto;
  text-underline-position: from-font;
  text-transform: capitalize;
  cursor: pointer;
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: var(--OLI-Off-White, #f7f6e6);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.modalContent {
  margin-right: 1rem;
  margin-left: 2rem;
  margin-top: 12rem;
}

.modalTitle {
  color: var(--Charcoal, #000);
  font-family: 'ABC Diatype';
  font-size: 31.745px;
  width: 193.476px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 31.745px */
  letter-spacing: -0.635px;
  text-transform: uppercase;
}

.stepsContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: auto;
  margin-top: 4rem;
}

.buttonWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;
}

.buttonContainer {
  width: 240px;
  justify-items: center;
}

.modalStep {
  color: #000;
  width: 342.466px;
  font-family: 'NB Grotesk Pro Mono';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 185%; /* 25.9px */
  letter-spacing: -0.28px;
  text-transform: capitalize;
}

// New styles for desktop modal backdrop
.modalWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 1001px) {
  .modalOverlay {
    position: relative;
    max-width: 83.59494rem;
    height: 41.97094rem;
    flex-shrink: 0;
    max-height: 90vh;
    border-radius: 16px;
    border: none;
    flex-direction: row;
    align-items: center;
    gap: 8rem;
    justify-content: center;
    overflow-y: auto;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
    padding: 3rem;
  }

  .blobWrapper {
    position: relative;
    width: 12.77044rem;
    height: 11.92125rem;
    flex-shrink: 0;
  }

  /* Inner blob that masks the image */
  .innerBlob {
    width: 100%;
    height: 100%;
    aspect-ratio: 208.8/243.31;
    position: relative;
    transform: translate(8%, 8%);
    mask: url('../../assets/icons/innerBlob.svg') no-repeat center / contain;
    -webkit-mask: url('../../assets/icons/innerBlob.svg') no-repeat center / contain;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 60% 0%;
      z-index: 1;
    }
  }
  .outerBlob {
    position: absolute;
    top: 0;
    left: 0;
    width: 14.71688rem;
    height: 13.73825rem;
    pointer-events: none;
  }

  .modalContent {
    display: flex;
    width: 37.15956rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 2.1875rem;
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
  }

  .modalTitle {
    line-height: 120%; /* 2.25rem */
    letter-spacing: -0.0375rem;
    font-size: 1.875rem;
    width: 100%;
  }

  .stepsContainer {
    gap: 0px;
    font-size: 0.875rem;
    line-height: 145%; /* 1.26875rem */
    letter-spacing: -0.0175rem;
    margin-top: 0;
  }
  .modalStep {
    width: 100%;
  }

  .buttonWrapper {
    margin-top: 0;
  }

  .closeButton {
    position: absolute;
    top: 1.29rem;
    right: 1.61rem;
    color: var(--Charcoal, #000);
    font-family: 'ABC Diatype';
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    cursor: pointer;
  }

  .switchMethodButton {
    width: auto;
    img {
      width: 20.016px;
      height: 19.999px;
    }
  }
}
