@import '../../../index.scss';

.menuOverlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-start;
  z-index: 9999;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s ease-in-out;

  &.visible {
    visibility: visible;
    opacity: 1;
    padding: 14px 20px;
  }
}

.menuContainer {
  position: absolute;
  display: flex;
  flex-direction: column;
  left: 20px;
  background-color: $color-default-background;
  padding: 15px 20px;
  z-index: 10000;
  opacity: 0;
  transform: scale(0);
  transform-origin: top right;
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
  height: 97svh;
  width: 42%;
  min-width: 650px;
  border-radius: 10px;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &.visible {
    opacity: 1;
    transform: scale(1);
  }
}

.menuContainer::-webkit-scrollbar {
  display: none;
}

.menuTopButtonContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.menuCloseButtonContainer {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-right: 0;

  @media (max-width: 1490px) {
    margin-right: 2.5%;
  }

  @media (max-width: 1470px) {
    margin-right: 4.8%;
  }

  @media (max-width: 1430px) {
    margin-right: 6.8%;
  }

  @media (max-width: 1410px) {
    margin-right: 7.8%;
  }

  @media (max-width: 1360px) {
    margin-right: 12%;
  }

  @media (max-width: 1330px) {
    margin-right: 12.8%;
  }

  @media (max-width: 1310px) {
    margin-right: 15.5%;
  }

  @media (max-width: 1280px) {
    margin-right: 16%;
  }

  @media (max-width: 1265px) {
    margin-right: 17.2%;
  }

  @media (max-width: 1250px) {
    margin-right: 18.8%;
  }

  @media (max-width: 1230px) {
    margin-right: 19.5%;
  }

  @media (max-width: 1200px) {
    margin-right: 21%;
  }

  @media (max-width: 1190px) {
    margin-right: 24%;
  }

  @media (max-width: 1170px) {
    margin-right: 25%;
  }

  @media (max-width: 1130px) {
    margin-right: 28%;
  }

  @media (max-width: 1100px) {
    margin-right: 29.5%;
  }

  @media (max-width: 1170px) {
    margin-right: 31%;
  }

  @media (max-width: 1150px) {
    margin-right: 31.5%;
  }

  @media (max-width: 1130px) {
    margin-right: 32%;
  }

  @media (max-width: 1000px) {
    margin-right: 34.5%;
  }

  @media (max-width: 985px) {
    margin-right: 37%;
  }

  @media (max-width: 950px) {
    margin-right: 38%;
  }

  @media (max-width: 930px) {
    margin-right: 39%;
  }

  @media (max-width: 900px) {
    margin-right: 42%;
  }

  @media (max-width: 880px) {
    margin-right: 43%;
  }

  @media (max-width: 850px) {
    margin-right: 44%;
  }

  @media (max-width: 830px) {
    margin-right: 45%;
  }

  @media (max-width: 800px) {
    margin-right: 47%;
  }

  @media (max-width: 780px) {
    margin-right: 48%;
  }

  @media (max-width: 767px) {
    margin-right: 0;
    /* Reset margin-right for mobile devices */
  }
}

.menuCloseButton {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.subMenuContainer {
  opacity: 0;
  overflow: hidden;
  animation: fadeIn 0.5s ease-in-out forwards;
}

.subSubMenuContainer {
  opacity: 0;
  overflow: hidden;
  animation: fadeIn 0.5s ease-in-out forwards;
}

.menuCloseButtonDot {
  margin-right: 8px;
  width: 15px;
  height: 15px;
}

.menuCloseButtonText {
  color: var(--Charcoal, #000);
  font-family: 'ABC Diatype';
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}

.menuLoginButtonText {
  color: var(--Charcoal, #000);
  font-family: 'ABC Diatype';
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  cursor: pointer;
  white-space: nowrap;
}

.menuSocialMediaContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.menuSocialMediaContainerItem {
  flex: 0;
  text-align: center;
  color: #000;
  font-family: 'ABC Diatype';
  font-size: 8px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  cursor: pointer;
}

.menuSocialMediaContainerItem:nth-child(2) {
  flex: 1;
  text-align: center;
}

.menuOptionsContainer {
  margin-top: 15%;
}

.menuOptionsItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #000;
  font-family: 'ABC Diatype';
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: 8px;
  cursor: pointer;
}

.menuOptionsItem li {
  flex-grow: 1;
}

.menuOptionsItem img {
  margin-left: 10px;
}

.menuSubOptionsItem {
  display: flex;
  align-items: center;
  color: #000;
  font-family: 'NB Grotesk Pro Mono';
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 8px;
  cursor: pointer;
}

.articleCard {
  background-size: cover;
  background-position: center;
  height: 206px;
  border-radius: 6px;
  margin-bottom: 1%;
  position: relative;
  overflow: hidden;
  padding: 12px;
  cursor: pointer;
}

.dossiersContainer {
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  overflow: hidden;
}

.dossiersBlock {
  border-radius: 120px;
  border: #f7f6e6;
  background: #f7f6e6;
  height: 100%;
  width: fit-content;
  padding: 3px 6px;
}

a {
  text-decoration: none;
}

.articleHeaderContainer {
  position: absolute;
  bottom: 12px;
  left: 12px;
  width: 100%;
}

.articleHeader {
  color: var(--OLI-Off-White, #f7f6e6);
  font-family: 'ABC Diatype';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%;
  letter-spacing: -0.14px;
  text-transform: uppercase;
}

.dossiersBlockText {
  text-decoration: none;
  color: var(--Charcoal, #000);
  font-family: 'ABC Diatype';
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}

.trendingContainer {
  text-align: right;
  margin-bottom: 10px;
}

.trendingContainerText {
  color: #000;
  font-family: 'ABC Diatype';
  font-size: 9px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}

@media (max-width: 768px) {
  .menuOverlay {
    &.visible {
      visibility: visible;
      opacity: 1;
      padding: 0px;
    }
  }

  .menuLoginButtonText {
    display: inline-block;
    margin-left: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .menuContainer {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    min-width: unset;
    border-radius: 0;
    padding: 20px;
    transition: none;
    transform: none;
    opacity: 1;
  }

  .dossiersBlockText {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
