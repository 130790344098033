@font-face {
  font-family: 'NB Grotesk Pro';
  src: url('../../../assets/fonts/ABCDiatype-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

.actives-list-container {
  display: flex;
  height: 100vh;
  font-family: 'ABC Diatype';
  overflow: hidden;
}

.actives-list__image {
  flex: 1;
  background-size: cover;
  background-position: center;
  position: relative;
  height: 100vh;
}

.actives-list__image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 40px;
  background: rgba(0, 0, 0, 0.2);
}

.actives-list__title {
  font-family: 'ABC Diatype';
  font-weight: 400;
  font-size: 24px;
  color: white;
  max-width: 50%;
  text-transform: uppercase;
}

.actives-list__article-count {
  font-size: 12px;
  color: white;
}

.actives-list__articles-content {
  flex: 1;
  background-color: #ebe6d5;
  overflow-y: auto;
  height: 100vh;
}

.actives-list__articles-section {
  position: relative;
  padding-top: 5rem;
  padding-left: 10px;
  padding-right: 10px;

  &--no-top-padding {
    padding-top: 0;
  }

  .actives-list__rectangle {
    top: 3rem;
    left: 10px;
    width: calc(100% - 30px);
    background-color: black;
    display: flex;
    flex-direction: column;
    padding: 0.2rem;
    z-index: 1;
    border-radius: 4px;
    margin: 0 10px;

    .actives-list__rectangle-content {
      display: flex;
      align-items: center;
    }

    .actives-list__rectangle-text-container {
      display: flex;
      flex-direction: column;
      margin-left: 0.25rem;
    }

    .actives-list__rectangle-text {
      font-family: 'ABC Diatype';
      font-size: 10px;
      color: #f7f6e6;
      text-align: left;
    }

    .actives-list__rectangle-image {
      width: 60px;
      height: auto;
    }
  }

  .actives-list__articles-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    padding: 10px;
    position: relative;
    z-index: 0;
  }
}

.actives-list__articles-heading {
  font-size: 24px;
  margin: 40px 0 20px;
}

.actives-list__article-item {
  text-decoration: none;
  color: inherit;
  border-radius: 6px;
  overflow: hidden;
  transition: transform 0.3s ease;

  &.actives-list__top-viewer {
    grid-column: 1 / -1;
    overflow: visible;

    .actives-list__article-image {
      border-radius: 10px;
      overflow: hidden;
    }
  }
}

.actives-list__article-image {
  width: 100%;
  height: 360px;
  background-size: cover;
  background-position: center;
  position: relative;

  .actives-list__top-viewer & {
    height: 650px;
  }
}

.actives-list__article-info {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px 15px;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0));
  color: white;
}

.actives-list__article-title {
  font-family: 'ABC Diatype';
  font-size: 14px;
  font-weight: 700;
  line-height: 15.4px;
}

.actives-list__article-subtitle {
  font-family: 'NB Grotesk Pro Mono';
  font-size: 10px;
  margin: 0 0 10px 0;
  font-weight: 400;
  line-height: 8px;
}

.actives-list__article-category {
  font-size: 12px;
  text-transform: uppercase;
  background-color: rgba(204, 185, 167, 0.7);
  color: white;
  padding: 3px 8px;
  border-radius: 20px;
  display: inline-block;
}

.actives-list__bottom-text {
  position: absolute;
  bottom: 20px;
  left: 20px;
  right: 20px;
  color: white;
  font-family: 'ABC Diatype';
  font-size: 12px;
  text-align: left;
  z-index: 2;
  text-transform: uppercase;
  line-height: 1.5;
  font-weight: 400;
}

@media screen and (max-width: 768px) {
  .actives-list-container {
    flex-direction: column;
    height: inherit;
  }

  .actives-list__image {
    padding: 20px;
    height: 80vh;
    flex: inherit;
  }

  .actives-list__article-image {
    width: 100%;
    height: 300px;
    background-size: cover;
    background-position: center;
    position: relative;

    .actives-list__top-viewer & {
      height: 650px;
    }
  }

  .actives-list__title {
    font-size: 26px;
    max-width: 70%;
    font-weight: 800;
  }

  .actives-list__bottom-text {
    font-size: 12px;
    text-align: center;
  }

  .actives-list__rectangle-content {
    text-align: center;
  }

  .actives-list__articles-section {
    padding-top: 1rem;

    .actives-list__articles-grid {
      grid-template-columns: repeat(2, 1fr);
      gap: 5px;
    }
    .actives-list__rectangle {
      width: inherit;
    }
  }

  .actives-list__content-wrapper {
    padding: 10px;
  }

  .actives-list__image-overlay {
    flex-direction: column;
    justify-content: center;
    gap: 25px;
    .actives-list__article-count {
      font-size: 10px;
    }
  }
}
