.textHeadSection {
  width: 100%;
  height: 100%;
}

/*zero index*/
.middleContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100svh;
}

.indexZeroTitle {
  color: var(--Text-OFF-WHITE, #f7f6e6);
  font-family: 'ABC Diatype';
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 110.457%;
  /* 39.765px */
  text-transform: uppercase;
  text-align: center;
}

.indexZeroTextContainer {
  width: 55vw;
  text-align: center;
  padding-top: 26px;
}

.indexZeroText {
  color: var(--OLI-Off-White, #f7f6e6);
  text-align: center;

  /* Subtle drop shadow */
  text-shadow: 0px 0px 100px #000;
  font-family: 'NB Grotesk Pro Mono';
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 110.457%;
  /* 19.882px */
}
.buttonLocationIndexZero {
}
.weekContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
}
/*one index*/
.middleContainerOne {
  display: flex;
  align-items: center;
  /* Ensures all items are aligned vertically in the center */
  justify-content: space-between;
  /* Spreads the left, center, and right items across the container */
  width: 100%;
}

.indexOneTitle {
  flex: 1;
  /* Allows the title to occupy its natural width */
  text-align: center;
  /* Centers the title text */
  color: var(--OLI-Off-White, #f7f6e6);
  font-family: 'NB Grotesk Pro Mono';
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 110.457%;
}

.indexOneTitleImage {
  flex-shrink: 0;
  /* Prevents the image from shrinking */
  margin: 0 16px;
  /* Adds some spacing between the title and the right text */
  height: auto;
  max-height: 80px;
  /* Adjust as needed to match visual proportions */
}

.indexOneTitleRight {
  flex: 1;
  /* Allows the right text to occupy its natural width */
  text-align: center;
  /* Aligns the text to the right */
  color: var(--OLI-Off-White, #f7f6e6);
  text-align: center;

  font-family: 'NB Grotesk Pro Mono';
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 110.457%;
}

/*two index*/
.middleContainerTwo {
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  height: 100%;
  width: 445px;
  margin-left: 250.1px;
}

.indexTwoTextContainer {
  padding-top: 26px;
}

.indexTwoTitleContainer {
  width: 300px;
}

.titleIndexTwo {
  color: var(--Text-OFF-WHITE, #f7f6e6);

  font-family: 'ABC Diatype';
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  /* 40px */
  letter-spacing: -0.4px;
  text-transform: uppercase;
  align-self: stretch;
}
.titleIndexTwoShop {
  color: var(--Text-OFF-WHITE, #f7f6e6);

  font-family: 'ABC Diatype';
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  /* 40px */
  letter-spacing: -0.4px;
  text-transform: uppercase;
  align-self: stretch;
}

.indexTwoText {
  color: var(--Text-OFF-WHITE, #f7f6e6);

  font-family: 'NB Grotesk Pro Mono';
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
  /* 22px */
}
.indexTwoTextShop {
  color: var(--Text-OFF-WHITE, #f7f6e6);

  font-family: 'NB Grotesk Pro Mono';
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
}

/*edit section*****************/

.editTitle {
  color: var(--OLI-Off-White, #f7f6e6);
  text-align: center;
  /* Subtle drop shadow */
  text-shadow: 0px 0px 100px #000;
  font-family: 'ABC Diatype';
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 40px */
  text-transform: uppercase;
}
.editTextContainer {
  width: 511px;
  text-align: center;
  padding-top: 26px;
}
.editText {
  color: var(--OLI-Off-White, #f7f6e6);
  text-shadow: 1px 2px 2px rgba(0, 0, 0, 0.5);

  /* Subtle drop shadow */
  font-family: 'NB Grotesk Pro Mono';
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 110.457%; /* 22.091px */
}

/*button*/

.buttonContainer {
  padding-top: 36px;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.button {
  color: var(--Text-OFF-WHITE, #f7f6e6);
  text-align: center;
  font-family: 'ABC Diatype';
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  border-radius: 26px;
  padding: 8px 34px;

  background: var(--Text-CHARCOAL, #000);
  border: none;
  cursor: pointer;
}

.scrollDownSection {
  position: absolute;
  bottom: -30px;

  .imageWrapper {
    position: relative; // allows absolutely positioning the text
    display: inline-block;
  }

  .scrollDownVector {
    // image styling, e.g. width, height, etc.
    display: block;
    width: 87.834px;
    height: 81.879px;
    // width: 100px;
    // height: auto;
  }

  .overlayText {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    color: #fff;
    text-align: center;
    font-family: 'NB Grotesk Pro Mono';
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 110.457%; /* 11.046px */
    // text-shadow: 1px 1px 2px rgba(0,0,0,0.7);
  }
}
.buttonLocation {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.buttonLocationWeek {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 440px) {
    position: relative;
    top: 13%;
  }
}
.lastImageVector {
  display: flex;
  width: 100%;
  justify-content: center;
}
@media (max-width: 440px) {
  .indexZeroTitle {
    width: 296px;
  }

  .indexZeroTextContainer {
    width: 296px;
  }

  .indexZeroText {
    color: var(--OLI-Off-White, #f7f6e6);
    text-align: center;

    /* Subtle drop shadow */
    text-shadow: 0px 0px 100px #000;
    font-family: 'NB Grotesk Pro Mono';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 110.457%;
  }
  .middleContainerOne {
    flex-direction: column;
  }
  .indexOneTitle {
    transform: translateY(3svh);
    font-size: 14px;
  }
  .indexOneTitleImage {
    margin-top: 90px;
  }
  .indexOneTitleRight {
    transform: translateY(10vh);
    font-size: 14px;
  }

  .middleContainerTwo {
    width: 100%;
    margin-left: 30px;
  }

  .indexTwoText {
    font-size: 14px;
  }
  .buttonLocation {
    /* Keep existing properties but also add: */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .middleContainerOne {
    flex-direction: column;
  }

  .indexTwoTitleContainer {
    text-align: center;
    width: 323px;
  }

  .middleContainerTwo {
    width: 100%;
    margin-left: 30px;
  }

  .indexTwoTextContainer {
    width: 323px;
    text-align: center;
  }
  .editTextContainer {
    width: 323px;
  }
  .editText {
    font-size: 14px;
  }
}
