@font-face {
  font-family: 'ABC Diatype';
  src: url('../../../assets/fonts/ABCDiatype-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

.carouselContainer {
  padding-top: 10px;
  overflow: hidden;
  width: 100%;
  // padding-left: 30px;
  // border: 10px solid red;

}

.combinationsContainer {
  margin-left: 20px;
}

.combinationsTitle {
  color: #000;
  font-family: "ABC Diatype";
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%;
  letter-spacing: -0.1px;
  text-transform: uppercase;
}

.goodActiveItemContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  border-radius: 4px;
  background: var(--Beige, #CCB9A7);
  margin-bottom: 7px;
  padding: 17px 12px;
}

.badActiveItemContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  border-radius: 4px;
  background: var(--Beige, #000);
  margin-bottom: 7px;
  padding: 17px 12px;
}

.badActiveItem {
  color: white;
  text-align: center;
  font-family: "ABC Diatype";
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 116%;
  letter-spacing: -0.1px;
  text-transform: uppercase;
  cursor: pointer;
}

.goodActiveItem {
  color: #000;
  text-align: center;
  font-family: "ABC Diatype";
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 116%;
  letter-spacing: -0.1px;
  text-transform: uppercase;
  cursor: pointer;
}

.mainActiveGoodText {
  color: #000;
  font-family: "NB Grotesk Pro Mono";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 105%;
  letter-spacing: -0.24px;
  text-transform: capitalize;
}

.mainActiveBadText {
  color: white;
  font-family: "NB Grotesk Pro Mono";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 105%;
  letter-spacing: -0.24px;
  text-transform: capitalize;
}

.blackDotImg {
  margin-right: 16px;
  width: 6px;
  height: 6px;
}

.article-detail-container {
  display: flex;
  height: 100vh;
  font-family: 'Arial', sans-serif;
  position: relative;
}

.back-button {
  position: absolute;
  top: 40px;
  left: 20px;
  z-index: 3;
  background-color: rgba(255, 255, 255, 0.2);
  border: none;
  border-radius: 50px;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: rgba(255, 255, 255, 0.5);
  }
}

.article-image-section {
  width: 50%;
  position: relative;
  overflow: hidden;
  height: 100vh;
}

.article-image {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.article-image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 1;
  pointer-events: none;
}

.article-meta {
  padding: 0px 56px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  z-index: 2;
  position: relative;
  color: white;
}

.article-header {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

.article-category {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-bottom: 10px;
  background-color: transparent;
}

.article-title {
  font-family: 'NB Grotesk Pro Mono';
  font-weight: 400;
  font-size: 26px;
  margin: 0;
  white-space: nowrap;
}

.article-content-section {
  position: relative;
  width: 50%;
  padding: 50px 20px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #f7f6e6;
  color: #333;

  .article-paragraph:last-child {
    padding-bottom: 75px;
  }
}

.article-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-bottom: 20px;
}

.share-button,
.save-button {
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-size: 14px;
  text-transform: uppercase;
}

.share-button {
  background-color: #ebe6d5;
  color: black;
}

.save-button {
  background-color: #ebe6d5;
  color: black;
}

.article-middle-text {
  font-family: 'ABC Diatype';
  text-align: center;
  margin: 300px 0;
  text-transform: uppercase;
  font-size: 12px;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.active-desc-header {
  font-family: 'ABC Diatype';
  margin-bottom: 10px;
}

.active-desc {
  font-family: ABC Diatype;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: -0.01em;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  width: 80%;
}

.article-content {
  flex: 1;
  margin-bottom: 30px;
}

.article-paragraph {
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 20px;
  color: #333;
}

.article-image-container {
  margin-bottom: 20px;
}

.article-content-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 17px;
}

.article-image-caption {
  font-size: 14px;
  color: #666;
  text-align: center;
  margin-top: 10px;
}

.article-description {
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 20px;
}

.article-section-title {
  font-size: 24px;
  margin-bottom: 15px;
}

.article-link {
  color: #000;
  text-decoration: none;
  font-style: italic;
  transition: color 0.3s ease;
  font-weight: 600;

  &:hover {
    color: #666;
  }
}

.function-badge {
  background-color: #f7f6e6;
  color: #000;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 10px;
  text-transform: uppercase;
  margin: 0;
  display: inline-block;
}

.article-subtitle {
  font-size: 18px;
  margin-top: 5px;
  color: #ebe6d5;
  font-weight: 300;
}

.menu-container {
  display: flex;
  flex-direction: column;
  position: relative;
  left: 20px;
  bottom: 10px;
  height: auto;
  width: 100%;
  min-height: 20svh;
  max-height: 20svh;
  justify-content: flex-start;
  overflow: visible;
}

.menu-item {
  padding: 5px 0;
  cursor: pointer;
  text-align: left;
  text-transform: uppercase;
  font-weight: bold;
  font-family: 'ABC Diatype';
  font-size: 12px;
  font-weight: 700;
  line-height: 12px;
  letter-spacing: -0.02em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin-bottom: 5px;
  position: relative;
}

.menu-number {
  font-family: 'NB Grotesk Pro Mono';
  margin-right: 5px;
  font-weight: normal;
}

.menu-plus {
  font-size: 20px;
  font-weight: normal;
  margin-left: 5px;
}

.menu-item-text-section {
  font-family: ABC Diatype;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.18px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  width: 90%;
  margin-bottom: 80px;
  position: relative;

  &:has(.active-related-products) {
    padding: 0;
    width: 100%;
    height: auto;
    display: block;
    clear: both;
    overflow: visible;
  }
}

.no-products-message {
  text-align: center;
  padding: 40px 20px;
  font-family: 'ABC Diatype', sans-serif;

  p {
    margin: 10px 0;
    font-size: 14px;
    line-height: 1.4;
  }

  .bestsellers-link {
    color: inherit;
    text-decoration: underline;

    &:hover {
      opacity: 0.8;
    }
  }
}

.loaderContainer {
  background-color: #f7f6e6;
  width: 100%;
  height: 100%;
}

.loaderContainer_animation {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

// mobile screens

@media screen and (max-width: 768px) {
  .article-detail-container {
    flex-direction: column;
    width: 100%;
    height: 100%;
  }

  .article-header {
    flex-direction: column;
    height: 40vh;
  }

  .active-desc {
    width: 100%;
    padding: 0 18px;
  }

  .article-image-section {
    flex-direction: column;
    width: 100%;
    overflow: inherit;
    height: 80vh;
  }

  .article-content-section {
    flex-direction: column;
    width: 100%;
    overflow: inherit;
    padding: 40px 10px;
  }

  .article-middle-text {
    flex-direction: column;
    margin: 0;
    margin-bottom: 40px;
  }

  .menu-container {
    position: static;
    padding: 0;
    width: 100%;
    left: 0;
    bottom: 0;
  }

  .menu-item {
    margin-bottom: 20px;
  }

  .menu-number {
    font-size: 9px;
  }

  .menu-item-text-section {
    width: 100%;
    margin-bottom: 30px;
    padding: 0 18px;
  }

  .article-meta {
    flex-direction: column;
    justify-content: flex-end;
  }

  .article-category {
    margin-bottom: 20px;
  }

  .article-title {
    font-size: 24px;
  }

  .function-badge {
    margin: 2px;
  }

  .no-products-message {
    text-align: center;
    padding: 40px 20px;
    font-family: 'ABC Diatype', sans-serif;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  .article-favorite-button-container {
    z-index: 2;
  }
}