/* 
  1. Outer wrapper
  2. Section scrollbar styling
  3. Subcategory container
  4. Subcategories title
  5. Row container (scrollable)
  6. Row scrollbar styling
  7. Subcategories grid
  8. Round container
  9. Image
  10. Text container
  11. Text
*/

.subCategoriesSection {
    display: flex;
    width: 100%;
    height: 360px;
    padding: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    flex-shrink: 0;
}

.subCategoriesSection::-webkit-scrollbar {
    display: none;
}

.subCategory {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    width: 100%;
}

.subCategoriesTitle {
    color: var(--Charcoal, #000);
    font-family: "ABC Diatype";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 104%;
    text-transform: uppercase;
}

.rowCategories {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1%;
    width: 100%;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    scrollbar-width: none;


}

.rowCategories::-webkit-scrollbar {
    display: none;
}


.subCategoriesGrid {
    height: 100%;
}


.subCategoriesRoundContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* horizontally center items */
    justify-content: flex-start;
    /* we can adjust if needed */
    cursor: pointer;
    position: relative;
    height: 100%;
    /* optional if you need absolute positioning */
}

.subCategoriesTextContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    padding-top: 10px;
    margin-top: auto;
}

.subCategoriesImage {
    width: 117px;
    height: 117px;
    object-fit: cover;
    display: block;
    border-radius: 50%;
    flex-shrink: 0;
    margin: 0 auto;
    // clip-path: ellipse(60% 50% at 43% 50%);
    clip-path: ellipse(55% 50% at 45% 50%);
    transform: scaleX(1.05);
}


.subCategoriesTextContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    /* or 'space-between' if you prefer */
    padding-top: 10px;
    margin-top: auto;
    /* This helps push it to the bottom in a flex parent */
}

.subCategoriesText {
    color: var(--Charcoal, #000);
    font-family: "ABC Diatype";
    font-size: 10%;
    font-style: normal;
    font-weight: 700;
    line-height: 104%;
    text-transform: uppercase;
}

@media (max-width: 448px) {
    .swiper-slide {
        width: 100px; // Full width for each slide
        height: 100%; // Full height for each slide
        display: flex; // Displays each slide as a flex container
        justify-content: center; // Centers the slide vertically
        align-items: center; // Centers the slide horizontally
        overflow: hidden; // Hides overflowing content

        background: var(--Off-White, #F7F6E6);
    }

    .mobilegrid {
        display: flex;
        flex-direction: column; // Ensures items are stacked vertically

        width: 200px; // Full width to use available space

    }

    .subCategoriesSection {
        display: flex;
        width: 100%;
        height: 260px;
        padding: 10px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
        flex-shrink: 0;
    }

    .mobileSubCategoriesRoundContainer {
        width: 100%; // Full width to ensure touch targets are large enough
        margin-bottom: 10px; // Adds space between items
    }

    .mobileImageContainer {
        width: 150px; // Full width to ensure images are responsive
        height: 150px; // Height auto for maintaining aspect ratio

    }

    .subCategoriesTitle {
        color: var(--Charcoal, #000);

        font-family: "ABC Diatype";
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 104%;
        /* 20.8px */
        text-transform: uppercase;
    }

    .subCategoriesImage {
        width: 127.15px;
        height: 122.019px;
        flex-shrink: 0;
        border-radius: 1000px;

    }

    .subCategoriesTextContainer {
        text-align: center; // Centers text within the container
    }

    .productsCarouselSliderBlock {
        width: 370px;
    }

    .subCategoriesText {
        color: var(--Charcoal, #000);

        font-family: "ABC Diatype";
        font-size: 11.188px;
        font-style: normal;
        font-weight: 700;
        line-height: 104%;
        /* 11.636px */
        text-transform: uppercase;
    }
}