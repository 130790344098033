@import '../../../index.scss';

.productsCarouselSliderBlock {
    cursor: url('../../../assets/cursors/dragCursor.svg'), auto;
    padding: 5px;

    .slick-slide {
        text-align: center;
        margin: 0 10px;
    }

    a {
        cursor: pointer;
    }
}

.productContainer {
    width: 283px;
    height: 420px;
    flex-shrink: 0;
    display: flex !important;
    flex-direction: column;
    align-items: center;
    background-color: #f0edde;
    border-radius: 8px;
    position: relative;
    text-decoration: none;
    color: inherit;
    cursor: url('../../../assets/cursors/dragCursor.svg'), auto;
    padding: 10px;

    &:hover,
    &:focus,
    &:active {
        text-decoration: none;
    }
}

.clickableImage {
    width: 252.561px;
    height: 327px;
    flex-shrink: 0;
    padding: 10px;
}

.carouselImage {
    width: 252.561px;
    height: 327px;
    object-fit: cover;
    object-position: center;
    transition: opacity 0.3s ease;
}

.productInfo{
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 100%;
    margin-top: 17px;
  }

  .productBrand {
    color: var(--Charcoal, #000);
    font-family: "ABC Diatype";
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
    letter-spacing: -0.12px;
    text-transform: uppercase;
    padding-bottom: 6px;
  }
  
  .productTitle {
    color: var(--OLI-PRIMARY-BLACK, #000);
  
    font-family: "NB Grotesk Pro Mono";
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 13px */
    letter-spacing: -0.26px;
    text-transform: capitalize;
  }
  .productPrice{
    padding-top: 5px;
    color: var(--Black, #000);
    font-family: "NB Grotesk Pro Mono";
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 14px */
    letter-spacing: -0.28px;
    text-transform: capitalize;
    padding-bottom: 5px;
    @media (max-width: 768px) {
      text-align: left;
      width: 100%;
    }
  }

  .justDroppedContainer{
    position: absolute;
    top: 10px;
    left: 10px;
  }
  .justDroppedText{
    color: var(--Backgrounds-CERAMICS, #5D5246);
  font-family: "ABC Diatype";
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  }

