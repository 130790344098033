.security-container {
  margin-top: 20px;
  margin-bottom: 4px;
  //border: 1px solid red;
}

.header-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1%;
}

.security-title {
  font-family: 'ABC Diatype', sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: -0.02em;
  text-align: left;
  text-transform: uppercase;
  margin-bottom: 5px;
}

.btn-change {
  font-family: 'SF Pro Display', sans-serif;
  font-size: 14px;
  font-weight: 400;
  border: none;
  cursor: pointer;
  background-color: transparent;
  margin-top: -5px;
  text-decoration: none;
  color: black;
}

.divider {
  height: 1px;
  background-color: rgba(0, 0, 0, 0.07);
  margin-top: 5px;
  margin-bottom: 2%;
}

.password-section {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.password-label {
  font-family: 'ABC Diatype', sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 15.26px;
  text-align: left;
  margin-right: 10px;
}

.password-mask {
  font-family: 'ABC Diatype', sans-serif;
  font-size: 14px;
  color: #5d5246;
  font-weight: 400;
  margin-left: auto;
}

@media screen and (max-width: 768px) {
  .security-container {
    width: 85vw;
    margin-top: 3rem;
  }
}