.favoriteProductContainer {
    position: absolute;
    top: 0;
    left: 0;
    gap: 20px;
    align-items: start;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
}

.favoriteProductTitle {
    color: var(--Charcoal, #000);
    font-family: "ABC Diatype";
    font-size: 16px;
    font-weight: 700;
    line-height: 1.2;
    letter-spacing: -0.32px;
    text-transform: uppercase;
    margin-bottom: 10px;
}

.favoriteProductSeeAll {
    color: var(--Charcoal, #000);
    text-align: right;
    font-family: "ABC Diatype";
    font-size: 10px;
    font-weight: 700;
    line-height: 1.1;
    letter-spacing: -0.1px;
    text-transform: uppercase;
    cursor: pointer;
}

.favoriteProductImagesContainer {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 5px;
    width: 100%;
    margin-top: 25px;
}

.favoriteProductCard {
    text-align: center;
    position: relative;
    margin: 0 auto;
    width: 100%;
    height: auto;
    background-color: #DBD3C1;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    transition: transform 0.3s;
}

.favoriteProductCardImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.favoriteProductCard.large {
    grid-column: 1;
    grid-row: 1 / span 2;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    height: 50svh;
}

.favoriteProductCard.small {
    grid-column: 2;
    grid-row: span 1;
    height: auto;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
}
.noLikedArticles{
    position: absolute;
    top: 80%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
}
.noLikedArticlesImage{
    width: 100%;
    height: 141px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    border-radius: 4px;
}

.backwidth{
    width: 100%;
    border-radius: 4px;
    height: 66px;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    box-sizing: border-box;
    position: absolute;
    top: 70%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.noLikedArticlesText {
    color: var(--Off-White, #F7F6E6);
    width: 100%; /* Ensure it fits inside the parent container */
    font-family: "ABC Diatype";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%; /* 13.2px */
    letter-spacing: -0.12px;
    text-align: center; /* Center align the text */
    background-color:black;
}