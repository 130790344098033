.desktopContainer {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-image: url('../../../../../assets/images/headSectionBackground.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.topSection {
    display: flex;
    height: 33vh;
    width: 100%;
}

.menuTopSection {
    width: 33%;
    background-color: transparent;
}

.contentTopSection {
    flex-grow: 1;
    padding: 2% 15% 3% 2%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.bottomSection {
    display: flex;
    flex-grow: 1;
    width: 100%;
    background-color: #F7F6E6;
    overflow: hidden;
    border-radius: 14px 14px 0 0;
}

.menuSection {
    width: 33%;
    padding: 3% 5% 5% 10%;
}

.contentSection {
    flex-grow: 1;
    padding: 2% 15% 2% 2%;
}

.userName {
    color: #FFF;
    font-family: "ABC Diatype";
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    letter-spacing: -0.52px;
    text-transform: uppercase;
    margin: 0;
}

.passwordBackButton {
    color: var(--Charcoal, #000);
    font-family: "ABC Diatype";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-transform: uppercase;
    background: none;
    border: none;
    cursor: pointer;
    margin-bottom: 30px;
}

.formHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--Charcoal, #000);
    font-family: "ABC Diatype";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 109%;
    text-transform: uppercase;
    margin-bottom: 18px;
}

.passwordForm {
    display: flex;
    flex-direction: column;

    .passwordField {
        display: flex;
        align-items: center;
        position: relative;
        margin-bottom: 12px;
        width: 50%;

        input {
            flex: 1;
            margin-bottom: 20px;
            padding: 10px 0;
            border: none;
            border-bottom: 1px solid #7E7063;
            outline: none;
            background: transparent;
            font-family: 'ABC Diatype', sans-serif;
            font-size: 10px;
            font-weight: 400;
            line-height: 11px;
            text-align: left;
        }

        .toggleVisibility {
            position: absolute;
            right: 0px;
            top: 10px;
            background: transparent;
            border: none;
            font-family: 'ABC Diatype', sans-serif;
            font-size: 10px;
            cursor: pointer;
        }
    }

    .passwordAdvice {
        display: flex;
        flex-direction: column;
        margin-bottom: 15px;

        h5 {
            font-family: 'ABC Diatype', sans-serif;
            font-size: 12px;
            font-weight: 700;
            margin-bottom: 8px;
            text-transform: uppercase;
        }

        span {
            font-family: 'ABC Diatype', sans-serif;
            font-size: 10px;
            font-weight: 400;
            margin-bottom: 4px;
            text-transform: uppercase;
        }
    }

    .submitButton {
        border-radius: 41px;
        background: var(--Charcoal, #000);
        color: #F7F6E6;
        padding: 10px 30px;
        border: none;
        cursor: pointer;
        width: 30%
    }
}

.forgotPasswordBtn {
    color: var(--Charcoal, #000);
    text-align: center;
    font-family: "ABC Diatype";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-transform: uppercase;
    background: none;
    border: none;
    cursor: pointer;
}


.errorMessage {
    color: #990202;
    font-size: 12px;
    // margin-top: 5px;
    margin-bottom: 10px;
    text-align: left;
}

.mobileContainer {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: #F7F6E6;
    padding: 10% 14px;
}

.mobileHeaderSection {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
    margin-bottom: 13px;
}

.mobileTitlesSection {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px 0;
    gap: 30px;
}


.mobileTitle {
    color: var(--Charcoal, #000);
    font-family: "ABC Diatype";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 104%;
    text-transform: uppercase;
}

.mobileSubTitle {
    color: var(--Charcoal, #000);
    font-family: "ABC Diatype";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 109%;
    text-transform: uppercase;
    margin-bottom: 20px;
}

.mobileText {
    color: var(--Ceramic, #5D5246);
    font-family: "SF Pro Display";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 141%;
}

.mobileChangeBtn {
    color: var(--Charcoal, #000);
    text-align: right;
    font-family: "ABC Diatype";
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    text-transform: uppercase;
    background: none;
    border: none;
}

.mobileForgotPasswordContainer {
    position: absolute;
    bottom: 5%;
    left: 50%;
    transform: translateX(-50%);
}


@media (max-width: 768px) {
    .passwordForm {
        padding-right: 33px;

        .passwordField {
            width: 100%;
        }

        .submitButton {
            border-radius: 41px;
            background: var(--Charcoal, #000);
            color: #F7F6E6;
            padding: 10px 30px;
            border: none;
            cursor: pointer;
            text-transform: uppercase;
            width: 100%;
        }

        .cancelBtn {
            background: transparent;
            color: var(--Charcoal, #000);
            font-family: "ABC Diatype";
            font-size: 10px;
            font-weight: 400;
            line-height: 20px;
            text-align: center;
            text-transform: uppercase;
            border: none;
            cursor: pointer;
            width: 100%;
        }
    }

    .btnsContainer {
        position: fixed;
        bottom: 5%;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 90%;
    }
}