@import '../../index.scss';

.largeContainer {
    background: linear-gradient(157deg, #5D5246 20%, #CCB9A7 97%);
}

.saferIngredientsContainer {
    min-height: 100dvh;
    display: flex;
    justify-content: space-between;
    padding: 20px;

    @media (max-width: 1024px) {
        flex-direction: column;
        align-items: center;
        padding: 40px 20px;
    }

    @media (max-width: 768px) {
        height: 100vh;
        padding: 20px;
    }

    .containerBottom {
        // margin-top: 511px;
        margin-top: 60px;

        @media (max-width: 768px) {
            margin-top: 160px;
        }
    }
}

.saferIngredientsDot {
    @media (max-width: 768px) {
        transform: translateX(70%);
    }
}

.saferIngredientsHeaderDescription {
    @include ABCDiatype-Bold(12);
    text-transform: uppercase;
    color: $color-white;
    margin-bottom: 50px;

    @media (max-width: 768px) {
        font-size: 14px;
        width: 95vw;
    }
}

.saferIngredientsHeaderHeader {
    @include ABCDiatype-Bold(40);
    text-transform: uppercase;
    color: $color-white;
    max-width: 500px;
    line-height: 1;

    @media (max-width: 1024px) {
        font-size: 35px;
        max-width: 100%;
    }

    @media (max-width: 768px) {
        font-size: 28px;
    }
}

.textContent {
    margin-top: 50px;

    @media (max-width: 768px) {
        margin-top: 0px;
    }
}

.saferIngredientsHeaderSVG {
    height: 33px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    @media (max-width: 1024px) {
        width: 350px;
        height: auto;
    }

    @media (max-width: 768px) {
        width: 300px;
        height: auto;
    }
}

.saferIngredientsDescription {
    @include ABCDiatype-Bold(14);
    text-transform: uppercase;
    max-width: 460px;
    color: $color-white;

    @media (max-width: 1024px) {
        font-size: 12px;
    }

    @media (max-width: 768px) {
        font-size: 16px;
        text-align: start;
        transform: translateX(2%);
    }
}

.saferIngredientsButton {
    background-color: #000;
    color: #fff;
    border-radius: 200px;
    height: 52px;
    width: 210px;
    font-size: 12px;
    text-transform: uppercase;
    font-family: 'ABC Diatype';
    border: none;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;

    @media (max-width: 768px) {
        font-size: 12px;
        width: 150px;
        height: 40px;
        margin-bottom: 10px;
    }
}

.saferIngredientsContainerIMG {
    height: 95vh;
    width: 695px;
    background-size: cover;
    border-radius: 10px;
    background-repeat: no-repeat;
    align-self: center;
    margin-right: 20px;

    @media (max-width: 1024px) {
        width: 100%;
        height: 500px;
        margin-right: 0;
        margin-top: 20px;
    }

    @media (max-width: 768px) {
        height: 95vh;
    }
}