.container {
    position: relative;
    width: 50px;
    font-family: "ABC Diatype", sans-serif;
    color: var(--Backgrounds-CERAMICS, #5D5246);
    
    z-index: 10;
    @media screen and (min-width: 768px) {
      position: absolute;
    bottom: 10px;
    left: 49%;
  }}
  
  .languageOptions {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    transition: transform 0.21s ease;
    position: absolute;
    width: 100%;
    background-color: transparent;
  }
  
  .languageButton {
    display: grid;
    grid-template-columns: 13px auto 10px; // Reserve fixed space for icons
    align-items: center;
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    font-family: "ABC Diatype", sans-serif;
    font-size: 10px;
    font-weight: 700;
    color: inherit;
    width: 100%;
  }
  
  .languageButton .worldIcon {
    grid-column: 1;
    width: 13px;
    height: 13px;
    flex-shrink: 0;
  }
  
  .languageButton .arrowIcon {
    grid-column: 3;
    width: 8px;
    height: 8px;
    margin-left: -2px;
    margin-top: 1px;
  }
  
  .languageButton span {
    grid-column: 2; // Always place the text in the middle column
    text-transform: uppercase;
    line-height: 110.457%;
  }
  
  /* Initially hide non-selected options */
  .languageButton:not(:first-child) {
    display: none;
    opacity: 0;
  }
  
  // When open, show all buttons and animate container
  .container.open {
    .languageOptions {
      transform: translateY(-16px);
      background-color: #F7F6E6;
      transition: transform 0.21s ease, background-color 0.21s ease 0.21s;
    }
  
    .languageButton:not(:first-child) {
      display: grid; // Ensure grid layout is applied
  animation: fadeIn 0.3s ease forwards;
    }

    .languageButton:not(:first-child)::before {
      content: "";
      display: inline-block;
      width: 19px; /* 13px for the icon plus 6px for the gap */
    }
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @media screen and (max-width: 768px) {
    .container {
     margin-top: 10px;
    }
  }
