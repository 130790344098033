@import '../../index.scss';

@font-face {
  font-family: 'ABC Diatype';
  src: url('../../assets/fonts/ABCDiatype-Regular.woff') format('woff'), url('../../assets/fonts/ABCDiatype-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'NB Grotesk Pro Mono';
  src: url('../../assets/fonts/NBGroteskProMono-Regular.woff') format('woff'), url('../../assets/fonts/NBGroteskProMono-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

.cart-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-end;
  z-index: 10000;
  padding: 15px 20px;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;

  &.fade-in {
    opacity: 1;
  }
}

.cart-modal {
width: 400px;
  background: #f7f6e6;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  overflow-y: auto;
  
}

.cart-modal.no-scroll {
  overflow-y: hidden;
}

.cart-header {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.07);
}

.cart-header-empty {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 10px;
}

.item-price {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  font-family: 'NB Grotesk Pro Mono';
  position: relative;
  margin-top: 3vh;
}

.item-count {
  color: $color-black;
  padding: 2px 7.4px;
  margin-left: 5px;
  @include ABCDiatype-Bold(12);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  color: #f7f6e6;
  @media (max-width: 768px) {
    margin-right: -5px;
  }

  &.product-page {
    color: #f7f6e6;
    background-size: contain;
  }
}

.cart-items {
  flex-grow: 1;
  overflow-y: auto;
  background: transparent;
  position: relative;
  height: 100%;
}

.cart-items::-webkit-scrollbar {
  width: 8px;
}

.cart-items::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

.cart-items::-webkit-scrollbar-track {
  background: transparent;
}

.cart-item {
  display: flex;
  position: relative; // 1. Make each cart-item the positioning container
  justify-content: space-between;
  width: 100%;
  height: 150px; 
  border-bottom: 1px solid rgba(0, 0, 0, 0.07);
  padding: 10px;
  overflow: hidden;

  &:hover {
    background-color: rgba(0, 0, 0, 0.2);

    .change-controls-hover {
      display: block;
    }
  }

  .cart-item-image {
    width: 80px;
    height: auto;
    flex-shrink: 0;
    background-color: #e6e4d4
;
  }

  .cart-item-details {
    flex-grow: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-left: 10px;
  }

  .cart-item-action {
    flex-shrink: 0;
  }
}

.item-header {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  flex-grow: 1;
  font-family: 'ABC Diatype';
}

.item-details {
  text-transform: uppercase;
  font-weight: 400;
  font-size: 10px;
  padding-left: 10px;
  font-family: 'NB Grotesk Pro Mono';
  flex: 50;
  align-self: flex-start;
}

.item-actions button {
  margin-right: 10px;
  background: none;
  border: none;
  cursor: pointer;
}

.cart-footer {
  display: flex;
  flex-direction: column;
  margin-top: auto;
  padding: 10px;
}

.subtotal {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 700;
  font-size: 10px;
}

.subtotal-price {
  font-family: 'NB Grotesk Pro Mono';
  font-weight: 400;
  font-size: 12px;
}

.default-controls {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;

  button {
    border: none;
    background: none;
    cursor: pointer;
    font-size: 10px;
    font-weight: 400;
    font-family: 'ABC Diatype';

    &:first-child {
      margin-right: 3px;
    }

    &:last-child {
      margin-left: 3px;
    }

    &:hover {
      opacity: 0.8;
    }
  }
}
.quantity-controls-container{
  display: inline-flex;
padding: 3px 5px;
justify-content: center;
align-items: center;
gap: 10px;
border-radius: 23px;
border: 0.5px solid var(--Charcoal, #000);
margin-right: 20px;
}
.quantity-controls-text{
  color: var(--Charcoal, #000);
text-align: center;
font-family: "ABC Diatype";
font-size: 8px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-transform: uppercase;
}

.quantity-controls {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  gap: 5px;

  span {
    font-size: 10px;
    font-weight: 400;
    font-family: 'ABC Diatype';
  }

  button {
    color: black;
    border: none;
    background: none;
    cursor: pointer;
    font-size: 10px;
    font-weight: 400;
    font-family: 'ABC Diatype';
  }
}


.subtotal-price {
  font-size: 14px;
  margin-left: 10px;
}

.item-description {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 80%;
  white-space: normal;
}


.checkout-btn {
  background: black;
  border-radius: 41px;
  color: #f7f6e6;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  width: 100%;
  text-align: center;
  margin-top: 29px;
  font-weight: 700;
  font-size: 10px;
  font-family: 'ABC Diatype';
}

.favorites-btn {
  font-family: 'ABC Diatype';
  cursor: pointer;
  border: none;
  background: none;
  font-size: 12px;
  font-weight: 700;
  display: flex;
  align-items: center;
}

.close-btn {
  font-family: 'ABC Diatype';
  cursor: pointer;
  border: none;
  background: none;
  font-size: 12px;
  font-weight: 700;
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
    position: absolute;
    top: 1%;
    right: 3%;
leading-trim: both;
text-edge: cap;
font-family: "ABC Diatype";
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: normal;
text-transform: uppercase;

  }

}

.empty-cart {
  margin-top: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: absolute;
  top: 10%;
  left: 0;
  height: 5%;

  .empty-cart-image {
    width: 50%;
    height: auto;
    display: block;
  }

  .empty-cart-text {
    font-family: 'ABC Diatype';
    color: #7e7063;
    font-size: 12px;
    font-weight: 700;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    line-height: 1;
    white-space: nowrap;
  }
}
.save-product-container-cart{
position: absolute;
top: 125px;
}
.save-product-button-cart{
  color: #000;
font-family: "ABC Diatype";
font-size: 10px;
font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  cursor: pointer;
  border: none;
  background: none;
}

@media (max-width: 768px) {
  .cart-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 10000;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    opacity: 1;
    transform: none;
    transition: none;
  }

  .cart-modal {
    width: 100% !important;
    max-width: 100% !important;
    height: 100vh !important;
    background-color: #f5f2e7;
    padding: 20px;
    display: flex;
    flex-direction: column;
    border-radius: 0;
    box-shadow: none;
    overflow-y: auto;
  }

  .cart-header,
  .cart-footer {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
    color: #333;
  }

  .cart-items {
    flex-grow: 1;
    overflow-y: auto;
    padding: 20px 0;
  }

  .empty-cart {
    margin-top: 40%;
  }

  .checkout-btn {
    width: 100%;
    padding: 15px;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    border-radius: 25px;
    margin-bottom: 20px;
    background-color: #000;
    color: #fff;
    margin-bottom: 15%;
  }

  .subtotal {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .quantity-controls button,
  .default-controls button {
    color: black;
    border: none;
    background: none;
  }
}

.change-controls-hover {
  display: none;
  // ... existing styles ...
}