@import '../../../index.scss';

.mobileHeader {
  color: #000;
  background-color: transparent;
  background: inherit;
  font-family: 'ABC Diatype Unlicensed Trial-Bold', Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%;
  letter-spacing: -0.14px;
  text-transform: uppercase;
  padding: 20px 20px 0px 20px;
  // border: 1px solid red;
}

.recommandedContainer {
  padding: 20px 20px 20px 30px;
  position: relative;
  z-index: 0;

  .header {
    @include ABCDiatype-Bold(20);
    text-transform: uppercase;
  }

  .description {
    @include NBGroteskProMono-Regular(15);
  }

  .indicatorContainer {
    display: flex;
    margin-top: 20px;
    justify-content: center;

    @media (max-width: 768px) {
      display: none;
    }
  }
}

.BSheaderBlock {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  //border: 10px solid blue;
}

@media (max-width: 768px) {
  .recommandedContainer {
    padding-top: 20px;
    padding-bottom: 0px;
    padding-right: 20px;
    padding-left: 20px;
  }

  .BSheaderBlock {
    margin-bottom: 0px;
    //border: 10px solid blue;
  }
}