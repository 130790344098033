.headerBlockFormsSkin {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-size: cover;
    background-position: center;
}
.formContainer {
    min-width: 620px;
    min-height: 650px;
    max-height: 727px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 12px;
    background: linear-gradient(to bottom, #f7f6e6, #c8bda8);
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

//welcome section*****************
.welcomeformContentContainer{
    display: inline-flex;
padding: 11px 0px;
flex-direction: column;
align-items: center;
gap: 20px;
margin-top: 140px;
}

.groupWelcome{
    width: 48px;
    height: 48px;
}
.titleWelcomeContainer{
    width: 460px;

}
.titleWelcome{
    color: var(--Charcoal, #000);
text-align: center;
font-family: "ABC Diatype";
font-size: 26px;
font-style: normal;
font-weight: 700;
line-height: 100%; /* 26px */
letter-spacing: -0.52px;
text-transform: uppercase;
}
.subtitleWelcomeContainer{
    width: 297px;
}
.subtitleWelcome{
    color: var(--Charcoal, #000);
text-align: center;
font-family: "ABC Diatype";
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 110%; /* 13.2px */
text-transform: uppercase;
}
.buttons{
    display: inline-flex;
padding: 10px;
flex-direction: column;
align-items: flex-start;
gap: 12px;
margin-top: 50px;

}
.welcomeButtonContainer{
    display: flex;
width: 298px;
padding: 10px 76px;
justify-content: center;
align-items: center;
gap: 10px;
border-radius: 41px;
background: var(--Charcoal, #000);
height: 40px;

}
.nextButtonForm{
    color: var(--CTA-White, #F7F6E6);
text-align: center;
font-family: "ABC Diatype";
font-size: 10px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 200% */
text-transform: uppercase;
background-color: transparent;
width: 100%;
height: 40px;
border: none;
}
.maybeLaterButtonContainer{
    display: flex;
width: 298px;
padding: 10px 50px;
justify-content: center;
align-items: center;
gap: 10px;
}
.maybeLaterButton{
    color: var(--CTA-Black, #000);
text-align: center;
font-family: "ABC Diatype";
font-size: 10px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 200% */
text-transform: uppercase;
background-color: transparent;
border: none;
}

//rest of the form
.formContentContainer{
    display: flex;
    width: 459px;
    flex-direction: column;
    align-items: center;
    margin-top:64px ;
}
.groupOne{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 460px;
height: 94px;
gap: 10px;

}
.titleContainer{
    width: 460px;
}
.TitlesSpf{
color: var(--Charcoal, #000);
text-align: center;
font-family: "ABC Diatype";
font-size: 26px;
font-style: normal;
font-weight: 700;
line-height: 100%; /* 26px */
letter-spacing: -0.52px;
text-transform: uppercase;
}
.subtitleContainer{

}
.SubtitleSpf{
    color: var(--Clay, #7E7063);
font-family: "ABC Diatype";
font-size: 14px;
text-align: center;
font-style: normal;
font-weight: 400;
line-height: 17px; /* 121.429% */
letter-spacing: -0.14px;
}
.groupTwo{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 34px;
    
}
.groupTwoLongList {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 34px;
    max-height: 350px;
    overflow-y: auto;
    
    /* Firefox scrollbar styling */
    scrollbar-width: thin; /* or "none" if you want to hide it completely */
    scrollbar-color: transparent transparent; /* thumb and track colors */
  }
  
  /* WebKit-based browsers (Chrome, Safari, Edge, etc.) */
  .groupTwoLongList::-webkit-scrollbar {
    width: 8px; /* Adjust width as needed */
  }
  
  .groupTwoLongList::-webkit-scrollbar-track {
    background: transparent;
  }
  
  .groupTwoLongList::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

.inputGroup{
    display: flex;
flex-direction: column;
align-items: flex-start;
gap: 4px;
align-self: stretch;


}
.inputButtons{
    width: 459px;
height: 48px;
background-color:#EBE6D5;
border: none;
text-align: start;
padding-left: 10px;
color: var(--Ceramic, #5D5246);
font-family: "NB Grotesk Pro Mono";
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.selected{
    width: 459px;
height: 48px;
color: var(--Charcoal, #000);

border-radius: 3px;
background: var(--Beige, #CCB9A7);

}
.spfbuttonContainerWide{
    display: flex;
    width: 224px;
    padding: 10px 51px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 41px;
    background: var(--Charcoal, #000);
    height: 40px;
    cursor: pointer;
}

.spfbuttonContainer {
    display: flex;
    width: 178px;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 10px;
    border-radius: 41px;
    background: var(--Charcoal, #000);
    height: 40px;
    cursor: pointer;
  
    &.disabled {
        opacity: 0.2;
        cursor: default;
    }
  }
  
.nextButtonForm{
    color: var(--CTA-White, #F7F6E6);

text-align: center;
font-family: "ABC Diatype";
font-size: 10px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 200% */
text-transform: uppercase;
background-color: transparent;
width: 100%;
height: 40px;
border: none;
cursor: pointer;
}

.inputDropdownContainer{
    margin-top: 32px;

}
.inputAgeGroup{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;
}
.dropdown{
    width: 90px;
height: 40px;
flex-shrink: 0;
border-radius: 3px;
background: #EBE6D5;
border: none;
color: rgba(93, 82, 70, 0.50);

font-family: "NB Grotesk Pro Mono";
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.groupThree{
    margin-top: 32px;
}
.skinReviewContainer{
    width: 462px;
height: 244px;
display: inline-flex;
flex-direction: column;
align-items: center;
margin-top: 32px;
}
.skinResumeSection {
    display: flex;
    width: 462px;
    padding: 14px 0 18px 0;
    justify-content: flex-start;
    align-items: center;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
  
  /* Add a border-bottom to the last .skinResumeSection element */
  .skinResumeSection:last-child {

    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
.skinReviewNumberContainer{
    display: flex;
width: 15px;
height:35px;
justify-content: center;
align-items: flex-start;
gap: 1.071px;
}
.skinReviewNumber{
width: 7px;
height: 12px;
flex-shrink: 0;
}
.skinReviewValueContainer{
    display: flex;
flex-direction: column;
align-items: flex-start;
gap: 10px;
padding-left: 10px;
}
.skinReviewValueTitle{
    color: var(--Text-Charcoal, #000);
font-family: "ABC Diatype";
font-size: 10px;
font-style: normal;
font-weight: 700;
line-height: 110%; /* 11px */
letter-spacing: -0.1px;
text-transform: uppercase;
text-align: left;
}
.skinReviewValue{
    color: var(--Text-Charcoal, #000);
text-align: right;
font-family: "NB Grotesk Pro Mono";
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 105%; /* 12.6px */
letter-spacing: 0.48px;
text-transform: capitalize;
text-align: left;

}
.skinReviewEditButtonContainer {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end; /* Align children to the right */
    width: 100%;
  }
  
  .skinReviewEditButton {
    display: flex;
    justify-content: flex-end;
    color: var(--CTA-Black, #000);
text-align: right;
font-family: "ABC Diatype";
font-size: 10px;
font-style: normal;
font-weight: 700;
line-height: 110%; /* 11px */
letter-spacing: -0.1px;
text-transform: uppercase;
border: none;
background-color: transparent;
    align-items: flex-end; /* Use "flex-end" for proper alignment */
  }
  .loaderContainer{
    position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
}
  


  //mobile section
  //**************************************************
  //**************************************************
  @media (max-width: 440px) {
    .headerBlockFormsSkin{
        width: 100vw;
        height: 100%;
        overflow-y: hidden;

    }
    .formContainer {
        width: 362px;
        height: 80%;
      min-width: unset;
      min-height: unset;
      max-height: unset;
    }
    .welcomeformContentContainer{
        display: flex;
width: 298px;
flex-direction: column;
align-items: center;
gap: 40px;

    }
    .titleWelcomeContainer{
        width: 100%;
    }
    .buttons{
        position: relative;
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 10px;
    }
    .titleContainer{
        width: 223px;
        height: 44px;
    }
    .subtitleContainer{
        padding-top: 20px;
        width: 298px;
        height: 34px;
        text-align: center;
        padding-bottom: 40px;
    }
    .inputButtons{
        width: 330px;
    }
    .groupThree {
        position: relative;  /* Establishes a positioning context for absolutely positioned children */
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        padding-bottom: 50px;  /* Space for the floating button so it doesn't overlap other content */
      }
      
      .spfbuttonContainer {
        position: fixed;   /* Remove from normal flow and position relative to .groupThree */
        bottom: 3%;            /* Pin to the bottom of .groupThree */
        left: 50%;            /* Center horizontally */
        transform: translateX(-50%);
        width: 326px;
        height: 40px;
        display: flex;
        z-index: 10;          /* Ensure it floats above other content */
      }
      .groupTwo{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        margin-top: 34px;
        max-height: 350px;
        overflow-y: auto;
      }
      .groupTwoLongList{
        max-height: 250px;

      }
    .skinReviewContainer{
        width: 330px;

    }
    .skinResumeSection{
        width: 330px;
    }
    .loaderContainer{
        position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
    }
    }      