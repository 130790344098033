.contactBox {
    padding: 20px 0;
}

.contentContainer {
    background-color: rgba(204, 185, 167, 0.21);
    padding: 18px 20px;
    border-radius: 6px;
}

.contactItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.itemTitle {
    font-family: 'ABC Diatype', sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 15.26px;
    text-align: left;
}

.btnAction {
    font-family: 'ABC Diatype', sans-serif;
    font-size: 12px;
    line-height: 20px;
    font-weight: 400;
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background: transparent;
    text-decoration: none;
    color: black;
}

.dividerInBox {
    width: 100%;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.1);
    margin: 10px 0;
}

@media (max-width: 768px) {
    .contentContainer {
        width: 100%;
        background-color: rgba(204, 185, 167, 0.21);
        padding: 4px 10px;
        border-radius: 6px;
    }

    .itemTitle {
        color: var(--Charcoal, #000);
        font-family: "ABC Diatype";
        font-size: 10px;
        font-style: normal;
        font-weight: 700;
        line-height: 109%;
        text-transform: uppercase;
    }

    .contactItem {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .dividerInBox {
        width: 100%;
        height: 1px;
        background-color: rgba(0, 0, 0, 0.1);
        margin: 0;
    }

    .btnAction {
        color: var(--Charcoal, #000);
        text-align: right;
        font-family: "ABC Diatype";
        font-size: 10px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
        text-transform: uppercase;
    }
}