@import '../../../index.scss';

.container {
  padding: 20px 20px 20px 30px;

  .header {
    @include ABCDiatype-Bold(18);
    text-transform: uppercase;
    // border: 1px solid red;
  }

  .description {
    @include NBGroteskProMono-Regular(15);
  }

  .indicatorContainer {
    display: flex;
    margin-top: 20px;
    justify-content: center;

    @media (max-width: 768px) {
      display: none;
    }
  }
}

.headerBlock {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .carouselContainer {
    padding-top: 20px;
  }

  .container {
    width: auto;
    padding: 0px 20px 0px 20px;
  }

  .miniature {
    height: 120px;
  }

  .headerBlock {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0px;
    // border: 1px solid red;
  }
}