@import '../../../../index.scss';

.searchContainer {
  display: grid;
  grid-template-columns: repeat(2, 0fr);
  list-style: none;
}

.menuExpandContainer {
  // border: 10px solid blue;
}

.menuContainerSearchBar {
  background-color: $color-default-background;
  padding: 15px 20px;
  z-index: 10000;
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 43%;
  min-width: 650px;
  height: 98svh;
  overflow-x: hidden;
  overflow-y: hidden;
  min-height: 767px;
  max-height: 100%;
  

  @media (max-width: 768px) {
    height: 100svh;
  }
}

.menuContainerSearchBarHeader {
  top: 2%;
  left: 2%;
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 768px) {
    align-items: center;
  }
}

.menuBacktoMenuButton {
  color: var(--Charcoal, #000);
  font-family: 'ABC Diatype';
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-transform: uppercase;
  border-radius: 14px;
  background: rgba(93, 82, 70, 0.2);
  border: none;
  width: 115px;
  padding: 3px 13px;
  align-items: flex-start;
  gap: 10px;
}

.menuCloseButton {
  background-color: transparent;
  border: none;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  //padding: 0;
  display: flex;
  align-items: center;
  margin-right: 1%;

  .menuContainerCloseText {
    @include ABCDiatype-Bold(12);
    text-transform: uppercase;
    cursor: pointer;
  }

  &:focus,
  &:active {
    background: none;
    outline: none;
    box-shadow: none;
  }
}

.menuContainerCloseIcon {
  width: 15px;
}

.menuContainerSearchBarBody {
  margin-top: 17%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: fit-content;
}

.menuTitleSearchbar {
  color: #000;
  font-family: 'ABC Diatype';
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}

.menuContainerSearchBarInputContainer {
  display: flex;
  margin-top: 4%;
  margin-right: 1%;
  padding: 10px 0px;
}

.menuSearchContainer {
  height: 100%;
  position: relative;
}

.searchBarInput {
  color: var(--Ceramic, #5d5246);
  font-family: 'ABC Diatype';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%;
  letter-spacing: -0.14px;
  text-transform: uppercase;
  flex-grow: 1;
  margin-right: 10px;
  width: 100%;
  background-color: $color-default-background;
  border: none;
  outline: none;
  color: #000000;
}

.searchClearButton {
  // position: absolute;
  // top: 20%;
  // transform: translateY(-50%);

  border: none;
  background: none;
  cursor: pointer;
  color: #000000;
  font-size: 12px;
  text-transform: uppercase;
  font-family: 'ABC Diatype';
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -0.14px;
}

.menuContainerMostSearchBar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 8%;
  overflow-y: auto; // Added scroll bar for y-axis
  overflow-x: hidden;

  //height: 0;
  &.expanded {
    height: 100%;
  }
}

.seeAll-mostSearched {
  //border: 1px solid blue;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 2%;
}

.menuContainerMostSearchedText,
.menuContainerMostSearchedSeeAll {
  @include ABCDiatype-Bold(12);
  text-transform: uppercase;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -0.14px;
  cursor: pointer;
}

.menuContainerMostSearchedList {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2%;
  list-style: none;
}

.menuContainerMostSearchedItem {
  cursor: pointer;
  max-width: 300px;
  background-color: #f0f1e1;
  height: 100%;
}

.menuContainerMostSearchedItemImg {
  min-height: 30svh;
  margin-right: 10px;
  width: 100%;
}

.menuContainerMostSearchedItemTextContainer {
  margin-top: 0px;
  padding: 2px;
}

.menuContainerMostSearchedItemPrice {
  text-align: left;
  margin-bottom: 2px;
  color: var(--Black, #000);
  font-family: 'NB Grotesk Pro Mono';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.26px;
  line-height: 100%;
  padding-bottom: 5px;
  padding-top: 2px;
  @media (max-width: 768px) {
    font-size: 11px;
  }
}

.menuContainerMostSearchedItemBrand {
  color: var(--Charcoal, #000);
  text-align: left;
  margin-bottom: 2px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%;
  /* 8.8px */
  letter-spacing: -0.12px;
  text-transform: uppercase;
  @include ABCDiatype-Bold(12);
  text-decoration: none;
}

.menuContainerMostSearchedItemTitle {
  color: var(--OLI-PRIMARY-BLACK, #000);
  font-family: 'NB Grotesk Pro Mono';
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  margin-top: 2px;
  text-align: left;
  font-size: 13px;
  letter-spacing: -0.26px;
  text-transform: capitalize;
}

@media (max-width: 768px) {
  .menuContainerMostSearchedItem {
    min-height: 23vh;
    min-width: 20vw;
  }

  .menuContainerMostSearchedItemImg {
    min-height: auto;
    height: 15vh;
    width: 60%;
  }

  .menuContainerMostSearchedItemTextContainer {
    padding: 6px;
    gap: 2px;
  }

  .menuContainerMostSearchedList {
    width: 100%;
  }
}

.menuContainerSuggestions {
  margin-top: 5%;
  line-height: 1.4;
}

.menuContainerSuggestionsTitle {
  padding-bottom: 1%;
  @include ABCDiatype-Bold(14);
  color: #000;
  text-transform: uppercase;
}

.menuContainerSuggestionsListItem {
  cursor: pointer;
  color: rgba(0, 0, 0, 0.5);
  font-family: 'ABC Diatype';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  letter-spacing: -0.1px;
  text-transform: uppercase;
  padding: 8px 0;

  strong {
    color: var(--Charcoal, #000);
    font-family: 'ABC Diatype';
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
    letter-spacing: -0.1px;
    text-transform: uppercase;
  }
}

.searchContainerSuggestions {
}

.socialMediaContainer {
  position: sticky;
  bottom: 0;
  //margin-top: 100%;
  display: flex;
  justify-content: space-between;
  width: 99%;
  position: absolute;
}

.socialMediaContainerItem {
  @include ABCDiatype-Bold(12);
  text-transform: uppercase;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -0.14px;
  cursor: pointer;

  color: black;
  text-align: center;
  font-style: normal;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
}

.menuContainerLastSearchContainer {
  margin-top: 6%;
  line-height: 2;
  text-transform: uppercase;
  font-size: 12px;
  color: var(--Charcoal, #000);
  font-family: 'ABC Diatype';
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.1px;
  text-transform: uppercase;
}

.menuContainerLastSearchText {
  @include ABCDiatype-Bold(14);
  text-transform: uppercase;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -0.14px;
}

.menuContainerLastSearchList {
  margin-top: 2%;
}

.menuContainerLastSearchItem {
  letter-spacing: 0.2px;
}

.menuContainerSearchDeleteButton {
  margin-left: 10px;
}

.menuContainerSearchBarExpandContainer {
  transition: visibility 0s, opacity 0.5s ease-in-out;
  height: 98svh;
  width: 43%;
  position: absolute;
  top: 0;
  right: 15.1%;
  padding: 15px 20px;

  @media (max-width: 768px) {
    height: 100svh;
  }
}

@media (max-width: 768px) {
  .searchContainer {
    width: 100%;
    height: 100%;
    padding: 0;
  }

  .menuContainerSearchBar {
    background-color: $color-default-background;
    z-index: 10000;
  }

  .menuSearchContainer {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    border-radius: 0px;
    padding: 20px;
    width: 100%;
    height: 100svh;

    @media (max-width: 768px) {
      max-width: 86%;
    }

    @media (max-width: 730px) {
      max-width: 89%;
    }

    @media (max-width: 710px) {
      max-width: 90%;
    }

    @media (max-width: 690px) {
      max-width: 93%;

    }

    @media (max-width: 670px) {
      max-width: 98%;
    }
  }

  .socialMediaContainer {
    position: sticky;
    bottom: 0;
    display: flex;
    width: 90%;
    position: absolute;
  }

  .menuContainerCloseText {
    color: black;
  }
}
