.dossiersSection {
  width: 100%;
}

.dossiersHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0px;
  padding: 0px 20px;

  h2 {
    margin: 0;
    color: black;
    font-family: 'SF Pro Display', sans-serif;
    font-size: 20px;
    font-weight: 600;
    line-height: 22.09px;
  }
}

.dossiersCount {
  font-family: 'ABC Diatype-Regular', Helvetica;
  letter-spacing: 0;
  line-height: 13.2px;
  position: absolute;
  left: 40%;
}

/* Swiper Container */
.dossiersSwiper {
  width: 100%;
  cursor: url('../../../../assets/cursors/dragCursor.svg'), auto;
  /* Optional: Customize Swiper's scrollbar or pagination */
  .swiper-pagination {
    bottom: 10px; /* Adjust as needed */
  }

  .swiper-slide {
    border: 1px solid red;
    cursor: pointer;
    /* Remove any default padding/margin */
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

/* Dossier Item */
.dossierItem {
  display: flex;
  justify-content: center;
}

/* Dossier Background */
.dossierBackground {
  max-width: 100%;
  min-width: 350px;
  max-height: 720.441px;
  min-height: 550px;
  background-size: cover;
  background-position: center;
  border-radius: 0;
  position: relative;
  overflow: hidden;


  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.4);
  }
}

/* Dossier Content */
.dossierContent {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  color: white;
  text-align: center;

  h3 {
    font-size: 1.5rem;
    margin: 0 0 0.5rem 0;
    font-weight: bold;
    text-transform: uppercase;
    font-family: 'ABC Diatype-Regular', Helvetica;
    letter-spacing: 0;
    line-height: 13.2px;
  }
}

.articleCount {
  font-size: 1rem;
  opacity: 0.8;
  font-family: 'NB Grotesk Pro', sans-serif;
}

/* Optional: Drag Indicator */
.dragIndicator {
  position: absolute;
  bottom: -2rem;
  right: 1rem;
  background-color: #000;
  color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  font-size: 0.8rem;
  font-weight: bold;
}
