.addressBook {
  border-radius: 8px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgb(223, 218, 218);
}

.title {
  font-family: 'ABC Diatype', sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
  text-transform: uppercase;
  margin-bottom: 1%;
}

.btnAdd {
  font-family: 'SF Pro Display', sans-serif;
  font-size: 14px;
  font-weight: 400;
  background: transparent;
  border-radius: 4px;
  color: black;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.3s ease;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1);
  margin: 15px 0;
}

.addressList {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
  margin-top: 10px;
  max-height: 500px;
  overflow-y: auto;
  padding: 10px 0;
}


.btnAddress {
  background-color: #ccb9a7;
  color: #5d5246;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.3s ease;
}

.btnAddress:hover {
  background-color: #bba493;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
}

.addressInfo {
  position: relative;
  padding-bottom: 20px;
}

.addressName {
  font-family: 'ABC Diatype', sans-serif;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 10px;
  color: black;
}

.btnEditAddress {
  font-family: 'SF Pro Display', sans-serif;
  font-size: 12px;
  font-weight: 400;
  color: black;
  text-decoration: none;
  position: absolute;
  right: 10px;
  bottom: 10px;
  background-color: #ccb9a7;
  border: 1px solid black;
  border-radius: 4px;
  padding: 4px 8px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.btnEditAddress:hover {
  background-color: #bba493;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
}

.addressInfoContainer {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.noAddresses {
  color: var(--unnamed, #5D5246);
  text-align: center;
  font-family: "ABC Diatype";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: -0.32px;
  text-transform: uppercase;
  padding: 30px;
}

.addressDefaultIcon {
  position: absolute;
  right: 10px;
  top: 0px;
}

@media screen and (max-width: 768px) {
  .addressList {
    margin-top: 0;
    grid-template-columns: 1fr;
    max-height: none;
    overflow-y: visible;
  }

  .noAddresses {
    margin-top: 50%;
    color: var(--unnamed, #5D5246);
    text-align: center;
    font-family: "ABC Diatype";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 17px;
    letter-spacing: -0.32px;
    text-transform: uppercase;
    padding: 0;
  }
}