.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContent {
  background: #f8f6e9;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  position: relative;
  height: 240px;
  width: 450px;
}

.headerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 10px;
  left: 10px;
  width: 60%;
  justify-content: space-between;
}

.closeButton {
  cursor: pointer;

  background: none;
  border: none;
  font-size: 10px;
  cursor: pointer;
  color: black;
  font-family: 'ABC Diatype';
  font-weight: bold;
}

.addVoucherHeader {
  font-family: 'ABC Diatype';
  font-size: 14px;
}
