.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  overflow: hidden;
}

.textContainer {
  position: relative;
  transition: transform 1s ease-in-out;

  &.animating {
    animation: slideOut 1s ease-in-out;
  }
}



.incomingText {
  color: var(--Charcoal, #000);
  width: 20.78913rem;
  font-family: 'ABC Diatype';
  font-size: 3.211rem;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 3.411rem */
  letter-spacing: -0.06825rem;
  text-transform: uppercase;
  text-align: center;
}

.outgoingText{
  color: var(--Charcoal, #000);
  width: 20.78913rem;
  font-family: 'ABC Diatype';
  font-size: 3.211rem;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 3.411rem */
  letter-spacing: -0.06825rem;
  text-transform: uppercase;
  text-align: center;

}

@keyframes slideOut {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-50px);
    opacity: 0;
  }
}

@keyframes slideIn {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@media (min-width: 1000px) {
  .outgoingText {
    color: var(--Charcoal, #000);
    text-align: center;

    font-family: 'ABC Diatype';
    font-size: 1.875rem;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 2.25rem */
    letter-spacing: -0.0375rem;
    text-transform: uppercase;
    width: 53.54156rem;
  }
  .incomingText{
    color: var(--Charcoal, #000);
    text-align: center;

    font-family: 'ABC Diatype';
    font-size: 1.875rem;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 2.25rem */
    letter-spacing: -0.0375rem;
    text-transform: uppercase;
    width: 53.54156rem;
  }
}

.outgoingText {
  position: absolute;
  animation: slideUp 0.6s ease-out forwards;
}

.incomingText {
  position: absolute;
  opacity: 0;
  transform: translateY(20px);
  animation: slideIn 0.6s ease-out forwards;

  &.animating {
    opacity: 0;
    animation: none;
  }
}

@keyframes slideUp {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-20px);
    opacity: 0;
  }
}

@keyframes slideIn {
  0% {
    transform: translateY(20px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
