@import '../../../index.scss';

html,
body {
  user-select: none;
  /* Standard syntax */
  -webkit-user-select: none;
  /* Chrome, Safari, iOS */
  -moz-user-select: none;
  /* Older Firefox versions */
  -ms-user-select: none;
  /* Internet Explorer/Edge Legacy */
}

@mixin ABCDiatype-Bold($size) {
  font-weight: bold;
  font-size: $size;
  font-family: 'ABCDiatype', sans-serif; // Assuming 'ABCDiatype' is the font-family name
}

.descriptionTextContainer {
  width: 350px;
  margin-top: 10px;
}

.descriptionText {
  color: var(--WHITE, #f7f6e6);
  font-family: 'NB Grotesk Pro Mono';
  text-align: left;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
  letter-spacing: -0.36px;
}

.shopHeaderBlock {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 82vh;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  position: relative;
}

.shopHeaderBlockContent {
  display: flex;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.bestsellerHeaderAdviceContainer {
  position: absolute;
  left: 10%;
}

.clickablePart {
  color: var(--WHITE, #f7f6e6);
  font-family: 'ABC Diatype';
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: 104%;
  /* 27.04px */
  text-transform: uppercase;
}

.bestsellerHeaderAdvice {
  color: var(--WHITE, #f7f6e6);
  font-family: 'ABC Diatype';
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: 104%;
  /* 27.04px */
  text-transform: uppercase;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3); /* Adds a subtle shadow */

  
}

.bestsellerHeaderRightTextContainer {
  position: absolute;
  right: 10%;
}

.bestsellerHeaderRightText {
  color: var(--WHITE, #f7f6e6);
  font-family: 'NB Grotesk Pro';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3); /* Adds a subtle shadow */

  
}

.hotbrandcounter {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-family: 'ABC Diatype';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  padding-top: 2vw;
}

.hotBrandsHeaderAdviceContainer {
  right: 10%;
  width: fit-content;
  position: absolute;
  text-align: center;
  justify-content: center;
  //width: 20%;
  //border: 1px solid red;
}

.hotBrandsHeaderAdvice {
  color: var(--WHITE, #f7f6e6);
  text-align: right;

  font-family: 'NB Grotesk Pro Mono';
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 105%;
  /* 18.9px */
  letter-spacing: -0.36px;
  text-transform: capitalize;
}

/***************************** Move all mobile-specific styles here */

/***************************** Move all mobile-specific styles here */

/***************************** Move all mobile-specific styles here */

/***************************** Move all mobile-specific styles here */

/***************************** Move all mobile-specific styles here */

@media (max-width: 768px) {
  .descriptionTextContainer {
    width: 100%;
    padding: 0px 50px;
  }

  .descriptionText {
    text-align: center;
  }

  .shopHeaderBlock {
    height: 70vh;
  }

  .shopHeaderBlockContent {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 100%;
  }

  .bestsellerHeaderAdviceContainer {
    top: 50%;
    left: 0;
    right: 0;
    text-align: center;
  }

  .bestsellerHeaderRightTextContainer {
    bottom: 15%;
    left: 50%;
    transform: translate(-50%, 0);
    text-align: center;
  }

  .hotbrandcounter {
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .hotBrandsHeaderAdviceContainer {
    bottom: 10%;
    left: 0%;
    width: 100%;
    position: absolute;
    text-align: center;
    justify-content: center;
  }

  .hotBrandsHeaderAdvice {
    width: 100%;
    position: absolute;
    text-align: center;
    justify-content: center;
    //border: 1px solid red;
    padding-left: 8vw;
  }
}
