.productCard {
    display: flex;
    align-items: stretch;
    /* Allow children to stretch to full height */
    padding: 12px 0;
    gap: 1rem;
    position: relative;
    border-top: 1px solid rgba(0, 0, 0, 0.07);
    max-width: 800px;
    min-height: 120px;
    /* Optional: Ensures a minimum height */
}

.left {
    flex-shrink: 0;
}

.image {
    width: 80px;
    height: auto;
    flex-shrink: 0;
}

.middle {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 4px;
    /* Adds some spacing between brand, title, and quantity */
}

.brand {
    color: var(--Charcoal, #000);
    font-family: "ABC Diatype";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
    text-transform: uppercase;
}

.title {
    color: var(--OLI-PRIMARY-BLACK, #000);
    font-family: "NB Grotesk Pro Mono";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 105%;
    letter-spacing: 0.4px;
}

.quantity {
    color: #000;
    font-family: "NB Grotesk Pro Mono";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
}

.right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    flex-shrink: 0;
    position: relative;
}


.price {
    color: #000;
    text-align: right;
    font-family: "NB Grotesk Pro Mono";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 115.5%;
    text-transform: uppercase;
}

.detailsBtn {
    color: #000;
    font-family: "ABC Diatype";
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    text-transform: uppercase;
    background: none;
    border: none;
    cursor: pointer;
    bottom: 0%;
}