.collectionPage {
    width: 100%;
    height: 100%;
    background: var(--Off-White, #F7F6E6);
    overflow-x: hidden;
}

.collectionPageContent {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    width: 100%;
    background: var(--Off-White, #F7F6E6);

}

.categoriesFilter {
    position: sticky;
    top: 40px;
    height: fit-content;
    z-index: 1000;
}

.gridProductsContainer {
    width: 100%;
}

.collectionCategories {
    flex: 20%;
    padding-right: 20px;

}

.collectionProducts {
    flex: 80%;
    padding-left: 20px;
    background: var(--Off-White, #F7F6E6);

}

@media (max-width: 448px) {
    .collectionPageContent {
        flex-direction: column;
    }
}