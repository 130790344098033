.footerContainer {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;



  .footerMenu {
    background-color: #f7f6e6;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    border-radius: 12px;
    padding: 50px 50px 10px 50px;
    height: 487px;

    .topContainer {
      width: 50%;
      margin-bottom: 10%;

      .footerHeader {
        font-family: 'NB Grotesk Pro Mono';
        font-size: 24px;
        max-width: 370px;
        margin-bottom: 30px;
      }

      .newsletterContainer {
        position: relative;
        /* Ensure dotIcon is positioned relative to this container */
        display: flex;
        align-items: center;
        /* Align elements vertically */

        .dotIcon {
          width: 10px;
          /* Adjust size of the dot */
          height: 10px;
          background-size: cover;
          background-position: center;
          position: absolute;
          /* Position relative to the container */
          top: 50%;
          /* Center vertically */
          transform: translateY(-50%);
          /* Center alignment */
          left: 10px;
          /* Adjust horizontal positioning */
          z-index: 10;
        }


        .regNews {
          font-size: 12px;
          text-transform: uppercase;
          font-family: 'ABC Diatype';
          font-weight: bold;
          margin-left: 25px;
        }
      }

      .newsletterForm {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .newsletterInput {
          flex: 1;
          /* Allow the input to take available space */
          border: none;
          background-color: transparent;
          text-transform: uppercase;
          padding: 10px;
          /* Add padding for better UX */
          outline: none;
          /* Remove the blue border */
          font-size: 14px;
          /* Adjust font size if needed */
          color: var(--Charcoal, #000);
          width: 279px;
          height: 53.955px;
          flex-shrink: 0;
          margin-left: 15px;

          color: var(--Ceramic, #5D5246);

          font-family: "ABC Diatype";
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 110%;
          /* 15.4px */
          letter-spacing: -0.14px;
          opacity: 0.3;
        }



        .newsletterInput:focus {
          border: none;
          /* Ensure no border is shown on focus */
          box-shadow: none;
          /* Remove any potential focus box shadow */
          opacity: 0.3;

        }



        .subscribeBtn {
          display: flex;
          width: 118px;
          padding: 10px 20px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          border-radius: 41px;
          background: var(--Charcoal, #000);
          color: var(--Off-White, #F7F6E6);
          text-align: center;
          font-family: "ABC Diatype";
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          text-transform: uppercase;
          position: relative;
          margin-left: 10px;
          border: none;
          /* Space between input and button */
          transform: translateX(0);

          /* Resetting transform */
          @media (max-width: 768px) {
            margin-right: 30%;
          }

          @media (max-width: 360px) {
            margin-right: 45%;
            padding-right: 10px;
          }
        }
      }

      .onlineStoresButtonsBlock {
        display: flex;
        flex-direction: row;
        margin-top: 50px;

        .storeButtonContainer {
          width: 100%;
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 40px;
          margin-right: 10%;

          .storeButton {
            background-color: #000;
            color: #fff;
            border-radius: 200px;
            height: 52px;
            width: 100%;
            font-size: 12px;
            text-transform: uppercase;
            font-family: 'ABC Diatype';
            border: none;
            margin-right: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;

            .icon {
              height: 14px;
              width: 12px;
              position: absolute;
              left: 20px;
              background-size: cover;
              background-position: center;
              background-repeat: no-repeat;
            }

            p {
              margin: 0;
            }
          }
        }
      }
    }

    .menuLinks {
      display: flex;
      width: 35%;

      .toggleIcon {
        display: none;
      }

      .menuSection {
        margin-right: 15%;

        .footerMenuHeader {
          font-family: 'ABC Diatype';
          font-size: 10px;
          font-weight: bold;
          text-transform: uppercase;
          margin-bottom: 10px;
          color: #7e7063;
        }

        ul {
          padding-left: 0;
          list-style: none;

          .footerMenuItem {
            font-family: 'ABC Diatype';
            font-size: 12px;
            text-transform: uppercase;
            margin-bottom: 8px;
            font-weight: bold;
            cursor: pointer;
            display: flex;
            flex-direction: column;

            @media (max-width: 768px) {
              font-size: 14px;
              display: block;
            }
          }
        }
      }
    }

    .policyBlock {
      display: flex;
      justify-content: space-between;
      width: 100%;
      position: relative;
      align-self: flex-end;
      padding-right: 0%;

      .allRights {
        font-family: 'ABC Diatype';
        font-size: 10px;
        color: #7e7063;
        text-transform: uppercase;
      }

      .policies {
        display: flex;
        gap: 20px;
        font-weight: 700;
      }

      .policyBtn {
        font-family: 'ABC Diatype';
        font-size: 10px;
        text-transform: uppercase;
        cursor: pointer;
      }
    }
  }

  .footerBottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 100px 0;

    .footerLogo {
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      height: 35px;
      width: 100px;
      margin-bottom: 20px;
    }

    .footerAdvice {
      font-family: 'NB Grotesk Pro Mono';
      font-size: 14px;
      text-align: center;
      color: #f7f6e6;
    }

    .mediaLinksContainer {
      margin-top: 10px;
      display: flex;
      gap: 10px;
      align-items: center;
    }

    .mediaLinks {
      color: #F4F2F2;
      text-align: center;
      font-family: "ABC Diatype";
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 110.457%;
      text-transform: uppercase;
      text-decoration: none;
      cursor: pointer;
    }
  }

  .allRightsComingSoon {
    color: #7E7063;
    font-size: 12px;
    font-family: "ABC Diatype";
    text-transform: uppercase;
    text-align: center;
  }
}

@media (max-width: 768px) {
  .footerContainer {
    padding: 8px;
    padding-top: 20px;

    .footerMenu {
      padding: 30px;
      height: 100%;

      .topContainer {
        width: 100%;

        // height: 0%;
        .onlineStoresButtonsBlock {
          justify-content: center;

          .storeButtonContainer {
            width: 100%;
            gap: 10px;
            display: flex;
            flex-direction: column;
            margin-bottom: 30px;
            margin-right: 0px;

            .storeButton {
              //background-color: grey;
              color: #fff;
              border-radius: 200px;
              height: 50px;
              width: 100%;
              font-size: 12px;
              text-transform: uppercase;
              font-family: 'ABC Diatype';
              //border: 1px solid red;
              margin-top: 3px;

              .icon {
                height: 14px;
                width: 12px;
                position: absolute;
                left: 20px;
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
              }
            }
          }
        }
      }

      .allRightsContainer {
        display: flex;
       align-items: center;
       gap: 8px
       
      }

      .menuLinks {
        display: flex;
        flex-direction: column;
        width: 100%;

        .menuSection {
          width: 100%;
          border-top: 0.5px solid #7e7063;
          width: 100%;
          padding-top: 10px;

          &:last-child {
            border-bottom: 0.5px solid #7e7063;
          }

          .header-mobile-section {
            display: flex;
            justify-content: space-between;

            .toggleIcon {
              display: block;
            }
          }

          .footerMenuHeader {
            font-family: 'ABC Diatype';
            font-size: 12px;
            font-weight: bold;
            text-transform: uppercase;
            color: #7e7063;
            padding: 0;

            .arrow {
              width: 0;
              height: 0;
              border-left: 6px solid transparent;
              border-right: 6px solid transparent;
              border-top: 8px solid #000;
              transition: transform 0.3s ease;
            }

            &.up .arrow {
              transform: rotate(180deg);
            }
          }

          .footerMenuItem {
            font-family: 'ABC Diatype';
            font-size: 12px;
            text-transform: uppercase;
            margin-bottom: 16px;
            font-weight: bold;
            cursor: pointer;
          }

        }

        .footerMenuList {
          list-style: none;
          padding-left: 0;
          max-height: 0;
          overflow: hidden;
          transition: max-height 0.3s ease;

          &.expanded {
            max-height: 500px;
          }

          .footerMenuItem {
            cursor: pointer;
            transition: color 0.3s ease;
            font-size: 14px;

            &:hover {
              color: #555;
            }
          }
        }
      }

      .policyBlock {
        width: 100%;
        // padding-top: 10%;
        display: flex;
        margin-top: 30px;

        padding-right: 0%;
        flex-direction: column-reverse;

        .allRights {
          margin-top: 25px;
          font-weight: bold;
        }

        .policies {
          gap: 5px;
          font-weight: 700;
          flex-direction: column-reverse;
          font-weight: 700;
        }
      }
    }
  }
}