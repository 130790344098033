.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  z-index: 1000;
}

.modalContent {
  background-color: #f7f6e6;
  padding: 20px;
  margin: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  min-width: 480px;
}

.noVouchersMessage {
  font-family: 'ABC Diatype';
  font-size: 10px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.modalHeader {
  font-family: 'ABC Diatype';
  font-size: 10px;
  font-weight: bold;
}

.closeButton {
  background: none;
  border: none;
  font-size: 10px;
  cursor: pointer;
  color: black;
  font-family: 'ABC Diatype';
  font-weight: bold;
}

.vouchersList {
  max-height: 300px;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding-right: 10px;
}

.vouchersList::-webkit-scrollbar {
  display: none;
}

.voucherCard {
  height: 130px;
  width: 100%;
  border-radius: 8px;
  background-size: cover;
  background-position: center;
  position: relative;
  cursor: pointer;
  margin-bottom: 16px;

  &.inactive {
    cursor: not-allowed;
  }
}

.expirationDateContainer {
  display: flex;
  justify-content: flex-end;
  padding: 15px;
}

.expirationDate {
  font-size: 10px;
  background-color: #f7f6e6;
  border-radius: 15px;
  color: black;
  font-family: 'ABC Diatype';
  padding: 8px;
}

.voucherInfo {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  // background: linear-gradient(to top, rgba(0, 0, 0, 0.7), transparent);
  color: white;
}

.voucherCode {
  font-size: 14px;
  margin-bottom: 5px;
  font-family: 'ABC Diatype';
  font-weight: bold;
}

.discountValue {
  font-size: 14px;
  font-family: 'NB Grotesk Pro';
}

@media (max-width: 768px) {
  .modalOverlay {
    justify-content: flex-end;
    align-items: flex-end;
  }

  .modalContent {
    width: 100%;
    min-width: auto;
    margin: 0;
    border-radius: 16px 16px 0 0;
    background-color: #F7F6E6;
    padding: 15px;
  }

  .vouchersList {
    max-height: 50vh;
    padding-right: 0;
  }

  .voucherCard {
    margin-bottom: 12px;
    transition: opacity 0.3s ease;

    &.transparent {
      opacity: 0.5;
    }
  }

  .expirationDateContainer {
    // justify-content: space-between;
    padding: 10px;
  }

  .expirationDate {
    font-size: 9px;
    padding: 6px;
  }

  .voucherCode,
  .discountValue {
    font-size: 12px;
  }

  .addButton {
    display: flex;
    width: 100%;
    padding: 10px 76px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 41px;
    background: black;
    color: #F7F6E6;
    margin: 20px auto 0;
    position: static;
    border: none;

    &:disabled {
      background-color: rgba(0, 0, 0, 0.2);
      cursor: not-allowed;
      filter: grayscale(100%);
    }
  }


  .headerContainer {
    justify-content: center;
    position: relative;
  }

  .modalHeader {
    font-size: 12px;
    font-weight: 700;
    text-align: center;
    flex-grow: 1;
  }

  .closeButton {
    position: absolute;
    left: 10px;
    font-size: 12px;
    font-weight: 400;
  }

  .selectedIconContainer {
    position: absolute;
    bottom: 10px;
    right: 20px;
  }

  .selectedIcon {
    width: 20px;
    height: 20px;
  }
}