.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
  }
  
  .modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: var(--Text-OFF-WHITE, #f7f6e6);
    padding: 2rem;
    border-radius: 8px;
    z-index: 1001;
    text-align: center;
    width: 90%;
    max-width: 400px;
  
    h2 {
      font-family: 'ABC Diatype';
      font-size: 1.25rem;
      margin-bottom: 1rem;
      color: var(--Text-CHARCOAL, #000);
    }
  
    p {
      font-family: 'NB Grotesk Pro Mono';
      font-size: 0.89388rem;
      margin-bottom: 1.5rem;
      color: var(--Text-CHARCOAL, #000);
    }
  }
  
  .buttonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
  }
