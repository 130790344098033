.collectionPage {
    width: 100%;
    height: 100%;
    background: var(--Off-White, #F7F6E6);
}

.collectionPageContent {
    padding-top: 60px;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    width: 100%;
}

.collectionCategories {
    flex: 20%;
    padding-right: 20px;
}

.collectionProducts {
    flex: 80%;
    padding-left: 20px;
}

.loaderContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.collectionPageGrid {
    width: 100%;
    overflow: hidden;
}

.categoriesFilter {
    position: sticky;
    top: 40px;
    height: fit-content;
    z-index: 1000;
}