@import '../../index.scss';

.scrollIndicators {
  display: flex;
  margin-top: 15px;

  &.horizontal {
    flex-direction: row;
  }

  &.vertical {
    flex-direction: column;
    align-items: flex-start;
  }
}

.scrollNumberIndicator {
  @include NBGroteskProMono-Regular(12);

  .scrollIndicators.horizontal & {
    margin: 0 3px;
    //border: 10px solid red;
    //position: relative;
  }

  .scrollIndicators.vertical & {
    margin: 3px 0;
    margin-right: 0;
    position: relative;
    display: inline;
    bottom: 150px;
    left: 0;
    @media (max-width: 768px) {
      bottom: 0px;
    }
  }
}
