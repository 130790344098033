.wrapper {
  position: relative;
  min-height: 80vh;
  width: 100%;
}

.scannerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3rem;
  margin-bottom: 3rem;
  gap: 2rem;
  justify-content: center;
  width: 100%;
}

.title {
  color: var(--Charcoal, #000);
  text-align: center;
  max-width: 300px;
  font-family: 'ABC Diatype';
  font-size: 1.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 2.25rem */
  letter-spacing: -0.0375rem;
  text-transform: uppercase;
}

.generalContainer {
  position: relative;
  width: 22.6rem;
  height: 600px;
  margin: 0 auto;
  overflow: hidden;
}

.webcam {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.5rem;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;

  img:not(.blob) {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.blob {
  position: absolute;
  width: 360px;
  height: 355px;
  top: 13%;
  object-fit: contain;
  z-index: 5;
}

.scanButtonsWrapper {
  position: absolute;
  bottom: 1.7rem;
  z-index: 10;
  width: 100%;
  display: flex;
  gap: 4rem;
  justify-content: center;
  align-items: center;
}

.scanButtonsContainer {
  z-index: 10;
  display: flex;
  width: 95%;
  gap: 3.5625rem;
}

.ingredientsInput {
  width: 100%;
  height: 600px;
  padding-inline: 1.28rem;
  padding-top: 1.44rem;
  border-radius: 0.5rem;
  border: 0.5px solid var(--Backgrounds-CERAMICS, #5d5246);
  background: #fff;
  color: #000;
  font-family: 'NB Grotesk Pro Mono';
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.875rem; /* 150% */
  resize: none;

  &::placeholder {
    color: #cecece;
    font-family: 'NB Grotesk Pro Mono';
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.875rem; /* 150% */
  }

  &:focus {
    outline: none;
    border-color: #000;
  }
}

@media (min-width: 1001px) {
  .scannerContainer {
    margin-top: 6.4rem;
    gap: 0;
  }
  .title {
    color: var(--Charcoal, #000);
    font-size: 1.875rem;
    line-height: 120%; /* 2.25rem */
    letter-spacing: -0.0375rem;
    max-width: 53.54156rem;
  }
  .mainTitle {
    color: var(--Charcoal, #000);
    text-align: center;
    max-width: 300px;
    font-family: 'ABC Diatype';
    font-size: 1.875rem;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 2.25rem */
    letter-spacing: -0.0375rem;
    text-transform: uppercase;
  }
  .generalContainer {
    width: 61.3rem;
    height: 28.9rem;
    margin-top: 1.5rem;
  }
  .scanButtonsWrapper {
    bottom: 1.2rem;
  }
  .scanButtonsContainer {
    gap: 12rem;
  }
  .blob {
    width: 23.481rem;
    height: 22.14594rem;
    top: 6.5%;
  }

  .howItWorksButtonWrapper {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  .inputContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .ingredientsInput {
    width: 28.86725rem;
    height: 27.12281rem;
    flex-shrink: 0;
    margin-top: 1.5rem;
    border-radius: 0.625rem;
  }

  .analyseButton {
    max-width: 9rem;
  }
}

@media (max-height: 700px) {
  .wrapper {
    min-height: 600px;
  }
  .textInputContainer{
    height:23rem
  }
  .textInputContainer .blob{
    height: 20.9rem;
    top: -1.5%;
  }
  
  .textInputContainer .howItWorksButtonWrapper {
    margin-top: 1rem
  }
  .textInputContainer.scanButtonsWrapper{
    bottom: 0;
  }
}
