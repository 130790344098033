@import '../../../index.scss';

.LatestTrendsContainer {
    height: 500px;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-self: stretch;
    text-decoration: none;


}

.LatestTrendsContainerButton {
    display: flex;
    padding: 8px 34px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 26px;
    background: var(--Off-White, #F7F6E6);
    margin-bottom: -90px;
    margin-top: 50px;
    text-decoration: none;
}

.LatestTrendsContainerButtonText {
    color: var(--Charcoal, #000);
    font-family: "ABC Diatype";
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    border: none;
    background: none;
    cursor: pointer;

}

.LatestTrendsContainerTextIMG {
    width: 597px;
    height: 38.21px;


    @media (max-width: 1024px) {
        width: 450px;
        height: auto;
    }

    @media (max-width: 768px) {
        width: 350px;
        height: auto;
    }

    @media (max-width: 480px) {
        width: 250px;
        height: auto;
    }
}

.LatestTrendsContainerHeader {
    padding-top: 10px;
    color: #FFF;
    text-align: center;
    font-family: "NB Grotesk Pro Mono";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
    width: 642.695px;
    text-align: center;

    @media (max-width: 768px) {
        font-size: 14px;
        width: 70%;
    }
}