@import '../../../src/index.scss';

.errorPageContainer {
    position: relative;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    background-size: cover;
    background-repeat: no-repeat;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(189, 162, 136, 0.80);
        mix-blend-mode: overlay;
        z-index: 1;
    }

    &>* {
        position: relative;
        z-index: 3;
    }
}

.imageContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;

    &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 500px;
        height: 500px;
        border-radius: 50%;
        border-radius: 578px;
        opacity: 0.3;
        width: 578px;
        height: 578px;
        background: black;
        filter: blur(100px);
        z-index: -1;
    }
}

.Button {
    @include ABCDiatype-Bold(10);
    border-radius: 120px;
    text-align: center;
    font-style: normal;
    font-weight: 400;
    display: flex;
    justify-content: center;
    width: 188px;
    border: none;
    height: 45px;
    padding: 10px 36px;
    align-items: center;
    background-color: #000;
    color: #ffff;
    position: relative;
    z-index: 2;
}

.Button:hover {
    cursor: pointer;
    transition: .2s;
}