.cautionBox {
    display: flex;
    align-items: center;
    background-color: black;
    padding-right: 25%;
    border-radius: 4px;
    margin-bottom: 3%;
}

.imageSection {
    margin-right: 15px;
    margin-left: 10px;
}

.cautionImage {
    width: 60px;
    height: 60px;
    object-fit: contain;
}

.textSection {
    display: flex;
    flex-direction: column;
}

.cautionText {
    font-family: 'ABC Diatype', sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 13.2px;
    letter-spacing: -0.01em;
    margin-top: 5px;
    color: #F7F6E6;
    text-align: left;
}

@media screen and (max-width: 768px) {
    .cautionBox {
        display: flex;
        align-items: center;
        background-color: black;
        padding-right: 10%;
        border-radius: 4px;
        margin-bottom: 3%;
    }
}