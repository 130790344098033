.desktopContainer {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-image: url('../../../assets/images/headSectionBackground.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.topSection {
    display: flex;
    height: 33vh;
    width: 100%;
}

.menuTopSection {
    width: 33%;
    background-color: transparent;
}

.contentTopSection {
    flex-grow: 1;
    padding: 2% 15% 3% 2%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.userName {
    color: #FFF;
    font-family: "ABC Diatype";
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    letter-spacing: -0.52px;
    text-transform: uppercase;
    margin: 0;
}

.bottomSection {
    display: flex;
    flex-grow: 1;
    width: 100%;
    background-color: #F7F6E6;
    overflow: hidden;
    border-radius: 14px 14px 0 0;

}

.menuSection {
    width: 33%;
    padding: 3% 5% 5% 10%;
}

.contentSection {
    flex-grow: 1;
    padding: 2% 15% 2% 2%;
}

.mobileTitle {
    color: var(--Charcoal, #000);
    font-family: "ABC Diatype";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    letter-spacing: -0.32px;
    text-transform: uppercase;
    border-bottom: 1px solid #ccc;
    padding: 20px 0;
}

.mobileFunctionContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid #ccc;
}

.mobileFunctionTitle {
    color: var(--Charcoal, #000);
    font-family: "ABC Diatype";
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 109%;
    text-transform: uppercase;
}

.editButton {
    color: var(--Charcoal, #000);
    text-align: right;
    font-family: "ABC Diatype";
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
    letter-spacing: -0.1px;
    text-transform: uppercase;
    background: none;
    border: none;
}