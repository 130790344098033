.desktopContainer {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-image: url('../../../assets/images/headSectionBackground.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.topSection {
  display: flex;
  height: 33vh;
  width: 100%;
}

.menuTopSection {
  width: 33%;
  background-color: transparent;
}

.contentTopSection {
  flex-grow: 1;
  padding: 2% 15% 3% 2%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.userName {
  color: #fff;
  font-family: 'ABC Diatype';
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: -0.52px;
  text-transform: uppercase;
  margin: 0;
}

.bottomSection {
  display: flex;
  flex-grow: 1;
  width: 100%;
  background-color: #f7f6e6;
  overflow: hidden;
  border-radius: 14px 14px 0 0;
}

.menuSection {
  width: 33%;
  padding: 3% 5% 5% 10%;
}

.contentSection {
  flex-grow: 1;
  padding: 2% 15% 2% 2%;
}

.voucherForm {
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  .addButton {
    background: black;
    font-family: 'ABC Diatype';
    font-weight: bold;
    font-size: 12px;
    color: #f7f6e6;
    padding: 10px 20px;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    margin-top: 20px;
    width: 253px;
    align-self: center;
  }

  .voucherInput {
    margin-bottom: 10px;
    padding: 10px 0;
    background: none;
    border: none;
    border-bottom: 1px #7e7063 solid;
  }
}

.voucherList {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-top: 20px;
}

.voucherSectionHeaderMain {
  display: flex;
  align-items: baseline;
  justify-content: center;
}

.voucherTitle {
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.voucherSectionHeader {
  color: #000;
  font-family: 'ABC Diatype';
  font-size: 30px;
  font-weight: 700;
  text-transform: uppercase;
}

.voucherCount {
  font-size: 14px;
  margin-left: 10px;
  font-weight: normal;
}

.addVoucherButton {
  font-size: 12px;
  color: #000;
  text-decoration: none;
  font-weight: normal;
  cursor: pointer;
  font-family: 'ABC Diatype';
}

.lastVoucherSection {
  margin-bottom: 40px;
}

.sectionTitle {
  color: #000;
  font-family: 'ABC Diatype';
  font-size: 10px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.voucherCard {
  flex: 1 1 calc(50% - 16px);
  box-sizing: border-box;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  background-size: cover;
}

.lastVoucherCard {
  height: 300px;
}

.regularVoucherCard {
  height: 200px;
}

.voucherInfo {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.7), transparent);
  color: white;

  h3 {
    font-size: 24px;
    margin-bottom: 10px;
  }

  .voucherCode {
    font-size: 20px;
    margin-bottom: 5px;
    font-family: 'ABC Diatype';
    font-weight: bold;
  }

  .discountValue {
    font-size: 24px;

    margin-bottom: 5px;
    font-family: 'NB Grotesk Pro';
  }
}
.expirationDateContainer {
  display: flex;
  justify-content: flex-end;
}
.expirationDate {
  font-size: 12px;
  background-color: #f7f6e6;
  border-radius: 15px;
  color: black;
  font-family: 'ABC Diatype';
  font-size: 10px;
  padding: 8px;
  margin-top: 15px;
  margin-right: 15px;
}

.codePopHeader {
  color: #7e7063;
  font-family: 'ABC Diatype';
  font-size: 10px;
  margin-bottom: 5px;
  text-align: left;
}

.noVouchersContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px; /* Adjust as needed */
}

.noVouchersCircle {
  background: url('../../../assets/icons/emptyRound.png') no-repeat center;
  background-size: contain;
  text-align: center;
  padding: 40px;
  border-radius: 50%;
}

.noVouchersHeader {
  font-family: 'ABC Diatype';
  font-weight: 18px;
}

.noVouchersP {
  font-family: 'ABC Diatype';
  font-weight: 14px;
  margin-top: 5px;
  color: rgba(0, 0, 0, 0.584);
}

.addVoucherButtonEmpty {
  background: black;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  margin-top: 40px;
}

.mobileContainer {
  padding: 20px;
}

.mobileTitle {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .desktopContainer {
    .topSection,
    .menuSection {
      display: none;
    }

    .bottomSection {
      flex-direction: column;
    }

    .contentSection {
      width: 100%;
      padding: 0;
    }
  }

  .voucherTitle {
    margin-top: 20px;
  }

  .voucherCard {
    width: 100%;
    margin: 10px 0;
  }

  @media (max-width: 768px) {
    .mobileContainer {
      margin-top: 40px;
      .lastVoucherCard {
        height: 370px !important;
      }
    }
    .noVouchersContainer {
      height: 600px;
    }
    .voucherList {
      grid-template-columns: 1fr;
      gap: 10px;
      display: grid;
    }

    .voucherCard {
      width: 100%;
    }

    .regularVoucherCard,
    .lastVoucherCard {
      width: 100%;
      margin: 10px 0;
    }
  }
}

.mobileHeaderSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.mobileTitle {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
}

.mobileVoucherCount {
  font-size: 14px;
  font-weight: normal;
  color: #5d5246;
}
