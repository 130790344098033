.collectionCategories {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    width: 250px;
    // added 100%
    height: 100%;
    padding: 20px 0px;
    margin-left: 10px;
    margin-right: 10px;
}

.title {
    color: var(--Charcoal, #000);
    font-family: "ABC Diatype";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 104%;
    text-transform: uppercase;
    width: 300px;
}

.filterTitle {
    color: #000;

    font-family: "ABC Diatype";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
    /* 11px */
    letter-spacing: -0.1px;
    text-transform: uppercase;
    width: 100%;
    cursor: pointer;
}

.filterSection {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    min-width: 200px;
    overflow: hidden;
    transition: all 0.3s ease;
    justify-content: space-between;
    padding: 10px 10px;
}

.filterList {
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    color: #000;
    padding-bottom: 10px;

    font-family: "ABC Diatype";
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
    /* 11px */
    letter-spacing: -0.1px;
    text-transform: uppercase;
    width: 100%;
    cursor: pointer;
}

.filterSectionDontShowMeHeader {
    display: flex;
    justify-content: space-between;
    padding: 10px;
}

.filterSectionDontShowMe {
    border-radius: 7px;
    width: 100%;
    background: var(--Clay, #CCB9A7);
}

.dontShowMeContainer {
    width: 106px;
    min-height: 26px;
    max-height: 100%;
}

.Checkboxcontainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;
}

.filterListText {
    margin-right: auto;
    cursor: pointer;
    text-transform: uppercase;
    font-family: "ABC Diatype";
    font-size: 12px;
    font-weight: 700;
    line-height: 110%;
    color: #000;
    display: flex;
    align-items: left;
}

.filterListIcon {
    width: 16px;
    height: 16px;
    margin-left: 0.5rem;
    cursor: pointer;
}

.blackdot {
    width: 4.713px;
    height: 4.106px;
}

.dontShowMeTitle {
    color: #000;
    font-family: "ABC Diatype";
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
    letter-spacing: -0.1px;
    text-transform: uppercase;
}

.totalProducts {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    width: 100%;
    position: relative;
    padding: 0px 10px;
}

.totalProductsTitle {
    color: var(--Charcoal, #000);

    font-family: "ABC Diatype";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 104%;
    /* 20.8px */
    text-transform: uppercase;
}

.upperSectionMobile {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.totalProductsText {
    color: var(--Charcoal, #000);

    font-family: "ABC Diatype";
    font-size: 8px;
    font-style: normal;
    font-weight: 700;
    line-height: 104%;
    /* 8.32px */
    text-transform: uppercase;
}

.floatingButtonContainer {
    position: fixed;
    bottom: 5%;
    right: 50%;
    transform: translate(50%, 50%);
    z-index: 1000;
    opacity: 0;
    display: flex;
    width: 150px;
    padding: 10px 40px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    visibility: hidden;
    border-radius: 25px;
    background: var(--Charcoal, #000);
    transition: opacity 0.3s ease, visibility 0.3s ease;
}

.floatingButton {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--Off-White, #F7F6E6);
    font-family: "ABC Diatype";
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    background: none;
    border: none;
}

.floatingButtonContainer.show {
    opacity: 1;
    visibility: visible;
}

.floatingButtonContainer.hide {
    opacity: 0;
    visibility: hidden;
}

.collectionSection {
    height: 100%;
    width: 100%;
}

.filterListCheckboxAndText {
    display: flex;
    flex-direction: column;
    align-items: left;
    /* Vertically align text and checkbox */
}

.filterItem {
    padding: 5px 10px;
    cursor: pointer;
    text-transform: uppercase;
    /* Matches the existing text styling */
    font-family: "NB Grotesk Pro Mono";
    font-size: 12px;
    font-weight: 700;
    line-height: 110%;
    color: #000;
    display: flex;
    justify-content: flex-start;
}

.checkboxIcon {
    width: 10px;
    height: 10px;
    margin-right: 10px;
}

.savedFiltersContainer {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    position: relative;
    gap: 8px;
    padding: 0px 10px;
}

.clearAll {
    position: absolute;
    top: 0;
    right: 10px;
    color: var(--Backgrounds-CLAY, #7F6F64);
    font-family: "ABC Diatype";
    font-size: 8px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
    letter-spacing: -0.08px;
    text-transform: uppercase;
    background: none;
    border: none;
    cursor: pointer;
}

.clearAllTextColor {
    color: var(--Backgrounds-CLAY, #F7F6E6);
}

.filterChip {
    display: inline-flex;
    padding: 3px 7px;
    align-items: center;
    gap: 6px;
    border-radius: 15px;
    background-color: #CCB9A7;
    margin-right: 4px;
}

.dontShowMeFilterChipBackground {
    background-color: #F7F6E6;
}

@media (min-width: 0px) {
    .savedFiltersContainer {
        padding-right: calc(12px + 40px);
    }
}

.xIconFilter {
    margin-top: 3px;
}

.filterChipText {
    color: var(--Ceramic, #5D5246);
    font-family: "ABC Diatype";
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
    /* 11px */
    letter-spacing: -0.1px;
    text-transform: uppercase;
}

.removeButton {
    display: flex;
    margin-bottom: 5px;
    width: 8px;
    height: 8px;
    justify-content: center;
    align-items: center;
    background: none;
    border: none;
    cursor: pointer;
}

.mobileFilterSectionHeader {
    display: flex;
    align-items: center;
    justify-content: center;
}

.filteringOptionsContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 30%;
}

.optionContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 18px 14px;
    border-top: 1px solid rgba(0, 0, 0, 0.10);
    border-bottom: 1px solid rgba(0, 0, 0, 0.10);
    position: relative;
    cursor: pointer;
}

.selectedOptionsContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
    width: 100%;
    margin-bottom: 8px;
    overflow: hidden;
    word-wrap: break-word;
    position: relative;
}

.exludedOptionsContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
    width: 100%;
    margin-bottom: 8px;
    overflow: hidden;
    word-wrap: break-word;
    flex-direction: row;
    margin-top: 8px;
}

.clearAllButtonMobile {
    color: #000;
    font-family: "ABC Diatype";
    font-size: 8px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
    letter-spacing: -0.08px;
    text-transform: uppercase;
    cursor: pointer;
    background: none;
    border: none;
    position: absolute;
    top: 5px;
    right: 0;
    margin: 0;
}

.selectedOptions {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    max-width: calc(100% - 80px);
}

.optionHeader {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.optionText {
    color: #000;
    font-family: "ABC Diatype";
    font-size: 14px;
    font-weight: 700;
    line-height: 110%;
    letter-spacing: -0.14px;
    text-transform: uppercase;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.optionImage {
    width: 14px;
    height: 14px;
    object-fit: contain;
    margin-left: auto;
    cursor: pointer;
}

.selectedOptionTextContainer {
    border-radius: 15px;
    border: 0.5px solid var(--Ceramic, #5D5246);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px 7px;
}

.selectedOptionText {
    color: var(--Ceramic, #5D5246);
    font-family: "ABC Diatype";
    font-size: 10px;
    font-weight: 700;
    letter-spacing: -0.1px;
    text-transform: uppercase;
}

.removeIcon {
    margin-left: 4px;
    cursor: pointer;
    width: 12px;
    height: 12px;
}

.subMenu {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    scrollbar-width: thin;
}

.subMenuText {
    color: #000;
    font-family: "ABC Diatype";
    font-size: 12px;
    font-weight: 400;
    line-height: 110%;
    letter-spacing: -0.14px;
    text-transform: uppercase;
    padding: 10px 14px;
    border-top: 1px solid rgba(0, 0, 0, 0.10);
    border-bottom: 1px solid rgba(0, 0, 0, 0.10);
    display: flex;
    justify-content: space-between;
}


//mobile!!! 
@media (max-width: 448px) {
    .mobileFilterSection {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100%;
        z-index: 1050;
        background: var(--OLI-Off-White, #F7F6E6);
        overflow-y: hidden;
    }

    .closeButtonContainer {
        position: absolute;
        top: 15px;
        left: 20px;
        background: none;
        border: none;
    }

    .upperSectionMobile {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 390px;
        height: 48px;

        @media (max-width: 370px) {
            width: 90%;
        }
    }

    .filterTitle {
        padding-left: 15px;

        @media (max-width: 370px) {
            padding-left: 25px;
        }
    }

    .filterSectionDontShowMe {
        width: 361px;
        max-height: 250px;
        box-sizing: border-box;
        overflow-y: auto;

    }

    .dontShowMeTitle {
        color: #000;
        font-family: "ABC Diatype";
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 110%;
        letter-spacing: -0.14px;
        text-transform: uppercase;
        justify-content: flex-start;
        width: 100%;
        padding-left: 10px;
    }

    .closeButton {
        color: var(--Charcoal, #000);
        font-family: "ABC Diatype";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        text-transform: uppercase;
        background: none;
        border: none;
    }

    .textContainer {
        position: absolute;
        top: 20px;
        background: none;
        border: none;
    }

    .filterTitleText {
        color: #000;
        text-align: center;

        font-family: "ABC Diatype";
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 110%;
        /* 13.2px */
        letter-spacing: -0.12px;
        text-transform: uppercase;
    }

    .filterMobileTitles {
        position: absolute;
        top: 150px;
    }

    .filterSection {
        display: flex;
        align-items: center; // Ensure vertical alignment in the center
        justify-content: space-between; // Space between title and arrow
        width: 100%;
        cursor: pointer;
        align-items: flex-start;
        border-bottom: 1px solid rgba(0, 0, 0, 0.10);
        background: var(--Off-White, #F7F6E6);
    }

    .clearAllContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 8px 12px;
        gap: 8px;
    }

    .savedFilterSection {
        flex: 1;
        display: flex;
        align-items: center;
    }

    .clearAll {
        color: #000;
        font-family: "ABC Diatype";
        font-size: 10px;
        /* Adjust font-size if needed */
        font-style: normal;
        font-weight: 700;
        line-height: 1.2;
        letter-spacing: -0.08px;
        text-transform: uppercase;
        margin: 0;
        /* Remove any default margin */
        cursor: pointer;
        flex-shrink: 0;
        /* Prevent shrinking if the container gets too small */
    }


    .dontShowMeContainer {
        width: 100%;
        padding-right: 14px;
        padding-left: 14px;
        margin-top: 20px;
    }

    .dontShowMeHeader {
        display: flex;
        justify-content: space-between;
    }

    .dontShowMeTextContainer {
        border-radius: 3px;
        background: var(--Beige, #CCB9A7);
        padding: 22px 15px;
        cursor: pointer;
    }

    .dontShowMeText {
        color: #000;
        font-family: "ABC Diatype";
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 110%;
        letter-spacing: -0.14px;
        text-transform: uppercase;
    }

    .dontShowMeClearAll {
        color: #000;
        font-family: "ABC Diatype";
        font-size: 8px;
        font-style: normal;
        font-weight: 700;
        line-height: 110%;
        letter-spacing: -0.08px;
        text-transform: uppercase;
        background: none;
        border: none;
    }

    .blackdot {
        display: none;
    }

    .subScreen {
        display: flex;
        width: 390px;
        height: 100svh;
        padding: 10px 0px;
        justify-content: space-between;
        align-items: flex-start;
        background: var(--Off-White, #F7F6E6);
    }

    .subFilterMobile {
        border-bottom: 2px solid rgba(0, 0, 0, 0.10);
        display: flex;
        padding: 14px 14px;
        justify-content: space-between;
        align-items: flex-start;
        align-self: stretch;
    }

    .collectionSection {
        height: 844px;
    }

    .arrowContainer {
        display: flex;
        align-items: center; // Center the arrow vertically
        justify-content: center; // Center the arrow horizontally
        width: 30px; // Adjust width as needed
        height: 100%; // Full height of the parent container
    }

    .arrowIcon {
        width: 11px;
        height: 11px;
        flex-shrink: 0;
    }
}

//mobile overlay

.mobileOverlay {
    position: fixed;
    top: 5%;
    left: 0;
    width: 100vw;
    background: var(--OLI-Off-White, #F7F6E6);
    height: 100%;
    z-index: 1050;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow-y: hidden;
}

.backButtonContainer {
    position: absolute;
    top: 0px;
    left: 20px;
    margin-bottom: 20px;
    background: none;
    border: none;
}

.mobileFilterList {
    display: flex;
    width: 390px;
    height: 100%;
    margin-top: 100px;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    top: auto;
}

.mobileFilterItem {
    display: flex;
    padding: 16px 5px;
    justify-content: space-between;
    align-items: flex-center;
    align-self: stretch;
    border-top: 1px solid rgba(0, 0, 0, 0.10);

    @media (max-width: 370px) {
        padding: 16px 30px;

    }
}

.savedFilterMobileContaier {
    margin-top: 20px;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: space-between;
}

.seeResultButtonContainer {
    display: flex;
    justify-content: center;
    flex-shrink: 0;
    width: 100%;
    position: absolute;
    bottom: 8svh;
}

.seeResultButton {
    color: var(--Off-white, #F7F6E6);
    text-align: center;
    font-family: "ABC Diatype";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-transform: uppercase;
    background: var(--Charcoal, #000);
    border: none;
    width: 358px;
    border-radius: 41px;
    height: 44px;

    &:disabled {
        border-radius: 62px;
        opacity: 0.2;
        backdrop-filter: blur(10px);
        cursor: not-allowed;
    }
}