body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'ABC Diatype';
  src: url('./assets/fonts/ABCDiatype-Bold.woff') format('woff2'),
      url('./assets/fonts/ABCDiatype-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ABC Diatype';
  src: url('./assets/fonts/ABCDiatype-Regular.woff2') format('woff2'),
      url('./assets/fonts/ABCDiatype-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'NB Grotesk Pro Mono';
  src: url('./assets/fonts/NBGroteskProMono-Regular.woff2') format('woff2'),
      url('./assets/fonts/NBGroteskProMono-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}


@mixin ABCDiatype-Regular($size) {
  font-family: 'ABC Diatype';
  font-weight: normal;
  font-size: #{$size}px;
  font-style: normal;
  font-display: swap;
}

// Миксин для ABC Diatype Bold
@mixin ABCDiatype-Bold($size) {
  font-family: 'ABC Diatype';
  font-weight: bold;
  font-size: #{$size}px;
  font-style: normal;
  font-display: swap;
}

// Миксин для NB Grotesk Pro Mono Regular
@mixin NBGroteskProMono-Regular($size) {
  font-family: 'NB Grotesk Pro Mono';
  font-weight: normal;
  font-size: #{$size}px;
  font-style: normal;
  font-display: swap;
}

@mixin CenteredAbsolute(){
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
}




$color-white: #fff;
$color-black: #000;
$color-default-background: #F7F6E7;
$color-gray-dark-1: #ddd;
