.signUpHeaderBlock {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-size: cover;
  background-position: center;
}

.signUpContainer {
  width: 800px;
  min-width: 620px;
  height: 70svh;
  min-height: 727px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 12px;
  background: linear-gradient(to bottom, #f7f6e6, #c8bda8);
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.headSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  margin-top: 15%;
}

.validIcon {
  width: 24px;
  height: 24px;
  margin-bottom: 3px;
}

.olisLogo {
  margin-bottom: 30px;
}

.signupTitle {
  color: var(--Charcoal, #000);
  text-align: center;
  font-family: 'ABC Diatype';
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: -0.52px;
  text-transform: uppercase;
  margin-bottom: 20px;
  width: 100%;
}

.formContainer {
  width: 50%;
  margin-bottom: 20px;
}

.stepIndicatorContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
}

.stepIndicator {
  display: flex;
  align-items: center;
  gap: 6px;
  font-family: "SF Pro Display", sans-serif;
  font-size: 16px;
}

.step {
  color: #9E9E9E;
  font-style: normal;
  font-weight: 400;
  line-height: 1.4;
  cursor: pointer;
}

.step.active {
  color: #000;
  font-weight: 700;
}

.step.active::before {
  content: attr(data-step-label) " ";
  font-weight: 700;
}

.signupInputGroups {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #aaa;
  padding-bottom: 5px;
  margin-bottom: 20px;
  width: 100%;
}

.signupInputGroups input:-webkit-autofill,
.signupInputGroups input:-webkit-autofill:hover,
.signupInputGroups input:-webkit-autofill:focus {
  background-color: transparent !important;
  color: #000 !important;
  -webkit-box-shadow: 0 0 0 1000px transparent inset !important;
  transition: background-color 5000s ease-in-out 0s;
}


.signupInputGroups input {
  flex: 1;
  border: none;
  outline: none;
  background: transparent;
  font-size: 14px;
  color: #333;
  padding: 10px 0;
}

.signupInputGroups input::placeholder {
  color: #6d6d6d;
}

.signupInputGroups input:focus {
  outline: none;
}

.nextButton {
  display: flex;
  width: 100%;
  padding: 10px 76px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 41px;
  background: var(--Charcoal, #000);
  color: #f7f6e6;
  border: none;
  cursor: pointer;
  margin-top: 50px;
}

.nextButton:disabled {
  opacity: 0.2;
  cursor: not-allowed;
  border: none;
}

.loginText {
  position: absolute;
  bottom: 5%;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  text-align: center;
  color: var(--Charcoal, #000);
  text-align: center;
  font-family: 'ABC Diatype';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
}

.loginLink {
  color: var(--Charcoal, #000);
  ;
  font-family: "ABC Diatype";
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-skip-ink: none;
  text-decoration-thickness: auto;
  text-underline-offset: auto;
  text-underline-position: from-font;
}

.showPasswordBtn {
  background: none;
  border: none;
  font-size: 14px;
  color: #6d6d6d;
  cursor: pointer;
  margin-left: 10px;
}

.showConfirmPassword {
  background: none;
  border: none;
  font-size: 14px;
  color: #6d6d6d;
  cursor: pointer;
  margin-left: 10px;
}

.errorContainer {
  width: 100%;
  display: flex;
  margin-bottom: 32px;
}

.errorMessage {
  font-size: 12px;
  color: #990202;
}


//mobile section
//**************************************************
//**************************************************
//**************************************************
//**************************************************

@media (max-width: 768px) {
  .signUpContainer {
    width: 98vw;
    height: 80svh;
    min-width: unset;
    min-height: unset;
  }

  .headSection {
    width: 80%;
  }

  .loginForgotPasswordLinkContainer {
    width: 80%;
  }

  .formContainer {
    width: 80%;
  }
}