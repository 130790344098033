.whyOliContainer{
    display: flex;
padding: 70px 30px;
align-items: flex-start;
gap: 10px;
align-self: stretch;
width: 100%;
height: 313px;
}
.InnerContainer{
    display: flex;
width: 950px;
flex-direction: column;
align-items: flex-start;
gap: 29px;
}
.title{
    color: var(--Charcoal, #000);

font-family: "ABC Diatype";
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: 104%; /* 20.8px */
text-transform: uppercase;
}
.textContainer{
    width: 950px;
    height: auto;
}
.text{
    color: #000;
font-family: "NB Grotesk Pro Mono";
font-size: 24px;
font-style: normal;
font-weight: 400;
line-height: 110%; /* 26.4px */
}

@media (max-width: 768px) {
    .whyOliContainer{
        height: 100%;
    }
    .textContainer{
        width: 300px;
    }
}