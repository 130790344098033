.ethosSection {
    width: 100%;
    height: 100dvh;
    flex-shrink: 0;
    align-items: center;
    /* Center vertically */
    justify-content: center;
    /* Center horizontally */
    background-image: url('../../assets/images/ethos.png'); // Use the ethos image
    background-size: cover; // Ensure the image covers the entire section
    display: flex;
    /* Enable flexbox for centering */

    background-position: center; // Center the background image
    background-repeat: no-repeat; // Avoid repeating the image
}

.ethosInnerContainer {
    display: flex;
    width: 500px;
    height: auto;
    /* Allow it to adjust based on content */
    flex-direction: column;
    align-items: center;
    gap: 51px;
    /* Spacing between items */
    text-align: center;
    /* Center text alignment for child elements */
}

.title {
    color: #FFF;

    text-align: center;
    font-family: "ABC Diatype";
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
}

.text {
    color: #FFF;

    text-align: center;
    font-family: "NB Grotesk Pro Mono";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
    width: 450px;

    @media (max-width: 768px) {
        width: 300px;
    }
}

.learnMoreButton {
    display: flex;
    padding: 8px 34px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 26px;
    background: var(--Off-White, #F7F6E6);
    color: var(--Charcoal, #000);
    font-family: "ABC Diatype";
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    border: none;

}